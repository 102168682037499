var render, staticRenderFns
var script = {}
import style0 from "./style.vue?vue&type=style&index=0&id=0f5cd0ea&prod&scoped=true&lang=less&"
import style1 from "./style.vue?vue&type=style&index=1&id=0f5cd0ea&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f5cd0ea",
  null
  
)

export default component.exports