/**
 * Created by jiangxd on 2019/2/25.
 */

 /**
  *  全局的bus
  */
class EventBus {
  events: { [key: string]: any } = {};

  emit(type: string, ...args: any) {
    const e = this.events[type];
    if (!e) {
      return;
    }
    // 查看这个type的event有多少个回调函数，如果有多个需要依次调用。
    if (Array.isArray(e)) {
      e.forEach((v, i) => {
        e[i].apply(this, args);
      });
    } else {
      e[0].apply(this, args);
    }
  }
  on(type: string, fun: Function) {
    const e = this.events[type];
    if (!e) {
      // 如果从未注册过监听函数，则将函数放入数组存入对应的键名下
      this.events[type] = [fun];
    } else {
      // 如果注册过，则直接放入
      e.push(fun);
    }
  }
}

const eventBus = new EventBus();
export default eventBus;
