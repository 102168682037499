/*
 * @Author: jiangxd
 * @Date: 2021-03-07 18:34:26
 * @LastEditTime: 2021-05-10 18:36:25
 * @LastEditors: jiangxd
 * @Description: 
 * @FilePath: /qingger-dz-webview/src/components/taskCenterDetail/index.tsx
 */
/**
 * @author jiangxd <jiangxiaodon@qingger.com>
 *
 * @time 2019/10/10
 */

import { Vue, Component, Prop } from "vue-property-decorator";
import taskCenteredCom from "./taskCentered/index.vue";
import birthdayCareTasCom from "./birthdayCareTask/index.vue";
import api from "@/service";
// 不同任务类型对应的模板
const TASK_TYPE_TO_COMPONENT = {
  SaleBeforeV2: taskCenteredCom, // 售前邀约
  BirthdayCareV2: birthdayCareTasCom, // 生日会员慰问
  MemberNCV2: taskCenteredCom, // 入会未消费会员沟通
  AwakeSleepV2: taskCenteredCom, // 沉默会员唤醒
  CustomerStaticV2: taskCenteredCom // 客流统计
};

@Component({})
export default class TaskCenterDetail extends Vue {
  @Prop({ default: "" })
  taskId: string;
  @Prop({ default: "" })
  taskTypeCode: string;
  @Prop({ default: "" })
  selectedGuiderId: string;
  @Prop({ default: false })
  isAllGuider: boolean;


  render(h) {
    const Com = TASK_TYPE_TO_COMPONENT[this.taskTypeCode];
    return (
      <div class="task-detail" style="flex:1;overflow:auto">
        <Com taskId={this.taskId} selectedGuiderId={this.selectedGuiderId}   isAllGuider={this.isAllGuider}/>
      </div>
    );
  }
}
