/**
 * @author jiangxd <jiangxiaodon@qingger.com>
 *
 * @time 2019/10/15
 */

import { Vue, Component, Prop } from "vue-property-decorator";
import _Template from "./style.vue";

@Component({
  mixins: [_Template],
  components: {},
})
export default class HelpTips extends Vue {
  protected appWidth: number = 0;
  protected appHeight: number = 0;

  protected clientX: number = 0;
  protected clientY: number = 0;

  protected elWidth: number = 60;
  protected elHeight: number = 60;

  tipsTouchmove(e) {
    e.stopPropagation();
    e.preventDefault();

    let touchClientX = e.targetTouches[0].clientX;
    let touchClientY = e.targetTouches[0].clientY;

    if (touchClientX - 20 < 0 || touchClientY - 20 < 0) {
      return;
    }
    if (touchClientX - 20 + this.elWidth > this.appWidth) {
      touchClientX = touchClientX - this.elWidth;
      return;
    }
    if (touchClientY - 20 + this.elHeight > this.appHeight) {
      touchClientY = touchClientY - this.elHeight;
      return;
    }

    this.$nextTick(() => {
      this.clientX = touchClientX - 20;
      this.clientY = touchClientY - 20;
    });
  }
  tipsTouchstart(e) {
    // e.stopPropagation();
    // e.preventDefault();
  }
  tipsTouchend(e) {
    // e.stopPropagation();
    // e.preventDefault();
  }
  tipsClick() {
    this.$emit("show");
  }

  mounted() {
    const appElement: HTMLElement = document.querySelector("#app");

    this.appHeight = appElement.offsetHeight;
    this.appWidth = appElement.offsetWidth;

    this.clientX = this.appWidth * 0.8;
    this.clientY = this.appHeight * 0.8;
  }

  render(h) {
    return (
      <div
        class="help-tips-warning"
        style={{ left: this.clientX + "px", top: this.clientY + "px" }}
        onTouchmove={this.tipsTouchmove.bind(this)}
        onTouchstart={this.tipsTouchstart.bind(this)}
        onTouchend={this.tipsTouchend.bind(this)}>
        <span class="help-icon" on-click={this.tipsClick.bind(this)}>
          !
        </span>
      </div>
    );
  }
}
