/**
 * Created by jiangxd on 2019/6/12.
 */

// 会员招募
import * as $ from "jquery";
import * as moment from "moment";
import service from "../../service/index";
import GuiderList from "@/components/guiderList";
import SelectTime from "@/components/selectTime";
import PowerList from "@/components/powerList";
import { formatNum, createCircleChart, createPieChart } from "@/util/utils";

const yesterday = 1;
// let type: string = "";
let isMonth = false;
let selectParams: any = {};

const today = moment().format("YYYY-MM-DD");
const day = new Date().getUTCDate();
const currentDays = moment().daysInMonth();
const scale = (day / currentDays) * 100;

console.log(scale);

const getListParam: {
  siteCode: string;
  empCode: string;
  beginDay: string;
  endDay: string;
  guiderId: string;
} = {
  siteCode: localStorage.getItem("siteCode"),
  guiderId: localStorage.getItem("guiderId"),
  empCode: localStorage.getItem("empCode"),
  beginDay: moment().format("YYYY-MM-DD"),
  endDay: moment().format("YYYY-MM-DD")
};

const topVa: any = yesterday; // 头部时间,默认昨天

const getPageData = () => {
  localStorage.setItem("selectEmpCode", selectParams.empCode);
  getListParam.empCode = selectParams.empCode;
  getListParam.guiderId =
    selectParams.guiderId || localStorage.getItem("guiderId");
  getData();
};
function getGuiderList() {
  service
    .siteGuiderList()
    .then((data: any) => {
      $("#siteGuider").empty();
      $("#siteGuider").html(data && data.content);
    })
    .catch(error => {
      console.log(error);
    });
}
// 权限判断
function sale_divide() {
  service
    .contactMemberGet({ appointedGuiderId: localStorage.getItem("guiderId") })
    .then((resp: any) => {
      const siteRole = window.localStorage.getItem("siteRole");

      if (siteRole !== "101" && +resp.content.performanceType !== 1) {
        $(".promote-sale .select .shop").hide();
      }
    });
}

function getData() {
  service.siteProductSale(getListParam).then(res => {
    const list = res.content ? res.content.list : [];
    if (isMonth) {
      $(".promote-sale .cont").hide();
      $(".promote-sale .cont-month").show();
    } else {
      $(".promote-sale .cont").show();
      $(".promote-sale .cont-month").hide();
    }
    if (list && list.length > 0) {
      let element = "";
      let total = 0;
      let num = 0;
      $(".promote-sale-list").html("");
      $(".no-data").hide();

      // 排序 ,遍历数据
      list
        .sort((a, b) => {
          if (isMonth) {
            return b.pricePercent - a.pricePercent;
          } else {
            return b.numSale - a.numSale;
          }
        })
        .forEach(i => {
          if (isMonth) {
            const el = i.numPercent >= scale ? "up-color" : "red";
            const el2 = i.pricePercent >= scale ? "up-color" : "red";

            element +=
              // tslint:disable-next-line: restrict-plus-operands
              "<li>\n" +
              "<div class='left'>\n" +
              "<img src='" +
              i.img +
              "' alt='' class='pri'>\n" +
              "<div class='info'>\n" +
              "<p class='sourse'>" +
              i.pId +
              "</p>\n" +
              "<p class='name'>" +
              i.pName +
              "</p>\n" +
              "</div>\n" +
              "</div>\n" +
              "<div class='deal'>\n" +
              "<span class='num'>" +
              formatNum(i.numTarget, 1) +
              "件</span>\n" +
              "<span class='num'>￥" +
              formatNum(i.priceTarget, 1) +
              "</span>\n" +
              "</div>\n" +
              "<div class='deal'>\n" +
              "<span class='num blue'>" +
              formatNum(i.numSale, 1) +
              "件</span>\n" +
              "<span class='num blue'>￥" +
              formatNum(i.priceSale, 1) +
              "</span>\n" +
              "</div>\n" +
              "<div class='deal'  style='width: 10%'>\n" +
              "<span class='num " +
              el +
              "'>" +
              formatNum(i.numPercent, 1) +
              "%</span>\n" +
              " <span class='num " +
              el2 +
              "'>" +
              formatNum(i.pricePercent, 1) +
              "%</span>" +
              "</div>\n" +
              "</li>";
          } else {
            const mon =
              i.priceSale >= 0
                ? "￥" + formatNum(i.priceSale, 1)
                : "-￥" + formatNum(Math.abs(i.priceSale), 1);
            element +=
              // tslint:disable-next-line: restrict-plus-operands
              " <li>\n" +
              "<img src=" +
              i.img +
              "  class='pri'>" +
              "<div class='info'>\n" +
              "<p class='sourse' style=' color: #999;'>" +
              i.pId +
              "</p>\n" +
              "<p class='name'>" +
              i.pName +
              "</p>\n" +
              "</div>\n" +
              "<span class='num blue'>" +
              formatNum(i.numSale, 1) +
              "件</span>\n" +
              "<span class='num blue'>" +
              mon +
              "</span>\n" +
              "</li>";
          }
          total += i.priceSale;
          num += i.numSale;
        });
      $(".promote-sale-list").html(element);
      $(".opt .total").html(
        "共" + formatNum(num, 1) + "件（￥" + formatNum(total, 1) + "）"
      );
    } else {
      $(".promote-sale-list").html("");
      $(".opt .total").html(`共0件（￥0）`);
      $(".no-data").show();
    }
    $(".popupMask").hide();
  });
  service.dzGroupCommonConfig({}).then((res) => {
    console.log(res);
    window.localStorage.setItem("isHandlePhone", res.content.handlePhone);
  });
}
const onload = () => {
  document.title = "主推商品销售";
  window.localStorage.setItem("isGuider", "1");
  $("body").show();
  getGuiderList();
  getData();
  sale_divide();
};

import { Component, Vue } from "vue-property-decorator";
import _Template from "./promoteSale-temp.vue";
import eventBus from "@/util/eventBus";

interface ISelectGoodItem {
  img?: string;
  pName?: string;
  pId?: string;
  priceTarget?: number;
  numTarget?: number;
}

@Component({
  mixins: [_Template],
  components: { GuiderList, SelectTime, PowerList }
})
export default class PromoteSale extends Vue {
  protected guiderList: any[] = [];

  protected isShop: boolean = false;

  protected goodsList: ISelectGoodItem[] = [];

  protected selectGoodItem: ISelectGoodItem = {};

  protected getSaleProductData: any = {};
  protected productTotalSale: any = {};

  protected isMonth: boolean = false;

  protected scale = scale;

  mounted() {
    onload();

    this.getGuiderList();
    this.getShopMonthSale();
    // this.getProductTotalSale();
  }
  getShopMonthSale() {
    service.goodsListGet().then(res => {
      this.goodsList = (res.content.list || []).map(
        (item: any, index: number) => {
          item.select = !index;
          return item;
        }
      );

      if (this.goodsList.length > 0) {
        this.selectGoodItem = this.goodsList[0];
        this.getSaleProduct(this.selectGoodItem.pId);
      } else {
        $(".no-data").show();
      }
    });
  }

  getSaleProduct(productCode) {
    if (!productCode){
      return
    }
    const param: { [key: string]: any } = { productCode, empCode: "" };
    if (isMonth) {
      param.beginDay = getListParam.beginDay;
      param.endDay = getListParam.endDay;
    } else {
      param.beginDay = undefined;
      param.endDay = undefined;
    }
    service.reportSiteProductGuiderSale(param).then(res => {
      const getSaleProductData = res.content;
      getSaleProductData.empList = getSaleProductData.empList.sort((a, b) => {
        return b.pricePercent - a.pricePercent;
      });

      this.getSaleProductData = getSaleProductData;
    });
  }
  getPower(frame: string) {
    if (frame === "mine") {
      this.isShop = false;
      getListParam.empCode = localStorage.getItem("empCode");
      getListParam.guiderId = localStorage.getItem("guiderId");
      getData();
    } else if (frame === "shop") {
      getListParam.empCode = "";
      this.isShop = true;
      this.getShopMonthSale();
      $(".no-data").hide()
    }
    // 重置时间
    eventBus.emit("taskCenterOnSelectTime", {
      frame: "today",
      explain: "今日"
    });
  }

  getTime(frame) {
    if (frame === "thisMonth") {
      isMonth = true;
      getListParam.beginDay = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      getListParam.endDay = moment()
        .endOf("month")
        .format("YYYY-MM-DD");
    } else if (frame === "today") {
      isMonth = false;
      getListParam.beginDay = moment().format("YYYY-MM-DD");
      getListParam.endDay = moment().format("YYYY-MM-DD");
    }
    this.isMonth = frame === "today";

    if (this.isShop) {
      this.getSaleProduct(this.selectGoodItem.pId);
      this.getProductTotalSale();
    } else {
      getData();
    }
  }
  getData(guiderId, empCode) {
    selectParams = { guiderId, empCode };
    getListParam.empCode = empCode;
    getListParam.guiderId = guiderId || localStorage.getItem("guiderId"); // 查看店铺设置登录导购
    getData();
  }
  getGuiderList() {
    service
      .siteGuiderList()
      .then((data: any) => {
        this.guiderList = data.content.list;
      })
      .catch(error => {
        console.log(error);
      });
  }

  getProductTotalSale() {
    const param: any = { empCode: "" };
    if (isMonth) {
      param.beginDay = getListParam.beginDay;
      param.endDay = getListParam.endDay;
    } else {
      param.beginDay = undefined;
      param.endDay = undefined;
    }
    service.reportSiteProductTotalSale(param).then(res => {
      this.productTotalSale = res.content;
    });
  }

  choseGoods(item, index) {
    this.goodsList = this.goodsList.map((val: any, ind: number) => {
      if (index === ind) {
        val.select = true;
        this.selectGoodItem = val;
        this.getSaleProduct(this.selectGoodItem.pId);
      } else {
        val.select = false;
      }
      return val;
    });
  }
}
