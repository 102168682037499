import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import _Template from "./style.vue";
import * as $ from "jquery";
import service from "@/service/index";
import DzMenu from "../dzMenu";
import eventBus from "@/util/eventBus";

interface ISelectItem {
  siteRole?: number;
  empMobile?: string;
  empCode?: string;
  empName?: string;
  avatar?: string;
  state?: number;
  guiderId?: string;
}

@Component({
  mixins: [_Template],
})
export default class GuiderList extends Vue {
  // 新增标志位 处理选中后数据传递，与老模式兼容
  @Prop({ default: false, type: Boolean })
  useValue: boolean;

  @Prop()
  value;

  @Prop({ default: {} })
  guiderListGetData;

  @Prop()
  taskId: string;

  @Prop()
  guiderList: any[];

  @Watch("value")
  onValueChange(e) {
    if(e){
      this.selectGuider(e);
    }
  }

  public isMainMenuWrapperShow: boolean = false;

  public defGuiderList: ISelectItem[] = [
    {
      empCode: "",
      empName: "全店",
      guiderId: "",
    },
  ];
  public selectItem: ISelectItem = {};

  // 菜单 点击事件
  public onMenuItemClick(e, item) {
    this.isMainMenuWrapperShow = false;
    this.selectGuider(item);
    if (this.useValue && item) {
      this.$emit("input", item);
    }
  }
  public showMenu() {
    this.isMainMenuWrapperShow = true;
  }

  public selectGuider(item) {
    console.log("-selectGuider----",item)
    const { empName, empCode, guiderId } = item;
    this.selectItem = item;
    $("#guiderSelect").text(empName);
    this.guiderListGetData(guiderId, empCode,item);
    $(".guider-list").hide();
    $(".popupMask").hide();
  }

  mounted() {
    if (this.useValue) {
      this.selectGuider(this.value);
    }
    eventBus.on("taskCenterOnSelectGuider", (item) => {
      this.selectGuider(item);
    });
  }

  render(h) {
    return (
      <div class="data-select-wrap">
        <div class="data-select">
          <span
            onClick={() => {
              this.showMenu();
            }}
            class="date-text"
            id="guiderSelect">
            {this.defGuiderList.concat(this.guiderList)[0].empName}
          </span>

          <i class="iconfont icon-moreunfold" style="position: static;color:#2c3e50;" />

          <DzMenu
            label="empName"
            value={this.isMainMenuWrapperShow}
            on-input={(e) => (this.isMainMenuWrapperShow = e)}
            menuItems={this.defGuiderList.concat(this.guiderList)}
            onMenuItemClick={(e, item) => this.onMenuItemClick(e, item)}
          />
        </div>
      </div>
    );
  }
}
