// tslint:disable-next-line:ordered-imports
import { Vue, Component } from "vue-property-decorator";
import _Template from "./svg-icon-tmpl.vue";
@Component({
  mixins: [_Template],
  name: "svg-icon",
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    className: {
      type: String,
    },
  },
  computed: {
    iconName() {
      return `#${this.iconClass}`;
    },
    svgClass() {
      if (this.className) {
        return "svg-icon " + this.className;
      } else {
        return "svg-icon";
      }
    },
  },
})
export default class SvgIcon extends Vue {}
