/**
 * @author jiangxd <jiangxiaodon@qingger.com>
 *
 * @time 2019/10/11
 */

import { Vue, Component, Prop } from "vue-property-decorator";
import _Template from "./style.vue";

import DZMainService from "@/service";
import InitJSSDK from "@/util/initJSSDK";
import { AppUrls } from "@/util/consts";
import { mobileNumberChange } from "@/util/format";

// tslint:disable-next-line:no-var-requires
const yimaidanImg = require("../../assets/img/yimaidan.png");

interface ICustomerItem {
  birthday: string;
  regTimeFormat: string;
  cstName: string;
  taskRecordId: string;
  saveGuider: string;
  execTime: null;
  pntVal: number;
  avatar: string;
  mySiteCode: string;
  execGuider: string;
  execResult: string;
  mqTime: number;
  phone: string;
  execGuiderName: string;
  cstId: string;
  activate: number;
  ruleName: string;
  execState: number;
  myGuider: string;
  lastSaleDay: string;
}

@Component({
  mixins: [_Template],
  components: {}
})
export default class ExecutiveDetails extends Vue {
  @Prop({ default: "" })
  taskId: string;

  @Prop()
  item: ICustomerItem;

  @Prop({ default: [] })
  tabs;

  @Prop()
  saleAfterTabText;

  @Prop()
  taskType: string;

  @Prop()
  execResult: string;

  @Prop({ default: false })
  isAllGuider: boolean;

  protected taskRecordId = "";
  protected cstId = "";

  protected guiderId: string = "";
  protected siteCode: string = "";
  protected siteName: string = "";
  protected guiderPost: string = "";

  protected taskResult: string = "";

  protected tabsText = "";

  // 顾客信息
  protected customerInfo: any = {};

  // 沟通记录
  protected taskCustomerExecList: any[] = [];

  // 沟通导购
  protected execGuider: string = "";

  // 会员附加信息
  protected extraCustomerItems: any = {};

  // 买单
  protected customerPurchaseDataList: any[] = [];

  //
  protected extraCustomerItemsOpen: boolean = false;
  protected aboutOrderIsOpen: boolean = true;

  // 顾客订单列表
  protected customerOrderList: any[] = [];

  get getGuiderText() {
    if (this.item.execGuiderName) {
      return this.item.execGuiderName;
    } else if (this.isAllGuider) {
      return "参与导购均可执行";
    } else {
      return "暂无";
    }
  }

  mounted() {
    // 获取所需信息
    this.guiderPost = window.localStorage.getItem("siteRole");
    this.siteCode = window.localStorage.getItem("siteCode");
    this.guiderId = window.localStorage.getItem("guiderId");

    this.taskRecordId = this.item.taskRecordId + "";
    this.cstId = this.item.cstId + "";

    this.getTaskExecInfo();
    this.getDzTaskCustomerInfo();
    this.getDzV2TaskCustomerExecList();
    this.communicateConfig();
    this.getTaskCustomerPurchaseDataList();

    // 只用售后才有订单
    if (this.taskType === "SaleAfterV2") {
      this.getCustomerOrderList();
      this.tabsText = this.saleAfterTabText;
    }
  }

  // * T314. 获取顾客任务期间店铺买单情况
  getTaskCustomerPurchaseDataList() {
    const param = {
      taskId: this.taskId,
      customerId: this.cstId,
      guiderId: this.guiderId,
      siteCode: this.siteCode
    };
    DZMainService.taskCustomerPurchaseDataList(param).then(res => {
      this.customerPurchaseDataList = res.content.list;
    });
  }

  // 获取顾客的订单
  getCustomerOrderList() {
    const param = {
      customerId: this.cstId,
      siteCode: this.siteCode,
      taskRecordId: this.taskRecordId // 任务ID
    };
    DZMainService.customerOrderList(param).then(res => {
      this.customerOrderList = res.content.list;
    });
  }

  getTaskExecInfo() {
    const param = {
      taskRecordId: this.taskRecordId, // 任务ID
      taskResult: this.taskResult,
      guiderId: this.guiderId,
      siteCode: this.siteCode,
      topicSend: 2
    };
    DZMainService.taskExecInfoV2(param).then(res => {
      this.extraCustomerItems = res.content.extraCustomerItems;
      console.log(this.tabs);
      console.log(res.content);
      if (this.taskType !== "SaleAfterV2") {
        this.tabsText = (
          this.tabs.find(i => {
            return i.value === res.content.customerFlag;
          }) || { label: undefined }
        ).label;
      }
    });
  }
  getDzTaskCustomerInfo() {
    const param = {
      customerId: this.cstId,
      taskId: this.taskId,
      siteCode: this.siteCode,
      guiderId: this.guiderId
    };
    DZMainService.dzTaskCustomerInfo(param).then(res => {
      this.customerInfo = res.content;
    });
  }
  communicateConfig() {
    // return DZMainService.getCommunicateConfig({
    //   taskId: this.taskId
    // }).then(res=>{
    //  // todo
    // })
  }
  getDzV2TaskCustomerExecList() {
    const param = {
      customerId: this.cstId,
      guiderId: this.guiderId,
      siteCode: this.siteCode,
      taskId: this.taskId,
      taskRecordExecId: this.taskRecordId
    };
    DZMainService.dzV2TaskCustomerExecList(param).then(res => {
      this.taskCustomerExecList = res.content.list.map((val, index) => {
        (val as any).isOpen = index === 0;
        return val;
      });
      if (this.taskCustomerExecList && this.taskCustomerExecList.length) {
        this.execGuider = this.taskCustomerExecList[0].execGuider;
      }
    });
  }

  vipInfo(h) {
    if (this.customerInfo.mySiteCode === this.siteCode) {
      if (this.customerInfo.myGuider === this.guiderId) {
        return (
          <span class="vip" style={{ background: "#ED513F" }}>
            我的会员
          </span>
        );
      } else {
        return (
          <span class="vip" style={{ background: "rgb(33, 27, 195)" }}>
            本店会员
          </span>
        );
      }
    } else {
      return (
        <span class="vip" style={{ background: "#21C38C" }}>
          非本店会员
        </span>
      );
    }
  }

  linkToWeChatApp() {
    console.log("linkToWeChatApp");

    InitJSSDK.Init("navigateTo", () => {
      (window as any).wx.miniProgram.navigateTo({
        url: AppUrls.customerCenterV2 + `?cstId=${this.cstId}&external=ext`
      });
    });
  }

  render(h) {
    return (
      <div
        class="executive-details"
        onClick={() => {
          this.$emit("cancel");
        }}
      >
        <div
          class="detail-wrap"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <div class="header">
            <img
              src={this.customerInfo.cstAvatar}
              alt=""
              class="head"
              onClick={this.linkToWeChatApp.bind(this)}
            />
            <div class="info">
              <p class="name">
                <span class="name-text">{this.customerInfo.cstName}</span>
                {this.vipInfo(h)}
                <i class="iconfont icon-jiantou" />
              </p>
              <p class="other-info">
                <span class="sex">{this.customerInfo.cstSex}</span>
                <span class="card">{this.customerInfo.cstGrade}</span>
                <span class="Birthday">
                  生日 &nbsp;{this.customerInfo.cstBirthday}
                </span>
              </p>
            </div>
            <div
              class="phone"
              data-clipboard-text={this.customerInfo.cstMobile}
            >
              <span class="text">
                {" "}
                {this.customerInfo.cstMobile
                  ? mobileNumberChange(this.customerInfo.cstMobile)
                  : "暂无手机号"}
              </span>
            </div>
          </div>

          <div class="detail-content">
            <div class="returnVisit">
              <p class="guider">
                <span class="lable">
                  {this.taskType === "SaleAfterV2" ? "回访" : "沟通"}导购
                </span>
                <span class="text">{this.getGuiderText}</span>
              </p>
              <p class="guider">
                <span class="lable">
                  {this.taskType === "SaleAfterV2" ? "回访" : "沟通"}进展
                </span>
                <span class="text">{this.tabsText}</span>
              </p>
            </div>
            {this.customerPurchaseDataList &&
            this.customerPurchaseDataList.length ? (
              <div class="extra-customer-items">
                <div class="title">任务期间本店买单情况</div>
                <div class="cont">
                  {this.customerPurchaseDataList.map(i => {
                    return (
                      <div class="extra-list">
                        <span class="lable">{i.sldTime}</span>
                        <span class="text">{"￥" + i.paId}</span>
                      </div>
                    );
                  })}
                </div>
                <img src={yimaidanImg} alt="" class="imprint" />
              </div>
            ) : (
              ""
            )}
            {this.extraCustomerItems && this.extraCustomerItems.length ? (
              <div class="extra-customer-items">
                <div
                  class="title"
                  onClick={() => {
                    this.extraCustomerItemsOpen = !this.extraCustomerItemsOpen;
                  }}
                >
                  会员附加信息
                  <i
                    class={
                      this.extraCustomerItemsOpen
                        ? "iconfont icon-less"
                        : "iconfont icon-moreunfold"
                    }
                  />
                </div>
                {this.extraCustomerItemsOpen ? (
                  <div class="cont">
                    {this.extraCustomerItems.map(i => {
                      return (
                        <div class="extra-list">
                          <span class="lable">{i.key}</span>
                          <span class="text">{i.val}</span>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {this.taskType === "SaleAfterV2" &&
            this.customerOrderList &&
            this.customerOrderList.length ? (
              <div class="about-oder">
                <div
                  class="title"
                  onClick={() => {
                    this.aboutOrderIsOpen = !this.aboutOrderIsOpen;
                  }}
                >
                  <span class="text">相关订单</span>
                  <i
                    class={
                      this.aboutOrderIsOpen
                        ? "iconfont icon-less"
                        : "iconfont icon-moreunfold"
                    }
                  />
                </div>
                {this.aboutOrderIsOpen ? (
                  <div class="cont">
                    {this.customerOrderList.map(item => {
                      return (
                        <div class="oder-list">
                          <div class="order-title">
                            <span class="name">订单号：{item.orderId}</span>
                            <span class="time">{item.sldTime}</span>
                          </div>
                          {item.items.map(i => {
                            return (
                              <div class="goods-info">
                                <img src={i.img} alt="" class="order-img" />
                                <div class="info">
                                  <div class="name">{i.artName}</div>
                                  <p class="color">
                                    {i.spec}&nbsp;&nbsp;{i.size || ""}
                                  </p>
                                  <p class="money">
                                    <span class="price">￥ {i.paid}</span>
                                    <span class="old-pri">￥{i.price}</span>

                                    <span class="num">X {i.quantity}</span>
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                          <div class="all">
                            <span class="all-num">共{item.quantity}件商品</span>
                            <span class="all-pri">实付：￥{item.paid}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}

            {this.taskCustomerExecList && this.taskCustomerExecList.length ? (
              <div class="communicated-list">
                {this.taskCustomerExecList.map(i => {
                  return (
                    <div class="content">
                      <div
                        class="title"
                        onClick={() => {
                          i.isOpen = !i.isOpen;
                        }}
                      >
                        <span class="label" style="color: #000">
                          {i.numberOfTime}
                        </span>
                        <span class="cont">
                          {i.execTimeFm}
                          <i
                            class={
                              i.isOpen
                                ? "iconfont icon-less"
                                : "iconfont icon-moreunfold"
                            }
                          />
                        </span>
                      </div>
                      {i.isOpen ? (
                        <ul class="list">
                          <li>
                            <span class="label">沟通方式</span>
                            <span class="cont">{i.communicateType}</span>
                          </li>
                          {this.taskType !== "SaleAfterV2" ? (
                            <li>
                              <span class="label">回购意向</span>
                              <span class="cont">{i.communicatesResult}</span>
                            </li>
                          ) : (
                            ""
                          )}
                          {i.communicateFeedback && i.isOpen ? (
                            <li>
                              <span class="label">沟通反馈</span>
                              <span
                                class="cont"
                                style={{
                                  color:
                                    i.communicateFeedback === "拒绝骚扰" ||
                                    i.communicateFeedback === "不住本地"
                                      ? "red"
                                      : "#666"
                                }}
                              >
                                {i.communicateFeedback}
                              </span>
                            </li>
                          ) : (
                            ""
                          )}
                          <li style="height: auto;">
                            <span class="label">跟进事项</span>
                            <span class="cont-text">
                              {i.remark ? i.remark : "无"}
                            </span>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                {this.execResult === "已忽略" ? (
                  <div class="notYetBegun">本次任务已忽略</div>
                ) : (
                  <div class="notYetBegun">本次任务暂未开始执行</div>
                )}
              </div>
            )}
          </div>
        </div>

        <div class="bottom-btn">
          <div
            class="close"
            onClick={() => {
              this.$emit("cancel");
            }}
          >
            关闭
          </div>
        </div>
      </div>
    );
  }
}
