const pre = "/pages";
export const AppUrls = {
  loading: pre + "/loading/index", // 加载页面
  index: pre + "/index/index", // 首页
  error: pre + "/error/index", // 错误页面
  about: pre + "/about/index", // 关于
  verification: pre + "/before/verification/index", // 微信手机验证
  bindPhone: pre + "/before/bindPhone/index", // 手动绑定手机号
  chooseShop: pre + "/before/chooseShop/index", // 选择店铺页
  couponDetail: pre + "/coupon/cpnDetail/index", // 券详情页（H5）
  search: pre + "/search/index", // 搜索
  selectCorporate: pre + "/before/selectCorporate/index", // 选择企业
  reportIndex: pre + "/report/reportIndex/index", // 报表-首页
  memberIndex: pre + "/member/memberIndex/index", // 入会-首页
  admission: pre + "/member/admission/index", // 入会-手工入会-付费升级
  couponIndex: pre + "/coupon/couponIndex/index", // 优惠券-首页
  writeOff: pre + "/coupon/writeOff/index", // 优惠券-已核券页
  writeOffList: pre + "/coupon/writeOffList/index", // 优惠券-已核券列表页
  lock: pre + "/coupon/lock/index", // 优惠券-锁券页面
  lockList: pre + "/coupon/lockList/index", // 优惠券-锁券列表页面
  writeOffDetail: pre + "/coupon/writeOffDetail/index", // 优惠券-已核销券详情页
  unwriteOffDetail: pre + "/coupon/unwriteOffDetail/index", // 优惠券-待核销券详情页
  shopIndex: pre + "/shop/shopIndex/index", // 店铺管理-首页
  member: pre + "/shop/member/index", // 店铺管理-员工管理页
  memberDetail: pre + "/shop/memberDetail/index", // 店铺管理-员工详情页
  targetAssign: pre + "/shop/targetAssign/index", // 店铺管理-目标分配页
  transferCustomers: pre + "/shop/transferCustomers/index", // 店铺管理-转移顾客
  goodList: pre + "/product/goodList/index", // 商品-商品列表页
  goodsDetails: pre + "/product/goodsDetails/index", // 商品-商品详情页
  pictureIndex: pre + "/picture/zhengTu/index", // 集图宝-首页
  upLoadImg: pre + "/picture/upLoadImg/index", // 集图宝-上传图片(征图)
  upLoadDetails: pre + "/picture/upLoadDetails/index", // 集图宝-上传图片详情
  customerIndex: pre + "/customer/customerIndex/index", // 顾客中心-首页
  followingGuider: pre + "/customer/followingGuider/index", // 顾客中心-导购的顾客跟踪事项页
  followingDetailGuider: pre + "/customer/followingDetailGuider/index", // 顾客中心-导购的顾客跟踪事项详情页
  followingCustomer: pre + "/customer/followingCustomer/index", // 顾客中心-导购的顾客跟踪事项页
  followingDetailCustomer: pre + "/customer/followingDetailCustomer/index", // 顾客中心-导购的顾客跟踪事项详情页
  unassignedCustomers: pre + "/customer/unassignedCustomers/index", // 顾客中心-未分配顾客页
  customerCenter: pre + "/customer/customerCenter/index", // 顾客中心-顾客中心页
  customerCenterV2: pre + "/customer-v2/cst360/index", // 顾客中心-顾客中心页
  comTask: pre + "/customer/comTask/index", // 顾客中心-任务沟通页
  siteTransfer: pre + "/customer/siteTransfer/index", // 顾客中心-转店记录页
  guiderImpression: pre + "/customer/guiderImpression/index", // 顾客中心-导购印象页
  customerAffairs: pre + "/customer/customerAffairs/index", // 顾客中心-本店动态页
  manualTags: pre + "/customer/manualTags/index", // 顾客中心-手动标签页
  personInfo: pre + "/customer/personInfo/index", // 顾客中心-个人信息页
  coupon: pre + "/customer/coupon/index", // 顾客中心-优惠券页
  customerPoint: pre + "/customer/customerPoint/index", // 顾客中心-积分页
  members: pre + "/customer/members/index", // 顾客中心-我的会员页
  customerGroup: pre + "/customer/customerGroup/index", // 顾客中心-顾客组页
  addCustomerGroup: pre + "/customer/addCustomerGroup/index", // 顾客中心-新建顾客组页
  customerGroupDetail: pre + "/customer/customerGroupDetail/index", // 顾客中心-顾客组详情页
  orders: pre + "/customer/orders/index", // 顾客中心-已购宝贝页
  taskIndex: pre + "/task/taskIndex/index", // 任务中心-首页
  addTask: pre + "/task/addTask/index", // 任务中心-新建任务
  wordList: pre + "/task/wordList/index", // 任务中心-参考话术列表
  editWordList: pre + "/task/editWordList/index", // 任务中心-编辑话术列表
  editLab: pre + "/task/editLab/index", // 任务中心-沟通顾客标签
  myTaskList: pre + "/task/myTaskList/index", // 任务中心-我发布的任务
  taskCentered: pre + "/task/taskCentered/index", // 任务中心-任务中心
  taskDetail: pre + "/task/taskDetail/index", // 任务中心-我发布的任务详情
  taskExecResultInfo: pre + "/task/taskExecResultInfo/index", // 任务中心-任务详情-h5效果页
  taskCenteredPassenger: pre + "/task/taskCenteredPassenger/index", // 任务中心-客流量统计任务页
  selectCustomers: pre + "/task/selectCustomers/index", // 顾客中心-选择顾客
  taskTrack: pre + "/task/taskTrack/index", // 顾客中心-选择顾客
  birthdayCareTask: pre + "/task/birthdayCareTask/index", // 任务中心-生日回访的列表
  taskAfterSale: pre + "/task/taskAfterSale/index", // 任务中心-售后回访页面
  ranking: pre + "/ranking/rankingIndex/index", // 排行榜
  RankingListPG: pre + "/ranking/rankingIndex/index", // 排行榜
  cubeReportIndex: pre + "/report/cubeReport/index", // 数据魔方跳转
  reportIndexPG: pre + "/report/reportIndexPG/index", // 新的PG的报表
};
