// 会员招募
import * as F2 from "@antv/f2";
import * as $ from "jquery";
import service from "../../service/index";
import GuiderList from "@/components/guiderList";
import eventBus from "@/util/eventBus";

import { formatNum } from "@/util/utils";

const yesterday = 1;
let seleectParams: any = {};
let topVa: number = yesterday; // 头部时间,默认昨天

/**
 * 生成线图对比图表
 * @param {Array<yearVSListItem>} data
 * @param {string} id
 */
function createLineChart(data: PassengerInterval[], id: string): void {
  /**
   * 格式化日期
   * @param {string} oldDate
   * @return {string}
   */
  function createDate(oldDate: string): string {
    const array = oldDate.split("-");
    let str = "";
    str =
      array.length === 3
        ? `${array[0]}\n${array[1]} - ${array[2]}`
        : `${array[0]}\n${array[1]}`;
    return str;
  }
  // 格式化data
  const newData: { type: string; year: string; value: number }[] = [];
  // data.reverse(); // 反转数组
  data.forEach(item => {
    newData.push({
      type: "同期",
      year: createDate(item.theTime),
      value: item.sameTimePercent
    });
    newData.push({
      type: "本期",
      year: createDate(item.theTime),
      value: item.ratePercent
    });
  });

  const chart :any= new F2.Chart({
    id,
    pixelRatio: window.devicePixelRatio
  });
  chart.source(newData, {
    value: {
      min: 0,
      formatter: function formatter(val: any) {
        // tslint:disable-next-line: restrict-plus-operands
        return val + "%";
      }
    }
  });
  chart.axis("year");
  chart.axis("value");
  chart.tooltip({});
  chart
    .area()
    .position("year*value")
    .color("type")
    .shape("smooth");
  chart
    .line()
    .position("year*value")
    .color("type")
    .shape("smooth");
  chart.render();
}

/**
 * 清除图表缓存
 */
function initCanvas() {
  $("#customerCountChartWrap").html(
    '<canvas style="width: 100%; height: 100%;" id="customerCountChart"></canvas>'
  );
  $("#onlyWearChartWrap").html(
    '<canvas style="width: 100%; height: 100%;" id="onlyWearChart"></canvas>'
  );
  $("#buyChartWrap").html(
    '<canvas style="width: 100%; height: 100%;" id="buyChart"></canvas>'
  );
  $("#wearAndBuyChartWrap").html(
    '<canvas style="width: 100%; height: 100%;" id="wearAndBuyChart"></canvas>'
  );
}

/**
 * 数据请求
 */
function getDatas(va: any = topVa, param?) {
  initCanvas();
  // R8-1 . 报表 - 客流数据
  service
    .customerCount({ ...{ va }, ...param })
    .then(result => {
      customerCountChart(result.content.list, "customerCountChart");
    })
    .catch((error: any) => {
      console.log(error);
    });

  // 生成对比图表
  service
    .customerCountCompare({ ...{ va }, ...param })
    .then(result => {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < result.content.list.length; i++) {
        switch (result.content.list[i].code) {
          case "enterTryRate":
            createLineChart(result.content.list[i].list, "onlyWearChart"); // 进店试穿率趋势及同期对比
            break;
          case "enterDealRate":
            createLineChart(result.content.list[i].list, "buyChart"); // 进店试穿率趋势及同期对比
            break;
          case "tryDealRate":
            createLineChart(result.content.list[i].list, "wearAndBuyChart"); // 进店试穿率趋势及同期对比
            break;
        }
      }
    })
    .catch((error: any) => {
      console.log(error);
    });
}

/**
 * 生成客流数据图表
 * @param {Array<CustomerCountListItem>} data
 * @param {string} id
 */
function customerCountChart(data: customerCount[], id: string): void {
  if (data && data.length > 0) {
    data.reverse();
    const Global = F2.Global;
    const chart:any= new F2.Chart({
      id,
      pixelRatio: window.devicePixelRatio
    });
    chart.source(data, {
      val: {
        tickCount: 5
      }
    });
    chart.coord({
      transposed: true
    });
    chart.axis("itemName", {
      line: Global._defaultAxis.line,
      grid: null
    });
    chart.axis("val", {
      line: null,
      grid: Global._defaultAxis.grid,
      label: function label(text: number, index: number, total: number) {
        const textCfg: any = {};
        if (index === 0) {
          textCfg.textAlign = "left";
        } else if (index === total - 1) {
          textCfg.textAlign = "right";
        }
        return textCfg;
      }
    });
    chart.tooltip({
      showItemMarker: false,
      background: {
        radius: 2,
        fill: ["#1890FF", "red", "green"],
        padding: [3, 5]
      },
      tooltipMarkerStyle: {
        fill: "#1890FF",
        fillOpacity: 0.1
      },
      onShow: function onShow(ev: any) {
        const items = ev.items;
        items[0].name = items[0].title;
        items[0].value = formatNum(items[0].value);
      }
    });
    chart
      .interval()
      .position("itemName*val")
      .color("itemName");
    chart.render();
  } else {
    $(`#${id}`)
      .parent()
      .html(
        `<div class="no-data"><i class="icon-kongshuju iconfont"></i><div>无数据</div></div>`
      );
  }
}

/**
 * 获取店铺列表
 */
function getShopList() {
  service
    .guiderInfo()
    .then((result: guiderInfoReponse<guiderInfoReponseItem>) => {
      const item = result.content.list.find(el => el.isDefault);
      if (+item.post === 101) {
        $(".top-tap-wrap").show();
      } else {
        $(".top-tap-wrap").hide();
      }
    })
    .catch(error => {
      console.log(error);
    });
}

/**
 * 获取说明
 */
function getExtendInfo() {
  service.extendInfo().then((result: extendInfoResponse) => {
    if (
      result.content.functional_module_desc.Passenger &&
      result.content.functional_module_desc.Passenger !== ""
    ) {
      $(".warning").show();
      $("#dialogCover .content").html(
        result.content.functional_module_desc.Passenger
      );
    } else {
      $(".warning").hide();
    }
  });
}

/**
 * 页面事件
 */
function pageEvents() {
  $("#help").click(() => {
    $("#dialogCover").show();
    $("body").addClass("lock-scroll");
    $("html").addClass("lock-scroll");
  });

  $(".top-nav-wrap li").click(function() {
    if (![3, 5, 6].find(i => i === +$(this).attr("data-day"))) {
      $(".lastDayTip").hide();
    } else {
      $(".lastDayTip").show();
    }
    $(this)
      .addClass("nav-on-selected")
      .siblings()
      .removeClass("nav-on-selected");
    topVa = +$(this).attr("data-day");
    eventBus.emit("taskCenterOnSelectGuider", {
      empCode: "",
      empName: "全店",
      guiderId: ""
    });
    getDatas(topVa);
  });
  $("#showDialog").click(() => {
    $("#dialogCover").show();
    $("body").addClass("lock-scroll");
    $("html").addClass("lock-scroll");
  });

  $("#closeDialog").click(() => {
    $("#dialogCover").hide();
    $("body").removeClass("lock-scroll");
    $("html").removeClass("lock-scroll");
  });

  $(".top-tap-wrap span").click(function() {
    const type = $(this).attr("data-type");
    $(this)
      .addClass("tap-on-selected")
      .siblings()
      .removeClass("tap-on-selected");
    if (type === "1") {
      seleectParams = { empCode: localStorage.getItem("empCode") };
      seleectParams.guiderId = localStorage.getItem("guiderId");
      window.localStorage.setItem("isGuider", "1");
      $(".data-select-wrap").hide();
    } else {
      seleectParams = { empCode: "" };
      seleectParams.guiderId = localStorage.getItem("guiderId");
      window.localStorage.setItem("isGuider", "0");
      $(".data-select-wrap").show();
      eventBus.emit("taskCenterOnSelectGuider", {
        empCode: "",
        empName: "全店",
        guiderId: ""
      });
      (window as any).guiderId = $("#guiderSelect").attr("data-id");
      (window as any).empCode = $("#guiderSelect").attr("data-code");

      eventBus.emit("taskCenterOnSelectGuider", {
        empCode: "",
        empName: "全店",
        guiderId: ""
      });
    }

    getDatas();
  });

  $(".popupMask").click(() => {
    $(".guider-list").hide();
    $(".popupMask").hide();
  });
}

const getPageData = () => {
  // tslint:disable-next-line: no-shadowed-variable
  let topVa: any = 1;
  $(".top-nav-wrap")
    .children("li")
    .each((index: any, el: any) => {
      if ($(el).hasClass("nav-on-selected")) {
        topVa = $(el).attr("data-day");
      }
    });
  getDatas(topVa, seleectParams);
};

function getIsvMPConfig() {
  service
    .isvMPConfig()
    .then(result => {
      if (result.content.mpCode !== "MPRStore") {
        $("#homeTab").show();
      } else {
        $("#homeTab").hide();
      }
    })
    .catch(error => {
      console.log(error);
    });
}

function sale_divide() {
  service
    .contactMemberGet({ appointedGuiderId: localStorage.getItem("guiderId") })
    .then((resp: any) => {
      const siteRole = window.localStorage.getItem("siteRole");

      if (siteRole !== "101" && +resp.content.performanceType !== 1) {
        $(".top-tap-wrap").hide();
        $(".guider-tip-wrap").show();
      }
    });
}

import { Component, Vue } from "vue-property-decorator";
import _Template from "./customerCount-temp.vue";
import { QinggerLibURL } from "qingger-lib";
import HelpTips from "@/components/helpTips";

@Component({
  mixins: [_Template],
  components: { GuiderList,HelpTips }
})
export default class CustomerCount extends Vue {
  protected guiderList: any[] = [];

  mounted() {
    console.log("mounted");
    document.title = "客流统计";

    const url = window.location.href;
    const urlLibUtil = QinggerLibURL.urlUtil(url);
    const tokenFromQuery = urlLibUtil.getUrlQueryItem("token");
    const siteFromQuery = urlLibUtil.getUrlQueryItem("siteCode");
    const guiderFromQuery = urlLibUtil.getUrlQueryItem("guiderId");
    const empFromQuery = urlLibUtil.getUrlQueryItem("empCode");
    const siteRoleQuery = urlLibUtil.getUrlQueryItem("siteRole");

    if (tokenFromQuery) {
      window.localStorage.setItem("token", tokenFromQuery || "token-1");
    }
    if (siteFromQuery) {
      window.localStorage.setItem("siteCode", siteFromQuery || "");
    }
    if (guiderFromQuery) {
      window.localStorage.setItem("guiderId", guiderFromQuery || "");
    }
    if (empFromQuery) {
      window.localStorage.setItem("empCode", empFromQuery || "");
    }
    if (siteRoleQuery) {
      window.localStorage.setItem("siteRole", siteRoleQuery || "");
    }
    // 当token失效时，可能已经有多个请求已同时发出。通过此属性，可以判断此token是否短期内被更新过，防止重复更新token
    window.localStorage.setItem("tokenTime", `${new Date().valueOf()}`);

    // 设置是否是导购
    window.window.localStorage.setItem("isGuider", "1");
    setTimeout(() => {
      $("body").show();
      // getShopList();
      getIsvMPConfig();
      pageEvents();
      getDatas();
      getExtendInfo();
      sale_divide();
      this.getGuiderList();
    }, 100);
  }
   showTipDialog(){
    $("#dialogCover").show();
  }
  getData(guiderId, empCode) {
    seleectParams = { guiderId, empCode };
    getPageData();
  }
  getGuiderList() {
    service
      .siteGuiderList()
      .then((data: any) => {
        this.guiderList = data.content.list;
      })
      .catch(error => {
        console.log(error);
      });
  }
}
