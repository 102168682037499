/**
 * Created by jiangxd on 2019/6/12.
 */

import * as $ from "jquery";
import service from "../../service/index";
import {Component, Vue} from "vue-property-decorator";
import _Template from "./target-temp.vue";

import { formatNum, createCircleChart, createPieChart } from "@/util/utils";

const yesterday = 1;
const type: string = "";

const topVa: any = yesterday; // 头部时间,默认昨天

let targetdDate = "";

function getData() {
  service.guiderTargetInfo().then(res => {
    const data = res.content;
    targetdDate = data.month;
    document.title = targetdDate + "目标";
    $(".user .img").attr("src", data.avatar);
    $(".user .name").html(data.empName);
    $(".user .sourse").html(data.siteName);
    $(".target .target-title").html(targetdDate + "总目标");
    $("#targetNum").html(formatNum(data.saleTarget, 1));
    $("#targetSole").html(formatNum(data.recruitTarget, 1));

    let element = "";

    if (data.products && data.products.length > 0) {
      $(".no-data").hide();
      data.products.forEach(i => {
        element +=
// tslint:disable-next-line: restrict-plus-operands
          "<li>\n" +
          "  <img" +
          "    src=" +
          i.img +
          "    alt=''\n" +
          "    class='pri'\n" +
          "  />\n" +
          "  <div class='info'>\n" +
          "    <p class='sourse' style=' color: #999;'>" +
          i.productCode +
          "</p>\n" +
          "    <p class='name'>" +
          i.productName +
          "</p>\n" +
          "  </div>\n" +
          "  <span class='num blue'>" +
          formatNum(i.numTarget, 1) +
          " <span class='suf'>件</span></span>\n" +
          "</li>\n";
      });

      $(".body .cont ul").html(element);
    } else {
      $(".no-data").show();

      $(".body .cont ul").html("");
    }
  });
}

@Component({
  mixins: [_Template]
})
export default class Target extends Vue {
  mounted() {
      window.localStorage.setItem("isGuider","1");
    (window as any).guiderId = localStorage.getItem("guiderId");
    $("body").show();
    getData();
  }
}
