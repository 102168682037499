import { Vue, Component, Prop } from "vue-property-decorator";
import DZMainService from "@/service";
import DzTabs from "@/components/dzTabs";
import DzMenu from "@/components/dzMenu";
import eventBus from "@/util/eventBus";
import ExecutiveDetails from "@/components/executiveDetails";
import InitJSSDK from "@/util/initJSSDK";
import { AppUrls } from "@/util/consts";
import TaskFilter from "@/components/taskFilter/filter.vue";
import { formatMoney, formatPhone, mobileNumberChange } from "@/util/format";

export const taskTypeCustomerStatus: {
  val: number;
  text: string;
  isSelected?: boolean;
}[] = [
  {
    val: 4,
    text: "未沟通",
  },
  {
    val: 5,
    text: "跟进中",
  },
  {
    val: 6,
    text: "不跟进",
  },
  {
    val: 7,
    text: "已买单",
  },
];

@Component({
  components: { DzTabs, DzMenu, ExecutiveDetails, TaskFilter },
})
export default class TaskCentered extends Vue {
  @Prop({ default: "" })
  taskId: string;
  @Prop({ default: "" })
  selectedGuiderId: string;
  @Prop({ default: false })
  isAllGuider: boolean;

  public searchWidth: string = "0";
  public searchFlag: boolean = false;

  public isFilterShow: boolean = false;
  public filterListArray: any[] = [];
  // 筛选的参数
  public filterParam = {};
  public changeSortItem = {};
  // 是否有筛选选中的值
  public isFilterSelect: boolean = false;
  public isMainMenuWrapperShow: boolean = false;

  public keywords: string = "";
  public mobileNumberChange = mobileNumberChange;

  public tabsItem = [
    { val: 4, text: "未沟通" },
    { val: 5, text: "跟进中" },
    { val: 6, text: "不跟进" },
    { val: 7, text: "已买单" },
  ];
  public filterSearchFlag: boolean = false;

  public popMaskFlag: boolean = false; // 任务弹框

  public popMask6Flag: boolean = false; // 任务概述弹框

  public maskPopFlag: boolean = false; // 跟进中和不跟进tab中的状态选择
  public maskPopText: string = "全部状态"; // 跟进中和不跟进tab中的状态文字

  public execGuiderId: string = ""; // 执行导购（仅全部导购均可执行时有效）
  public execGuiderList: any[] = []; // 任务参与导购列表

  // 不跟进状态中额外添加已忽略状态过滤
  public ignoredItem = {
    communicatesResult: "ignore",
    communicatesResultName: "已忽略",
  };

  public taskDay: number | string = 0; // 哪一天的任务，方便于前端，用时间戳

  public customerList: any[] = [];

  public isExeced: boolean = false; // 是否沟通过

  public customerId: string = ""; // 选择的顾客ID

  public taskRecordId: string = ""; // 选择的taskRecordId

  public taskDetail: T2INFO = null;

  public chekedCustomer: customerItem = null;

  public guiderId: string = "";
  public siteCode: string = "";

  public taskResult: string = "";
  /**
   * 列表当前tab的索引值
   * @type {number}
   */
  public curTabIndex = 4;

  // public selfName:string = '';

  public siteName: string = "";
  public guiderPost: string = "";

  // 是否展示详情的标记
  public ExecutiveDetailsFlag: boolean = false;

  public listItem: any = {};
  public sortItem = [
    {
      text: "等级",
      code: "rakIndex",
      tip: "无会员等级",
      icon: "icon-biaoqian-dengji",
      color: "#F09803",
      getCode: "rakName",
      sort: "desc",
      active: true,
    },
    {
      text: "积分",
      sort: "",
      icon: "icon-biaoqian-jifen",
      color: "#FFB900",
      code: "pntVal",
      tip: "0",
      getCode: "pntVal",
      active: false,
    },
    {
      text: "消费",
      sort: "",
      tip: "近一年未消费",
      icon: "icon-biaoqian-xiaofei",
      getCode: "totalNumsFor365Days",
      color: "#ED513F",
      code: "totalNumsFor365Days",
      active: false,
    },
  ];

  // 排序选中项
  public changeSortItemValue = this.sortItem[0];
  public tabs: { label: string; value: number }[] = [
    {
      label: "未沟通",
      value: 4,
    },
    {
      label: "跟进中",
      value: 5,
    },
    {
      label: "不跟进",
      value: 6,
    },
    {
      label: "已买单",
      value: 7,
    },
  ];
  public page = 1;
  public pageSize = 30;
  public count = 0; // 总数量
  public isScroll: boolean = true; // 是否还能滚动
  public isSearchedListLoadMore: boolean = true;
  public listLoading: boolean = false;
  public isSearchedListEnd: boolean = false;

  public communInfo: any = {
    communicatesResults: [],
  };

  // 任务类型
  public taskTypeCode: string = "";

  public TASK_TYPE_TO_KEY = {
    MemberNCV2: "regTimeFormat", // 入会未消费会员沟通
    AwakeSleepV2: "lastSaleDay", // 沉默会员唤醒
  };
  public TASK_TYPE_TO_TEXT = {
    MemberNCV2: "入会", // 入会未消费会员沟通
    AwakeSleepV2: "上次消费", // 沉默会员唤醒
  };
  public taskTypeToItem: string = "";

  public copySortItem = JSON.parse(JSON.stringify(this.sortItem));

  public tabsText: string = "";

  public taskType: string = "";
  public scrollFunction = this.debounce(this.scrollFunc, 250); // 注册事件方法
  public searchShow: boolean = false;
  public openSearchElement() {
    this.searchWidth = "94%";
    this.searchFlag = true;
    this.searchShow = true;
    this.filterSearchFlag = true;
    this.keywords = "";
    this.clearFilter();
    this.clearSort();
    this.clearBoth();
    this.page = 1;
    this.customerList = [];
  }
  public closeSearchElement() {
    this.searchWidth = "0";
    this.curTabIndex = 4;
    this.keywords = "";
    this.searchShow = false;
    this.searchFlag = false;
    this.filterSearchFlag = false;
    this.clearFilter();
    this.clearSort();
    this.clearBoth();
    this.page = 1;
    this.customerList = [];
    this.getCommunicateInfo();
  }
  public searchHandle() {
    this.clearSort();
    this.clearBoth();
    (this.$el.querySelector(".search-input") as any).blur();
    this.page = 1;
    this.customerList = [];
    this.getCommunicateInfo().then(() => {
      this.searchFlag = false;
    });
  }
  public clearFilter() {
    this.$nextTick(() => {
      eventBus.emit("clearFilterData", true);
      this.isFilterShow = false;
    });
    this.isFilterSelect = false;
    this.filterParam = {};
  }

  public clearSort() {
    const deepSortItem = JSON.parse(JSON.stringify(this.copySortItem));
    this.$set(this, "sortItem", deepSortItem);
    this.changeSortItemValue = this.sortItem[0];
    this.changeSortItem = {};
  }

  public selectedTagsArray(param, status) {
    this.clearBoth();
    this.filterParam = param;
    this.isFilterSelect = status;

    this.getCommunicateInfo();
  }
  public clearBoth() {
    this.page = 1;
    this.customerList = [];
    this.isScroll = true;
    this.count = 0;
    this.isSearchedListEnd = false;
  }
  public handleSortItem(item, index) {
    this.clearFilter();
    this.clearBoth();

    this.sortItem = this.sortItem.map((val, ind) => {
      if (index === ind) {
        val.active = true;
        val.sort = val.sort ? (val.sort === "asc" ? "desc" : "asc") : "asc";

        this.changeSortItemValue = val;
      } else {
        val.active = false;
        val.sort = "";
      }
      return val;
    });

    this.changeSortItem = {
      orderKey: this.changeSortItemValue.code,
      orderType: this.changeSortItemValue.sort,
    };

    this.getCommunicateInfo();
  }

  public mounted() {
    this.taskRecordId = "";
    this.customerId = "";
    this.taskDetail = null;

    // 获取所需信息
    this.guiderPost = window.localStorage.getItem("siteRole");
    this.siteCode = window.localStorage.getItem("siteCode");
    this.guiderId = window.localStorage.getItem("guiderId");

    if (+this.guiderPost === 102) {
      this.execGuiderId = this.guiderId;
    }

    this.taskDayInfo();
    this.clearData();
    this.getCommunicateInfo();
    this.communicateConfig();

    // 绑定监听
    this.$el.querySelector(".con-list").addEventListener("scroll", this.scrollFunction);

    // 监听切换导购事件
    eventBus.on("selectedGuiderId", (guiderId) => {
      this.execGuiderId = guiderId;
      this.page = 1;
      this.getCommunicateInfo();
    });
  }

  public showDetail(item) {
    this.tabsText = this.tabs.find((i) => {
      return i.value === this.curTabIndex;
    }).label;
    this.listItem = item;
    this.ExecutiveDetailsFlag = true;
  }

  // 监听滚动条事件
  public scrollFunc(e) {
    const scrollDiv = this.$el.querySelector(".con-list");
    const clientHeight = scrollDiv.clientHeight;
    const scrollTop = scrollDiv.scrollTop;
    const scrollHeight = scrollDiv.scrollHeight;
    console.log(scrollTop + clientHeight + 100 > scrollHeight);
    if (scrollTop + clientHeight + 100 > scrollHeight && this.isScroll) {
      console.log("到底部了！");
      this.isScroll = false;
      this.getCommunicateInfo(true);
    }
  }

  // 防抖动函数
  public debounce(cb, waitTime, immediate?) {
    let timeout;
    return function() {
      const args = arguments;
      const later = () => {
        timeout = null;
        if (!immediate) {
          cb.apply(this, args);
        }
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, waitTime);
      if (callNow) {
        cb.apply(this, args);
      }
    };
  }
  /*清除数据*/
  public clearData() {
    this.page = 1;
    this.isScroll = true;
    this.isSearchedListEnd = false;
    this.customerList = [];
    this.count = 0;
    this.listItem = {};
  }
  /**
   * 任务执行人列表
   */
  public getCommunicateInfo(formScroll?: boolean) {
    this.listLoading = true;
    // 修复滚动条到底部后显示加载中
    if (formScroll) {
      setTimeout(() => {
        const scrollDiv = this.$el.querySelector(".con-list");
        const clientHeight = scrollDiv.clientHeight;
        const scrollHeight = scrollDiv.scrollHeight;
        console.log(scrollDiv.scrollTop, scrollHeight - clientHeight - 1);
        scrollDiv.scrollTop = scrollHeight - clientHeight - 1;
      });
    }
    const postParams = {
      ...{
        taskId: this.taskId, // 任务ID
        customerFlag: this.searchShow ? "0" : this.curTabIndex,
        page: this.page,
        taskResult: this.taskResult,
        pageSize: this.pageSize,
        execGuiderId: this.execGuiderId,
        keywords: this.keywords,
        requestType: 2,
      },
      ...this.changeSortItem,
      ...this.filterParam,
    };
    return DZMainService.taskCustomerListV2(postParams)
      .then((res) => {
        if (res.code === 20000 && res.content) {
          if (res.content.list.length === 0 && this.page !== 1) {
            this.isSearchedListEnd = true;
            this.isScroll = false;
          } else {
            // 修复连续切换tab时，加载数据出错问题
            if (this.page === 1 || postParams.page === 1) {
              this.page = 1;
              this.$set(
                this,
                "customerList",
                res.content.list
                  .filter((i) => {
                    return i.cstId;
                  })
                  .map((i) => {
                    i.pntVal = formatMoney(i.pntVal);
                    return i;
                  }),
              );
            } else {
              this.customerList = this.customerList
                .concat(res.content.list)
                .filter((i) => {
                  return i.cstId;
                })
                .map((i) => {
                  i.pntVal = formatMoney(i.pntVal);
                  return i;
                });
            }
            this.isScroll = true;
            this.count = res.content.count;
            this.page++;
          }
        }
      })
      .finally(() => (this.listLoading = false));
  }

  /**
   * 任务执行详情
   */
  public taskDayInfo() {
    DZMainService.getTaskInfo({
      taskId: this.taskId,
      searchDay: this.taskDay,
    }).then((resp) => {
      this.taskDetail = resp.content;
      this.taskType = this.taskDetail.chooseGuiderType;
      if (this.taskDetail.chooseGuiderType === "AllGuider") {
        this.getExecGuiderList();
      } else {
        this.$nextTick(() => {
          eventBus.emit("taskLoadSuccess", true);
        });
      }
      this.taskTypeCode = this.taskDetail.taskTypeCode;
      // 判断是否显示任务专有的字段
      this.taskTypeToItem = this.TASK_TYPE_TO_KEY[this.taskTypeCode];
      // //获取指定时间任务列表
      DZMainService.taskDayInfo({
        taskId: this.taskId,
        searchDay: +this.taskDay,
      })
        .then((res: any) => {
          this.popMask6Flag = res.content.isFirstVisit === 1; // 导购首次进入该任务时默认展示任务概述，非首次进入不展示
        })
        .catch((e) => {
          console.log("taskDayInfo=>", e);
        });
    });
  }

  // 获取沟通配置
  public communicateConfig() {
    return DZMainService.getCommunicateConfig({
      taskId: this.taskId,
    }).then((res) => {
      this.communInfo = { ...res.content };
    });
  }

  public getCommunicatesStatusList(val, item) {
    this.taskResult = item.communicatesResult;
    this.maskPopText = item.communicatesResultName;
    this.page = 1;
    this.maskPopFlag = false;
    this.customerList = [];
    this.getCommunicateInfo();
  }

  // 任务导购类型是所有导购均可执行时，获取任务参与导购列表
  public getExecGuiderList() {
    DZMainService.taskReportExecGuiderList({
      siteCode: this.siteCode,
      guiderId: this.guiderId,
      taskId: this.taskId,
    }).then((res) => {
      this.execGuiderList = res.content.list || [];
      this.$nextTick(() => {
        eventBus.emit("taskLoadSuccess", true);
      });
    });
  }

  public closePopMask() {
    this.popMaskFlag = false;
    this.taskRecordId = "";
    this.customerId = "";
  }

  public closePopMask6() {
    this.popMask6Flag = false;
  }
  public changePopMask() {
    this.popMask6Flag = !this.popMask6Flag;
  }
  public goCostomerCenter(cstId) {
    InitJSSDK.Init("redirectTo", () => {
      (window as any).wx.miniProgram.navigateTo({
        url: AppUrls.customerCenterV2 + `?cstId=${cstId}&external=ext`,
      });
    });
  }
  /**
   * 沟通详情列表切换
   * @param {number} index
   */
  public onCommunicateInfoListSwitch(index: number): void {
    this.curTabIndex = index;

    this.clearFilter();
    this.clearSort();

    if (index === 6) {
      this.communInfo.communicatesResults.push(this.ignoredItem);
    } else {
      this.communInfo.communicatesResults = this.communInfo.communicatesResults.filter(
        (i) => i.communicatesResult !== "ignore",
      );
    }

    this.clearData();
    this.getCommunicateInfo();
  }
  /**
   * 主菜单隐藏
   * @return null
   */
  public onHideMainMenu(): void {
    setTimeout(() => {
      this.isMainMenuWrapperShow = false;
    }, 0);
  }
}
