import { Component, Prop, Vue } from "vue-property-decorator";
import DZMainService from "@/service";
import DzTabs from "@/components/dzTabs";
import DzMenu from "@/components/dzMenu";
import eventBus from "@/util/eventBus";
import ExecutiveDetails from "@/components/executiveDetails";
import InitJSSDK from "@/util/initJSSDK";
import { AppUrls } from "@/util/consts";
import moment from "moment";
import { taskTypeCustomerStatus } from "../taskCentered/taskCentered";
import TaskFilter from "@/components/taskFilter/filter.vue";
import { formatMoney, mobileNumberChange } from "@/util/format";
type ITEM_TYPE = {
  text?;
  sort?;
  active?;
  code?;
  tip?;
  icon?;
  color?;
  getCode?;
};
@Component({
  components: { DzTabs, DzMenu, ExecutiveDetails, TaskFilter },
})
export default class BirthdayCareTask extends Vue {
  get getIsFilterShow() {
    return !this.sortItem.find((i) => i.sort === "none").active || this.curTabIndex !== 4;
  }
  @Prop({ default: "" })
  taskId: string;
  @Prop({ default: "" })
  selectedGuiderId: string;
  @Prop({ default: false })
  isAllGuider: boolean;
  filterSearchFlag: boolean = false;

  public isMainMenuWrapperShow: boolean = false;
  public popMaskFlag: boolean = false; // 任务弹框

  public popMask6Flag: boolean = false; // 任务概述弹框

  public maskPopFlag: boolean = false; // 跟进中和不跟进tab中的状态选择
  public maskPopText: string = "全部状态"; // 跟进中和不跟进tab中的状态文字

  public execGuiderId: string = null; // 执行导购（仅全部导购均可执行时有效）
  public execGuiderList: any[] = []; // 任务参与导购列表

  // 不跟进状态中额外添加已忽略状态过滤
  public ignoredItem = {
    communicatesResult: "ignore",
    communicatesResultName: "已忽略",
  };

  public taskDay: number | string = 0; // 哪一天的任务，方便于前端，用时间戳

  public customerList: any[] = [];

  public isExeced: boolean = false; // 是否沟通过

  public customerId: string = ""; // 选择的顾客ID

  public taskRecordId: string = ""; // 选择的taskRecordId

  public taskDetail: T2INFO = null;

  public chekedCustomer: customerItem = null;

  public guiderId: string = "";
  public siteCode: string = "";

  public taskResult: string = "";
  /**
   * 列表当前tab的索引值
   * @type {number}
   */
  public curTabIndex = 4;

  // public selfName:string = '';

  public siteName: string = "";
  public guiderPost: string = "";

  // 是否展示详情的标记
  public ExecutiveDetailsFlag: boolean = false;

  public listItem: any = {};

  public tabs: { label: string; value: number }[] = [
    {
      label: "未沟通",
      value: 4,
    },
    {
      label: "跟进中",
      value: 5,
    },
    {
      label: "不跟进",
      value: 6,
    },
    {
      label: "已买单",
      value: 7,
    },
  ];
  public page = 1;
  public pageSize = 30;
  public count = 0; // 总数量
  public isScroll: boolean = true; // 是否还能滚动
  public isSearchedListLoadMore: boolean = true;
  public listLoading: boolean = false;
  public isSearchedListEnd: boolean = false;

  public communInfo: any = {
    communicatesResults: [],
  };

  public TASK_TYPE_TO_KEY = {
    MemberNCV2: "regTimeFormat", // 入会未消费会员沟通
    AwakeSleepV2: "lastSaleDay", // 沉默会员唤醒
  };
  public TASK_TYPE_TO_TEXT = {
    MemberNCV2: "入会", // 入会未消费会员沟通
    AwakeSleepV2: "上次消费", // 沉默会员唤醒
  };
  public taskTypeToItem: string = "";

  public scrollFunction = this.debounce(this.scrollFunc, 250); // 注册事件方法

  public tabsText: string = "";

  public taskType: string = "";

  // 分配到日
  public DivideInDayList: FT2493Date[] = [];
  public selectDivideInDay: FT2493Date = null;

  public divideInDayPlace: any[] = [];

  public isFilterShow: boolean = false;
  public filterListArray: any[] = [];

  public keywords: string = "";

  public searchWidth: string = "0";
  public searchFlag: boolean = false;
  // 筛选的参数
  public filterParam = {};
  public changeSortItem = {};

  public sortFlag: boolean = false;

  public taskTypeCode: string = "";

  public isFilterSelect: boolean = false;

  public searchShow: boolean = false;

  public taskTypeCustomerStatus = taskTypeCustomerStatus;
  public sortItem: ITEM_TYPE[] = [
    {
      text: "默认",
      sort: "none",
      active: true,
    },
    {
      text: "等级",
      code: "rakIndex",
      tip: "无会员等级",
      icon: "icon-biaoqian-dengji",
      color: "#F09803",
      getCode: "rakName",
      sort: "default",
      active: false,
    },
    {
      text: "积分",
      sort: "default",
      icon: "icon-biaoqian-jifen",
      color: "#FFB900",
      code: "pntVal",
      tip: "0",
      getCode: "pntVal",
      active: false,
    },

    {
      text: "消费",
      sort: "default",
      tip: "近一年未消费",
      icon: "icon-biaoqian-xiaofei",
      getCode: "totalNumsFor365Days",
      color: "#ED513F",
      code: "totalNumsFor365Days",
      active: false,
    },
  ];

  get filteredSortItem() {
    return this.sortItem.filter((i) => {
      return i.sort === "none" ? this.curTabIndex === 4 : true;
    });
  }

  // 排序选中项
  public changeSortItemValue = this.sortItem[0];

  public birthdayDef = 1;

  public copySortItem = JSON.parse(JSON.stringify(this.sortItem));

  public mobileNumberChange = mobileNumberChange;

  openSearchElement() {
    this.searchWidth = "96%";
    // 生日和其他任务不同
    this.changeSortItemValue = this.sortItem[1];

    this.searchFlag = true;
    this.curTabIndex = 5;
    this.filterSearchFlag = true;
    this.searchShow = true;
    this.keywords = "";
    this.clearFilter();
    this.clearSort();
    this.clearBoth();
  }
  closeSearchElement() {
    // 生日和其他任务不同
    this.changeSortItemValue = this.sortItem[0];

    this.searchWidth = "0";
    this.curTabIndex = 4;
    this.searchFlag = false;
    this.searchShow = false;
    this.filterSearchFlag = false;
    this.keywords = "";
    this.clearFilter();
    this.clearSort();
    this.clearBoth();
    this.getCommunicateInfo();
  }
  searchHandle() {
    this.clearSort();
    this.clearBoth();
    (this.$el.querySelector(".search-input") as any).blur();

    this.getCommunicateInfo().then(() => {
      this.searchFlag = false;
    });
  }

  clearFilter() {
    this.$nextTick(() => {
      eventBus.emit("clearFilterData", true);
      this.isFilterShow = false;
    });
    this.isFilterSelect = false;
    this.sortFlag = false;
    this.filterParam = {};
  }
  clearSort() {
    let deepSortItem = JSON.parse(JSON.stringify(this.copySortItem));

    if (this.curTabIndex !== 4 || this.searchShow) {
      deepSortItem = deepSortItem.map((i, idx) => {
        if (i.code === "rakIndex") {
          i.active = true;
          i.sort = "desc";
        } else {
          i.active = false;
        }
        return i;
      });
    }

    this.$set(this, "sortItem", deepSortItem);
    this.changeSortItemValue = deepSortItem.find((i) => i.active);
    this.changeSortItem = {};
    this.sortFlag = false;
  }

  mounted() {
    this.execGuiderId = this.selectedGuiderId;
    this.taskRecordId = "";
    this.customerId = "";
    this.taskDetail = null;

    // 获取所需信息
    this.guiderPost = window.localStorage.getItem("siteRole");
    this.siteCode = window.localStorage.getItem("siteCode");
    this.guiderId = window.localStorage.getItem("guiderId");

    if (+this.guiderPost === 102) {
      this.execGuiderId = this.guiderId;
    }

    this.clearData();
    this.getCommunicateInfo().then(() => {
      this.taskDayInfo();
    });
    this.communicateConfig();

    // 绑定监听
    this.$el.querySelector(".con-list").addEventListener("scroll", this.scrollFunction);

    // 监听切换导购事件
    eventBus.on("selectedGuiderId", (guiderId) => {
      this.execGuiderId = guiderId;
      this.customerList = [];

      if (this.taskDetail.divideInDay && this.curTabIndex === 4) {
        this.getDivideInDayDate();
      } else {
        this.getCommunicateInfo();
      }
    });
  }
  onFilterClick() {
    if (this.getIsFilterShow) {
      this.isFilterShow = !this.isFilterShow;
    }
  }
  public clearBoth() {
    this.page = 1;
    this.customerList = [];
    this.isScroll = true;
    this.count = 0;
    this.isSearchedListEnd = false;
  }
  public selectedTagsArray(param, status) {
    this.clearBoth();
    this.birthdayDef = 0;
    this.filterParam = { ...param, ...{ birthdayDef: 0 } };
    this.isFilterSelect = status;

    this.getCommunicateInfo();
  }
  public handleSortItem(item, index) {
    this.clearFilter();
    this.clearBoth();

    this.sortItem = this.sortItem.map((val, ind) => {
      if (index === ind) {
        val.active = true;
        if (val.sort !== "none") {
          val.sort = val.sort === "default" ? "desc" : val.sort === "asc" ? "desc" : "asc";
        }
        this.changeSortItemValue = val;
      } else {
        val.active = false;
        if (val.sort !== "none") {
          val.sort = "default";
        }
      }
      return val;
    });

    this.sortFlag = this.changeSortItemValue.sort !== "none";
    if (!this.sortFlag) {
      this.clearFilter();
    }

    this.changeSortItem = {
      orderKey: this.changeSortItemValue.code,
      orderType: this.changeSortItemValue.sort !== "none" ? this.changeSortItemValue.sort : undefined,
      birthdayDef: !this.sortFlag && this.curTabIndex === 4 ? 1 : 0,
    };

    if (this.sortFlag) {
      this.getCommunicateInfo(true);
    } else {
      this.getDivideInDayDate();
    }
  }

  public showDetail(item) {
    this.listItem = item;
    this.ExecutiveDetailsFlag = true;
  }

  // 监听滚动条事件
  public scrollFunc(e) {
    console.log(e);
    this.$nextTick(() => {
      const scrollDiv = this.$el.querySelector(".con-list");
      const clientHeight = scrollDiv.clientHeight;
      const scrollTop = scrollDiv.scrollTop;
      const scrollHeight = scrollDiv.scrollHeight;
      if (scrollTop + clientHeight + 100 > scrollHeight && this.isScroll && (this.curTabIndex !== 4 || this.sortFlag)) {
        console.log("到底部了！");
        this.isScroll = false;
        this.getCommunicate(true);
      }
    });
  }

  /**
   * 任务执行人列表
   */
  public getCommunicate(formScroll?: boolean) {
    this.listLoading = true;
    // 修复滚动条到底部后显示加载中
    if (formScroll) {
      setTimeout(() => {
        const scrollDiv = this.$el.querySelector(".con-list");
        const clientHeight = scrollDiv.clientHeight;
        const scrollHeight = scrollDiv.scrollHeight;
        console.log(scrollDiv.scrollTop, scrollHeight - clientHeight - 1);
        scrollDiv.scrollTop = scrollHeight - clientHeight - 1;
      });
    }

    const postParams = {
      ...{
        regionId: this.curTabIndex,
        searchDay: +this.taskDay,
        regionStatus: this.taskResult,
        execGuiderId: this.execGuiderId,
        customerFlag: this.searchShow ? "0" : this.curTabIndex,
        taskResult: this.taskResult,
        keywords: this.keywords,
        taskId: this.taskId, // 任务ID
        requestType: 2,
        page: this.page,
        pageSize: this.pageSize,
        birthdayDef: !this.sortFlag && this.curTabIndex === 4 ? 1 : 0,
      },
      ...this.changeSortItem,
      ...this.filterParam,
    };
    DZMainService.taskCustomerListV2(postParams)
      .then((res) => {
        if (res.code === 20000 && res.content) {
          if (res.content.list.length === 0 && this.page !== 1) {
            this.isSearchedListEnd = true;
            this.isScroll = false;
          } else {
            // 修复连续切换tab时，加载数据出错问题
            if (this.page === 1 || postParams.page === 1) {
              this.page = 1;
              this.$set(
                this,
                "customerList",
                res.content.list
                  .filter((i) => {
                    return i.cstId;
                  })
                  .map((i) => {
                    i.pntVal = formatMoney(i.pntVal);
                    return i;
                  }),
              );
            } else {
              this.customerList = this.customerList
                .concat(res.content.list)
                .filter((i) => {
                  return i.cstId;
                })
                .map((i) => {
                  i.pntVal = formatMoney(i.pntVal);
                  return i;
                });
            }
            this.isScroll = true;
            this.count = res.content.count;
            this.page++;
          }
        }
      })
      .finally(() => (this.listLoading = false));
  }

  // 防抖动函数
  public debounce(cb, waitTime, immediate?) {
    let timeout;
    return function() {
      const args = arguments;
      const later = () => {
        timeout = null;
        if (!immediate) {
          cb.apply(this, args);
        }
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, waitTime);
      if (callNow) {
        cb.apply(this, args);
      }
    };
  }
  /*清除数据*/
  public clearData() {
    this.isScroll = true;
    this.isSearchedListEnd = false;
    this.customerList = [];
    this.page = 1;
    this.count = 0;
    this.listItem = {};
  }
  /**
   * 任务执行人列表
   */
  public getCommunicateInfo(formScroll?: boolean) {
    this.listLoading = true;
    // 修复滚动条到底部后显示加载中
    if (formScroll) {
      this.$nextTick(() => {
        const scrollDiv = this.$el.querySelector(".con-list");
        const clientHeight = scrollDiv.clientHeight;
        const scrollHeight = scrollDiv.scrollHeight;
        scrollDiv.scrollTop = scrollHeight - clientHeight - 1;
      });
    }

    const postParams = {
      ...{
        regionId: this.curTabIndex,
        searchDay: +this.taskDay,
        regionStatus: this.taskResult,
        execGuiderId: this.execGuiderId,
        customerFlag: this.searchShow ? "0" : this.curTabIndex,
        taskResult: this.taskResult,
        keywords: this.keywords,
        taskId: this.taskId, // 任务ID
        requestType: 2,
        birthdayDef: !this.sortFlag && this.curTabIndex === 4 ? 1 : 0,
      },
      ...this.changeSortItem,
      ...this.filterParam,
    };
    return DZMainService.taskCustomerListV2(postParams)
      .then((res) => {
        if (res.code === 20000 && res.content) {
          this.customerList = this.customerList.concat(res.content.list).map((i) => {
            i.pntVal = formatMoney(i.pntVal);
            return i;
          });

          if (this.curTabIndex === 4) {
            // 处理是否展开
            this.customerList = this.customerList
              .map((val: any, index) => {
                val.isOpen = index <= 1;
                return val;
              })
              .map((i) => {
                i.pntVal = formatMoney(i.pntVal);
                return i;
              });
          }

          this.isScroll = true;
          this.count = res.content.count;
        }
      })
      .finally(() => (this.listLoading = false));
  }

  public getDivideInDayDate() {
    const postParams = {
      taskId: this.taskId,
      siteCode: this.siteCode,
      guiderId: this.guiderId,
      execGuiderId: this.execGuiderId,
      requestType: 2,
    };

    return DZMainService.taskBirthdayDateList(postParams).then((res) => {
      let activeIndex = 0;
      let scrollAddItemNumber = 0;
      this.DivideInDayList = res.content.list.map((i, index) => {
        // 没有生日，或者任务的时间小于今天
        if (i.totalNum === 0) {
          i.color = "#888";
          i.rightText = "-";
          // 时间小于今天，且任务未完成
        } else if (i.dayNum > +moment().format("YYYYMMDD")) {
          i.color = "#888";
          i.rightText = i.totalNum - i.finishedNum + "";
        } else if (i.finishedNum < i.totalNum) {
          i.color = "#ED513F";
          i.rightText = i.totalNum - i.finishedNum + "";
        } else if (i.totalNum === i.finishedNum) {
          i.color = "#21C38C";
          i.rightText = "完";
        }
        i.id = "item" + index;
        i.active = false;
        // 默认选中当日
        if (i.dayNum === +moment().format("YYYYMMDD")) {
          i.active = true;
          i.weekDay = "今天";
          i.color = "#211BC3";
          this.selectDivideInDay = i;
          activeIndex = index;

          // 计算补足滚动条的元素个数
          scrollAddItemNumber = 9 - (res.content.list.length - index);
        }
        return i;
      });

      if (scrollAddItemNumber > 0) {
        this.divideInDayPlace = Array.from({ length: scrollAddItemNumber }, (a, b) => b);
      }

      this.count = res.content.total;

      this.$nextTick(() => {
        this.handelDivideInDay(activeIndex);
        this.getDivideInDayCustomerList();
      });
    });
  }
  // 到日时间的点击
  public changeSelectDivideInDay(item, index) {
    this.selectDivideInDay = item;
    this.DivideInDayList.map((val, ind) => {
      val.active = ind === index;
      return val;
    });
    this.customerList = [];
    this.getDivideInDayCustomerList();
  }

  /**
   * 任务执行人列表
   */
  public getDivideInDayCustomerList() {
    const postParams = {
      ...{
        taskId: this.taskId, // 任务ID
        customerFlag: this.searchShow ? "0" : this.curTabIndex,
        searchDay: this.selectDivideInDay ? this.selectDivideInDay.dayNum : undefined,
        page: this.page,
        taskResult: this.taskResult,
        pageSize: this.pageSize,
        execGuiderId: this.execGuiderId,
        keywords: this.keywords,
        requestType: 2,
        birthdayDef: !this.sortFlag && this.curTabIndex === 4 ? 1 : 0,
      },
      ...this.changeSortItem,
      ...this.filterParam,
    };

    DZMainService.taskCustomerListV2(postParams).then((res) => {
      this.customerList = res.content.list.map((i) => {
        i.pntVal = formatMoney(i.pntVal);
        return i;
      });
    });
  }

  public handelDivideInDay(index) {
    setTimeout(() => {
      const el = document.getElementById("left-date-list");
      const active = document.getElementById("item" + index);
      el.scrollTop = active.offsetTop - el.offsetTop - active.offsetHeight;
    }, 2000);
  }
  /**
   * 任务执行详情
   */
  public taskDayInfo() {
    DZMainService.getTaskInfo({
      taskId: this.taskId,
      searchDay: this.taskDay,
    }).then((resp) => {
      this.taskDetail = resp.content;
      console.log(this.taskDetail);

      this.taskType = this.taskDetail.chooseGuiderType;
      if (this.taskDetail.chooseGuiderType === "AllGuider") {
        this.getExecGuiderList();
      } else {
        this.$nextTick(() => {
          eventBus.emit("taskLoadSuccess", true);
        });
      }

      // 如果是客资分配到日的
      if (this.taskDetail.divideInDay) {
        this.getDivideInDayDate();
      }

      this.taskTypeCode = this.taskDetail.taskTypeCode;
      // 判断是否显示任务专有的字段
      this.taskTypeToItem = this.TASK_TYPE_TO_KEY[this.taskTypeCode];
      // //获取指定时间任务列表
      DZMainService.taskDayInfo({
        taskId: this.taskId,
        searchDay: +this.taskDay,
      })
        .then((res: any) => {
          this.popMask6Flag = res.content.isFirstVisit === 1; // 导购首次进入该任务时默认展示任务概述，非首次进入不展示
        })
        .catch((e) => {
          // console.log("taskDayInfo=>", e);
        });
    });
  }
  public goCostomerCenter(cstId) {
    InitJSSDK.Init("redirectTo", () => {
      (window as any).wx.miniProgram.navigateTo({
        url: AppUrls.customerCenterV2 + `?cstId=${cstId}&external=ext`,
      });
    });
  }
  // 获取沟通配置
  public communicateConfig() {
    return DZMainService.getCommunicateConfig({
      taskId: this.taskId,
    }).then((res) => {
      this.communInfo = { ...res.content };
    });
  }

  public getCommunicatesStatusList(val, item) {
    this.taskResult = item.communicatesResult;
    this.maskPopText = item.communicatesResultName;
    this.maskPopFlag = false;
    this.customerList = [];
    this.getCommunicateInfo();
  }

  // 任务导购类型是所有导购均可执行时，获取任务参与导购列表
  public getExecGuiderList() {
    DZMainService.taskReportExecGuiderList({
      siteCode: this.siteCode,
      guiderId: this.guiderId,
      taskId: this.taskId,
    }).then((res) => {
      this.execGuiderList = res.content.list || [];
      this.$nextTick(() => {
        eventBus.emit("taskLoadSuccess", true);
      });
    });
  }

  public closePopMask() {
    this.popMaskFlag = false;
    this.taskRecordId = "";
    this.customerId = "";
  }

  public closePopMask6() {
    this.popMask6Flag = false;
  }
  public changePopMask() {
    this.popMask6Flag = !this.popMask6Flag;
  }
  public changeOpenStatus(ind) {
    this.customerList = this.customerList.map((item: any, index) => {
      if (ind === index) {
        item.isOpen = !item.isOpen;
      }
      return item;
    });
  }
  /**
   * 沟通详情列表切换
   * @param {number} index
   */
  public onCommunicateInfoListSwitch(index: number): void {
    this.curTabIndex = index;
    this.clearFilter();
    this.clearSort();
    this.clearBoth();

    // 生日和其他任务不同
    this.changeSortItemValue = this.curTabIndex === 4 ? this.sortItem[0] : this.sortItem[1];

    if (this.taskDetail.divideInDay && index === 4) {
      this.getDivideInDayDate().then(() => {
        this.getDivideInDayCustomerList();
      });
    } else {
      this.getCommunicateInfo();
    }
  }
  /**
   * 主菜单隐藏
   * @return null
   */
  public onHideMainMenu(): void {
    setTimeout(() => {
      this.isMainMenuWrapperShow = false;
    }, 0);
  }
}
