/*
 * @Author: jiangxd
 * @Date: 2021-03-07 18:34:26
 * @LastEditTime: 2021-05-10 18:50:07
 * @LastEditors: jiangxd
 * @Description: 
 * @FilePath: /qingger-dz-webview/src/util/format.ts
 */

/**
 *  格式化数字，添加逗号
 * @param num
 * @returns {string|*}
 */
export function formatMoney(num) {
    if (+num) {
        // 兼容负数
        const nums =  parseInt(num,10) + "";
        const numString = num < 0 ? Math.abs(parseInt(num,10)) + "" : nums;
        return numString.length < 4 ? nums : formatterParam(nums, 3, ",");

    } else {
        return num;
    }
}

/**
 *
 * @param str  格式化的字符串
 * @param rema 要取余的参数
 * @param spli 分割添加的字符串
 */
function formatterParam(str, rema, spli) {
    return str
        .split("")
        .reverse()
        .map((val, index) => (index % rema === 0 && index !== 0 ? val + spli : val))
        .reverse()
        .join("");
}

/**
 *  手机号添加空格
 * @param phone
 * @returns {*}
 */
export function formatPhone(phone) {
    // 默认是正确的手机号，只针对长度验证
    if (phone && (phone + "").length === 11) {
        return formatterParam(phone + "", "4", " ");
    } else {
        return phone;
    }
}
// 手机号脱敏
export function mobileNumberChange(str): string {
    if (!+window.localStorage.getItem("isHandlePhone")) {
		return str;
	}
	if (!str || !str.length || str.length !== 11) {
		return str;
	}
	return str.replace(/(\d{3})\d*(\d{4})/, "$1****$2");
}
