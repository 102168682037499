import * as $ from "jquery";
import config from "./config";

const env = process.env.NODE_ENV;
function updateToken(oldToken: string) {
  let urls = config.tooken.url;
  if (env !== "development") {
    urls = "dzapi" + urls;
  }

  return new Promise((resolve, reject) => {
    $.ajax({
      type: config.tooken.type,
      url: urls,
      data: {
        token: oldToken,
      },
      success: (resp: any) => {
        resolve(resp);
      },
      error: (apiError: any) => {
        reject(apiError);
      },
    });
  });
}
const caches: any = {};
export default {
  service: (url: string, type: string, params = {}, cancel: boolean = false): Promise<any> => {
    let urls = url;
    if (env !== "development") {
      urls = "dzapi" + urls;
    }

    if (urls in caches && cancel) {
      caches[urls].abort();
    }

    return new Promise<any>((resolve, reject) => {
      let data: any = null;
      const token = window.localStorage.getItem("token");
      const siteCode = window.localStorage.getItem("siteCode");
      const guiderId = +window.localStorage.getItem("isGuider")
        ? window.localStorage.getItem("guiderId")
        : (window as any).guiderId
        ? (window as any).guiderId.replace(/(^\s*)|(\s*)$/g, "")
        : "";
      const empCode = +window.localStorage.getItem("isGuider")
        ? window.localStorage.getItem("empCode")
        : (window as any).empCode
        ? (window as any).empCode.replace(/(^\s*)|(\s*)$/g, "")
        : "";
      data = JSON.stringify({ ...{ siteCode, guiderId, empCode }, ...params });
      caches[urls] = $.ajax({
        type,
        url: urls,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
        contentType: "application/json; charset=utf-8",
        success: (resp: any) => {
          resolve(resp);
        },
        error: (apiError: any) => {
          if (apiError.statusText === "abort") {
            reject("abort");
          } else if (apiError.responseJSON.code === 12002) {
            // token过期
            updateToken(token).then((result: any) => {
              const newToken = result.content.token;
              window.localStorage.setItem("token", newToken);
              // 重新发起请求
              $.ajax({
                type,
                url: urls,
                headers: {
                  Authorization: `Bearer ${newToken}`,
                },
                data,
                contentType: "application/json; charset=utf-8",
                success: (res: any) => {
                  resolve(res);
                },
                error: (apiErrors: any) => {
                  alert(apiErrors.responseText);
                  reject(apiErrors);
                },
              });
            });
          } else {
            alert(apiError.responseText);
            reject(apiError);
          }
        },
      });
    });
  },
};
