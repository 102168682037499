import { Component, Prop, Vue } from "vue-property-decorator";
import _Template from "./style.vue";
import * as $ from "jquery";
import service from "@/service/index";
import DzMenu from "../dzMenu";
import eventBus from "@/util/eventBus";

interface ISelectItem {
  frame ?:string;
  explain ?:string;
}

@Component({
  mixins: [_Template],
})
export default class SelectTime extends Vue {
  @Prop({ default: {} })
  selectTimeData;

  public isMainMenuWrapperShow: boolean = false;

  public defTimeList: ISelectItem[] = [
    {
     frame : 'today',
     explain : '今日',
    },
    {
      frame : 'thisMonth',
      explain : '本月',
    }
  ];
  public selectItem: ISelectItem = {};

  // 菜单 点击事件
  public onMenuItemClick(e, item) {
    this.isMainMenuWrapperShow = false;
    this.selectTime(item);
  }
  public showMenu() {
    this.isMainMenuWrapperShow = true;
  }

  public selectTime(item) {
    const { frame,explain } = item;
    this.selectItem = item;
    $("#timeSelect").text(explain);
    this.selectTimeData(frame);
    $(".guider-list").hide();
    $(".popupMask").hide();
  }

  mounted() {
    eventBus.on("taskCenterOnSelectTime", item => {
      this.selectTime(item);
    });
  }

  render(h) {
    return (
      <div class="data-select-wrap">
        <div class="data-select">
          <span
            onClick={() => {
              this.showMenu();
            }}
            class="date-text"
            id="timeSelect"
          >
            {this.defTimeList[0].explain}
          </span>

          <i class="iconfont icon-moreunfold" style="position: static;color:#2c3e50;" />

          <DzMenu
            label="explain"
            v-model={this.isMainMenuWrapperShow}
            menuItems={this.defTimeList}
            onMenuItemClick={(e, item) => this.onMenuItemClick(e, item)}
          />
        </div>
      </div>
    );
  }
}
