import { Component, Prop, Vue, Watch, Model, Provide } from "vue-property-decorator";

@Component({
  name: "DzDefaultDialog",
})
export default class DzDefaultDialog extends Vue {
  dialogShow = false;

  loading = false;
  disable = false;
  hideConfirm = false;


  @Provide("dialogContext")
  get injectContext() {
    return this;
  }

  @Prop()
  value;

  @Prop({ default: true })
  persistent: boolean;

  @Prop()
  contentComponent: any;

  @Prop()
  contentComponentProps: object;

  @Prop()
  contentComponentConf: object;

  @Prop()
  contentSlot;

  // 表头右侧按钮 slot
  @Prop()
  titleRightContent;

  @Prop()
  title: string;

  @Prop()
  toolbar: any; // 底部按钮  toolbar 定义

  @Prop()
  cardClass: any;

  @Prop()
  cardStyle: any;

  @Prop({ default: "bg-primary text-white" })
  barClass: string;

  @Prop()
  dark: boolean;

  @Prop({ default: () => ({}) })
  dlg: any;

  @Prop({ default: true })
  showFooter: boolean;

  @Prop({ default: true })
  showHeader: boolean;

  // 是否显示关闭按钮
  @Prop({ default: true })
  cancel: boolean;

  @Prop({ default: false })
  square: boolean;

  @Prop({ default: false })
  sidenav: boolean;

  // 响应式设置宽度
  @Prop()
  widthBreakPoint;

  @Prop()
  footerAlign: "right" | "center" | "left";

  @Prop({ type: Function })
  slotFooter: Function;

  breakPointWidth = null;

  beforeDestroy() {
    //
  }

  created() {
    //
  }

  // 必须实现 show hide 接口
  show() {
    //
    this.dialogShow=true;
  }

  hide() {
    //
    this.dialogShow=false;
  }

  close() {
    this.hide();
  }

  onResize() {
    // 修正异步造成窗口高度变化处理
    // this.updateDialogHeight();
  }

  defaultBtnOkClick() {
    this.$emit("ok");
    this.hide();
  }

  defaultBtnCancelClick() {
    this.hide();
  }

  mounted() {
    //
  }

  render(h) {
    if(!this.dialogShow){
      return null
    }
    const Cmpt = this.contentComponent;
    const { props, ...others } = (this.contentComponentConf || {}) as any;

    const CmptConf = {
      props: {
        ...(this.contentComponentProps || {}),
        ...props,
        dialog: this,
        closeDialog: () => {
          this.hide();
        },
      },
      ...others,
      ref: "contentRef",
    };
    return (
      <div class="dialog-cover" style='display:block'>
        <div
          class="popupMask"
          on-click={(e) => {
            this.close();
          }}></div>
        <div class="notice-dialog" style='z-index:3'>
          <div class="title" style='display:block'>
            {this.title}
            <span id="closeDialog">
              <i class="close-icon" on-click={this.close}></i>
            </span>
          </div>
          <div class="content" >
            <Cmpt {...CmptConf}></Cmpt>
          </div>
          <div class="img"></div>
        </div>
      </div>
    );
  }
}
