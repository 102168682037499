import { Component, Prop, Vue, Watch, Model } from 'vue-property-decorator';
import _Template from './style.vue';
import * as $ from 'jquery';
import service from '@/service/index';
import DzMenu from '../dzMenu';
import eventBus from '@/util/eventBus';

@Component({
  mixins: [_Template]
})
export default class DzInfoDialog extends Vue {
  @Model('input')
  value;

  @Prop({ default: '名词解析' })
  title: string;

  @Prop({ default: '' })
  innerHTML: string;

  public isInfoDialogShow: boolean = false;

  @Watch('value', { immediate: true })
  watchCount(newVal, oldVal) {
    if (newVal === oldVal) {
      return;
    }
    this.isInfoDialogShow = !!newVal;
  }

  mounted() {
    //
  }
  closeDialog(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.isInfoDialogShow = false;
    this.$emit('input', false);
  }

  render(h) {
    return (
      <div class='dz-info-dialog'>
        {this.isInfoDialogShow && [
          <div
            class='mask-wrap'
            onClick={$event => this.closeDialog($event)}
          ></div>,
          <div class='extend-info'>
            <div class='title'>
              <span class="title-name">{this.title}</span>
              <span class='closeDialog'>
                <i
                  class='close-icon'
                  onClick={$event => this.closeDialog($event)}
                ></i>
              </span>
            </div>
            <div class='dialog-content'>
              <div
                class='extend-info-cont'
                domPropsInnerHTML={(this.innerHTML || '').replace(
                  /\r?\n|\r/g,
                  '<br>'
                )}
              ></div>
            </div>
          </div>
        ]}
      </div>
    );
  }
}
