import { Component, Vue } from "vue-property-decorator";
import _Template from "./style.vue";
import * as F2 from "@antv/f2";
import GuiderList from "@/components/guiderList";
import eventBus from "@/util/eventBus";
import api from "@/service";
import PieLabel from "@antv/f2/lib/plugin/pie-label";
import numeral from "numeral";
import DzMenu from "@/components/dzMenu";
import moment from "moment";
import { QinggerLibURL } from "qingger-lib";

import TaskAfterSale from "@/components/taskCenterDetail/taskAfterSale/index.vue";
import DzTabs from "@/components/dzTabs";
import HelpTips from "@/components/helpTips";
import { AppUrls } from "@/util/consts";
import InitJSSDK from "@/util/initJSSDK";
import { taskStatusStr } from "@/views/taskCenter/taskCenter";

interface IReportimplementation {
  willInNum?: number;
  totalNum?: number;
  ignoredNum?: number;
  noResponseNum?: number;
  surplusNum?: number;
  purchaseNum?: number;
  totalFinishNum?: number;
  conversionRate?: number;
  purchaseAmount?: number;
  followNum?: number;
  unFollowNum?: number;
}

interface IPieChartData {
  data: any[];
  el: string;
  name: string;
  total: number;
}

@Component({
  mixins: [_Template],
  components: { HelpTips }
})
export default class AfterSale extends Vue {
  //
  protected reportimpleme: any = {};

  // 务参与导购执行转化结果
  protected conversionList: any[] = [];

  protected extendInfo: string = "";

  protected extendInfoShow: boolean = false;

  protected taskInfo: any = {};
  protected taskInfoShow: boolean = false;

  protected selectedGuiderId: string = ""; // 选择的某个雇员
  /**
   *  当前用户身份  // 102:导购，101：店长
   *
   */
  protected guiderPost: string = "102";
  protected guiderId: string = "";
  protected siteCode: string = "";
  protected empCode: string = "";
  protected observe:string = '0';

  protected taskStatus: number = 1;
  protected taskType: number = 1;

  protected numLen: number | string = 2;

  protected taskId: string | string[] = "";

  // 菜单是否显示
  protected isMainMenuWrapperShow: boolean = false;

  // 导购列表
  protected guiderList: any[] = [];

  protected curTabIndex: number = 1;

  protected extendInFlag: boolean = false;

  protected menuItems: { label: string; value: number }[] = [
    {
      label: "任务详情",
      value: 2
    }
  ];

  protected tabs: { label: string; value: number }[] = [
    {
      label: "概览",
      value: 1
    },
    {
      label: "明细",
      value: 2
    }
  ];
  // tab 点击事件
  protected onTabClick(e) {
    this.curTabIndex = +e;
  }
  protected showExtendInfo() {
    setTimeout(() => {
      this.extendInfoShow = !this.extendInfoShow;
    });
  }

  protected showTaskInfo() {
    this.taskInfoShow = !this.taskInfoShow;
  }
  get taskStatusText():any {
    return taskStatusStr.find(i => i.code === this.taskStatus) || {};
  }

  get headerDesc() {
    if (this.taskInfo) {
      // 任务来源  1后台 2前台
      if (this.taskInfo.taskSource === 1) {
        return { text: "公司任务", color: "#21C38C" };
      } else if (this.taskInfo.taskSource === 2) {
        return this.guiderId === this.taskInfo.createPerson
          ? { text: "本店发布", color: "#211BC3" } // ? "本店发布（本人发布）"
          : { text: "本店发布", color: "#211BC3" };
      }
      return "";
    }
    return "";
  }

  // 获取展示数据的权限
  get getDataAuth(): boolean {
    // 只有当所有导购均可参与（AllGuider）的情况下，才会显示2列
    if (this.taskInfo.chooseGuiderType === "AllGuider") {
      // 店长可以看到全部，当选择导购时，看到新的样式
      return !!(this.guiderPost !== "101" || this.selectedGuiderId);
    } else {
      return false;
    }
  }

  // 获取任务导购类型是不是 所有导购均可参与（AllGuider）
  get isAllGuider(): boolean {
    return this.taskInfo.chooseGuiderType === "AllGuider";
  }

  // 菜单 点击事件
  protected onMenuItemClick(e) {
    this.isMainMenuWrapperShow = false;
    if (+e === 1) {
      this.showTaskInfo();
    } else if (+e === 2) {
      if (this.guiderPost === "102" ||+this.observe ===1 ) {
        this.showTaskInfo();
        return;
      }

      this.linkToDetail();
    }
  }
  /**
   * 跳转任务详情
   */
  protected linkToDetail() {
    const url = AppUrls.addTask + "?taskId=" + this.taskInfo.taskId;

    InitJSSDK.Init("navigateTo", () => {
      (window as any).wx.miniProgram.navigateTo({ url });
    });
  }
  protected showMenu() {
    this.isMainMenuWrapperShow = true;
  }

  protected getData(guiderId?, empCode?) {
    this.selectedGuiderId = guiderId;

    const baseParam = {
      siteCode: this.siteCode,
      guiderId: this.guiderId,
      taskId: this.taskId,
      selectGuider: this.guiderPost === "101" ? guiderId : this.guiderId
    };
    this.getGuiderListData();
    this.getTaskInfo(baseParam);
    this.gettaskReportimplementation(baseParam).then(() => {
      this.getLineChartData(baseParam);
      this.getPieChartData(baseParam);
    });
    this.getreportCommunicateConversionList(baseParam);
    this.getextendInfo();
    api.dzGroupCommonConfig({}).then((res) => {
      console.log(res);
      window.localStorage.setItem("isHandlePhone", res.content.handlePhone);
    });

    eventBus.emit("selectedGuiderId", guiderId);
  }

  // 获取任务详情
  protected getTaskInfo(param) {
    api.DZV2TaskInfo(param).then(res => {
      this.taskInfo = res.content;
      this.taskStatus = res.content.taskState;
    });
  }

  // 额外配置
  protected getextendInfo() {
    api.extendInfo().then(res => {
      const extendIn = res.content.functional_module_desc.After_Sale_Task;

      if (extendIn && extendIn !== "") {
        this.extendInFlag = true;
        this.extendInfo = extendIn.replace(/<br>/g, "</br>");
      } else {
        this.extendInFlag = false;
      }
    });
  }

  protected gettaskReportimplementation(param) {
    return api.taskReportAfterSaleImplementation(param).then((res: any) => {
      this.reportimpleme = res.content;
    });
  }

  protected getLineChartData(param) {
    api.nearlyDaysCommunicate(param).then(res => {
      this.initLineChart(res.content.list);
    });
  }

  protected getreportCommunicateConversionList(param) {
    api.reportCommunicateConversionList(param).then((resp: any) => {
      this.conversionList = resp.content.list;
    });
  }

  protected getGuiderListData() {
    api
      .siteReportGuiderList({ taskId: this.taskId })
      .then((data: any) => {
        this.guiderList = data.content.list;
      })
      .catch(error => {
        console.log(error);
      });
  }

  protected getPieChartData(param) {
    if (document.querySelectorAll(".guideWapper")) {
      document.querySelectorAll(".guideWapper").forEach(i => {
        (i as any).style = "display:none";
      });
    }

    api.reportCommunicateResult(param).then((res: any) => {
      //  沟通方式的

      if (+this.reportimpleme.cumulativeVisitNum > 0) {
        const data1 = this.tranData(res.content.communicateTypes);
        const chart1: IPieChartData = {
          total: this.reportimpleme.cumulativeVisitNum,
          data: data1,
          name: "累计沟通",
          el: "CommunicationStyle"
        };
        this.renderPieChart(chart1);
      }
    });
  }

  protected tranData(datalist) {
    const finishNum = this.reportimpleme.cumulativeVisitNum;
    const list = datalist.map(i => {
      return {
        name: i.name,
        percent: (+i.number / finishNum) * 100,
        val: i.number
      };
    });

    return list;
  }

  protected renderPieChart(chartData: IPieChartData) {
    const map = {};
    chartData.data.map(obj => {
      map[obj.name] = `${
        obj.percent ? numeral(obj.percent).format("0.00") : 0
      }%`;
    });

    const chart = new F2.Chart({
      id: chartData.el,
      pixelRatio: window.devicePixelRatio,
      plugins: PieLabel
    });
    chart.source(chartData.data, {
      percent: {
        formatter: function formatter(val) {
          return `${val || 0}%`;
        }
      }
    });
    chart.tooltip(false);
    chart.legend({
      position: "bottom",
      align: "center",
      itemFormatter: function itemFormatter(val) {
        return `${val || 0}  ${map[val]}`;
      }
    });
    // 假设数据结构为: { name: 'a', value: 100 }
    chart.pieLabel({
      sidePadding: 35,
      label1(data) {
        return {
          text: data.name + "  [" + data.val + "]", // 文本内容
          fill: "#808080"
        };
      }
    });
    chart.coord("polar", {
      transposed: true,
      innerRadius: 0.7,
      radius: 0.8
    });
    chart.axis(false);
    chart
      .interval()
      .position("1*percent")
      .color("name", [
        "#1890FF",
        "#13C2C2",
        "#2FC25B",
        "#FACC14",
        "#F04864",
        "#8543E0",
        "#3436C7",
        "#223273"
      ])
      .adjust("stack");

    chart.guide().html({
      position: ["50%", "45%"],
      html:
        // tslint:disable-next-line: restrict-plus-operands
        '<div style="width: 250px;height: 40px;text-align: center;">' +
        '<div style="font-size: 0.28rem;margin-top:0.2rem">' +
        chartData.total +
        "</div>" +
        '<div style="font-size: 0.25rem">' +
        chartData.name +
        "</div>" +
        "</div>"
    });
    chart.render();
  }

  protected initLineChart(data) {
    const resList = data.map(i => {
      return {
        date: i.execDay,
        value: i.currentDayFinishNum
      };
    });
    const chart = new F2.Chart({
      id: "lineChart",
      pixelRatio: window.devicePixelRatio
    });

    chart.source(resList, {
      value: {
        tickCount: 5,
        min: 0
      },
      date: {
        range: [0, 1]
      }
    });
    chart.tooltip({
      custom: true,
      showXTip: true,
      showYTip: true,
      snap: true,
      showCrosshairs: true,
      showItemMarker: false,
      crosshairsStyle: {
        lineDash: [2]
      }
    });
    chart.axis("date", {
      label: function label(text, index, total) {
        const textCfg: any = {};
        if (index === 0) {
          textCfg.textAlign = "left";
        } else if (index === total - 1) {
          textCfg.textAlign = "right";
        }
        return textCfg;
      }
    });
    chart.line().position("date*value");
    chart.render();
  }

  protected pageEvent() {
    eventBus.on("selectGuiderChange", (guiderId, empCode) => {
      console.log(guiderId, empCode);
    });
  }

  protected toGuiderInfo(item) {
    eventBus.emit("taskCenterOnSelectGuider", item);
  }

  protected mounted() {
    const url = window.location.href;
    const urlLibUtil = QinggerLibURL.urlUtil(url);
    const tokenFromQuery = urlLibUtil.getUrlQueryItem("token");
    const siteFromQuery = urlLibUtil.getUrlQueryItem("siteCode");
    const guiderFromQuery = urlLibUtil.getUrlQueryItem("guiderId");
    const empFromQuery = urlLibUtil.getUrlQueryItem("empCode");
    const siteRoleQuery = urlLibUtil.getUrlQueryItem("siteRole");
    const taskIdQuery = urlLibUtil.getUrlQueryItem("taskId");
    const observeQuery = urlLibUtil.getUrlQueryItem("observe");

    if (tokenFromQuery) {
      window.localStorage.setItem("token", tokenFromQuery || "token-1");
    }
    if (siteFromQuery) {
      window.localStorage.setItem("siteCode", siteFromQuery || "");
    }
    if (guiderFromQuery) {
      window.localStorage.setItem("guiderId", guiderFromQuery || "");
    }
    if (empFromQuery) {
      window.localStorage.setItem("empCode", empFromQuery || "");
    }
    if (siteRoleQuery) {
      window.localStorage.setItem("siteRole", siteRoleQuery || "");
    }
    if (observeQuery) {
      window.localStorage.setItem("observe", observeQuery || "");
    }
    // 当token失效时，可能已经有多个请求已同时发出。通过此属性，可以判断此token是否短期内被更新过，防止重复更新token
    window.localStorage.setItem("tokenTime", `${new Date().valueOf()}`);

    // 设置是否是导购
    window.window.localStorage.setItem("isGuider", "1");
    this.guiderPost = window.localStorage.getItem("siteRole");
    this.siteCode = window.localStorage.getItem("siteCode");
    this.guiderId = window.localStorage.getItem("guiderId");
    this.empCode = window.localStorage.getItem("empCode");
    this.taskType = this.guiderPost === "101" ? 1 : 2;
    this.taskId = taskIdQuery || this.$route.query.taskId;
    this.taskStatus = +this.$route.query.status;
    this.observe = window.localStorage.getItem("observe")
    this.pageEvent();

    setTimeout(() => {
      this.getData();
    }, 100);
  }

  protected render(h) {
    return (
      <div class="task-center">
        {/* 头部信息 */}
        <div class="header">
          <img src={this.taskInfo.taskTypeImage} alt="" class="logo" />
          <div class="header-info">
            <p class="title-info">
              <span class="title">{this.taskInfo.taskName}</span>
            </p>
            <div class="date">
              {moment(this.taskInfo.beginInDay).format("MM/DD") +
                " ~ " +
                moment(this.taskInfo.endInDay).format("MM/DD")}
              {this.taskStatusText && (
                <span
                  class="sta"
                  style={{ background: this.taskStatusText.color }}
                >
                  {this.taskStatusText.text}
                </span>
              )}
            </div>
          </div>
          <div class="oper">
            <i
              class="chart-icon iconfont icon-gengduo11"
              onClick={() => {
                this.showMenu();
              }}
            />
          </div>
        </div>

        {/* 店员 */}
        <p class="select-other">
          <span class="tab-wrap">
            <DzTabs
              tabs={this.tabs}
              curTabIndex={this.curTabIndex}
              onTabClick={e => this.onTabClick(e)}
            />
          </span>

          {this.guiderPost === "101" && (
            <GuiderList
              guiderListGetData={this.getData}
              taskId={this.taskId}
              guiderList={this.guiderList}
            />
          )}
        </p>

        <div v-show={this.curTabIndex === 1} style="flex:1">
          <div class={this.guiderPost === "101" ? "scroll" : "guider-scroll"}>
            {/* 数据信息 */}

            {this.getDataAuth ? (
              <div class="data-info">
                <ul class="list">
                  <li>
                    <span class="list-li-data">
                      <span class="num">{this.reportimpleme.visitedNum}</span>人
                    </span>
                    <span class="list-li-title">已完成回访</span>
                  </li>
                  <li>
                    <span class="list-li-data">
                      <span class="num">
                        {this.reportimpleme.onTimeVisitedNum}
                      </span>
                      人
                    </span>
                    <span class="list-li-title">按时完成回访</span>
                  </li>
                  <li>
                    <span class="list-li-data">
                      <span class="num">{this.reportimpleme.onTimeRate}</span>人
                    </span>
                    <span class="list-li-title">按时完成率</span>
                  </li>
                </ul>
                <ul class="list" style="border:none">
                  <li>
                    <span class="list-li-data">
                      <span class="num">{this.reportimpleme.onVisitNum}</span>人
                    </span>
                    <span class="list-li-title">回访中顾客</span>
                  </li>
                  <li>
                    <span class="list-li-data">
                      <span class="num">{this.reportimpleme.noVisitedNum}</span>
                      人
                    </span>
                    <span class="list-li-title">不再回访顾客</span>
                  </li>
                  <li>
                    <span class="list-li-data">
                      <span class="num">
                        {this.reportimpleme.cumulativeVisitNum}
                      </span>
                      人次
                    </span>
                    <span class="list-li-title">累计回访人次</span>
                  </li>
                </ul>
                <div class="after-sale">
                  {(this.reportimpleme.list || []).map(i => {
                    return (
                      <div class="sale-item">
                        <span class="title">{i.title}</span>
                        <span>
                          <span class="num">{i.totalNum}</span>人
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div class="data-info">
                <ul class="list">
                  <li>
                    <span class="list-li-data">
                      <span class="num">{this.reportimpleme.totalNum}</span>人
                    </span>
                    <span class="list-li-title">应回访顾客</span>
                  </li>
                  <li>
                    <span class="list-li-data">
                      <span class="num">{this.reportimpleme.visitedNum}</span>人
                    </span>
                    <span class="list-li-title">已完成回访</span>
                  </li>
                  <li>
                    <span class="list-li-data">
                      <span class="num">
                        {this.reportimpleme.onTimeVisitedNum}
                      </span>
                      人
                    </span>
                    <span class="list-li-title">按时完成回访</span>
                  </li>
                </ul>
                <ul class="list">
                  <li>
                    <span class="list-li-data">
                      <span class="num">{this.reportimpleme.onTimeRate}</span>%
                    </span>
                    <span class="list-li-title">按时完成率</span>
                  </li>
                  <li>
                    <span class="list-li-data">
                      <span class="num">{this.reportimpleme.onVisitNum}</span>人
                    </span>
                    <span class="list-li-title">回访中顾客</span>
                  </li>
                  <li>
                    <span class="list-li-data">
                      <span class="num">{this.reportimpleme.surplusNum}</span>人
                    </span>
                    <span class="list-li-title">未回访顾客</span>
                  </li>
                </ul>
                <ul class="list" style="border:none">
                  <li>
                    <span class="list-li-data">
                      <span class="num">{this.reportimpleme.noVisitedNum}</span>
                      人
                    </span>
                    <span class="list-li-title">不再回访顾客</span>
                  </li>
                  <li>
                    <span class="list-li-data">
                      <span class="num">
                        {this.reportimpleme.cumulativeVisitNum}
                      </span>
                      人次
                    </span>
                    <span class="list-li-title">累计回访人次</span>
                  </li>
                </ul>
                <div class="after-sale">
                  {(this.reportimpleme.list || []).map(i => {
                    return (
                      <div class="sale-item">
                        <span class="title">{i.title}</span>
                        <span>
                          <span class="num">{i.totalNum}</span>人
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {/* 具体店员的数据 */}
            {this.guiderPost === "101" && this.conversionList.length > 0 && (
              <div v-show={!this.selectedGuiderId} class="staff-list">
                <div class="title">
                  {this.isAllGuider ? (
                    <ul>
                      <li class="big-blank" />
                      <li class="tran">已完成</li>
                      <li class="cons">回访中</li>
                    </ul>
                  ) : (
                    <ul>
                      <li class="blank" />
                      <li class="talk">应回访</li>
                      <li class="tran">已完成</li>
                      <li class="cons">回访中</li>
                      <li class="cons">未回访</li>
                    </ul>
                  )}
                </div>
                <div class="cont-list">
                  {this.conversionList.map((item, index) => {
                    if (this.numLen !== "all" && index >= +this.numLen) {
                      return;
                    }

                    return (
                      <ul
                        onClick={() => {
                          this.toGuiderInfo(item);
                        }}
                      >
                        {this.isAllGuider
                          ? [
                              <li class="big-blank">
                                <img src={item.avatar} alt="" class="head" />
                                <span class="big-name">{item.empName}</span>
                              </li>,
                              <li class="tran">
                                <span class="num">{item.visitedNum}</span>人
                              </li>,
                              <li class="tran">
                                <span class="num">{item.onVisitNum}</span>人
                              </li>
                            ]
                          : [
                              <li class="blank">
                                <img src={item.avatar} alt="" class="head" />
                                <span class="name">{item.empName}</span>
                              </li>,
                              <li class="talk">
                                <span class="num">{item.totalNum}</span>人
                              </li>,
                              <li class="talk">
                                <span class="num">{item.visitedNum}</span>人
                              </li>,
                              <li class="tran">
                                <span class="num">{item.onVisitNum}</span>人
                              </li>,
                              <li class="tran">
                                <span class="num">{item.surplusNum}</span>人
                              </li>
                            ]}
                      </ul>
                    );
                  })}
                  {this.conversionList.length > 2 ? (
                    <p class="more">
                      <span
                        onClick={() => {
                          this.numLen = this.numLen === "all" ? 2 : "all";
                        }}
                        class="date-text"
                        id="guiderSelect"
                      >
                        {this.numLen === "all" ? "收起" : "展开"}
                      </span>
                      {this.numLen === "all" ? (
                        <i class="iconfont icon-less" />
                      ) : (
                        <i class="iconfont icon-moreunfold" />
                      )}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
            {/* 图表 */}

            {+this.taskStatus !== 3 && (
              <div class="customers-num">
                <p class="title">任务回访顾客人次</p>

                <div class="chartp-wrap">
                  <canvas id="lineChart"></canvas>
                </div>
                <div class="no-data" style="display: none;min-height: 100%">
                  <span class="iconfont icon-kongshuju"></span>
                  <div class="text"> 暂无数据</div>
                </div>
              </div>
            )}

            {/* 图表 */}
            <div class="customers-num">
              <p class="title">沟通方式</p>
              {+this.reportimpleme.cumulativeVisitNum > 0 ? (
                <div class="chartp-wrap">
                  <canvas id="CommunicationStyle"></canvas>
                </div>
              ) : (
                <div class="no-data" style="min-height: 100%">
                  <span class="iconfont icon-kongshuju"></span>
                  <div class="text"> 暂无数据</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <TaskAfterSale
          v-show={this.curTabIndex === 2}
          taskId={this.taskId}
          isAllGuider={this.isAllGuider}
          selectedGuiderId={this.selectedGuiderId}
        />

        <DzMenu
          v-model={this.isMainMenuWrapperShow}
          menuItems={this.menuItems}
          onMenuItemClick={e => this.onMenuItemClick(e)}
        />

        {+this.curTabIndex === 1 ? (
          <HelpTips
            onShow={() => {
              this.showExtendInfo();
            }}
          />
        ) : (
          ""
        )}
        {this.extendInfoShow && (
          <div id="mask" onClick={() => this.showExtendInfo()}></div>
        )}
        {this.extendInfoShow && (
          <div class="extend-info">
            <div class="title">
              名词解析
              <span class="closeDialog">
                <i class="close-icon" onClick={() => this.showExtendInfo()}></i>
              </span>
            </div>
            <div
              class="extend-info-cont"
              domPropsInnerHTML={this.extendInfo}
            ></div>
          </div>
        )}
        {this.taskInfoShow && (
          <div id="mask" onClick={() => this.showTaskInfo()}></div>
        )}
        {this.taskInfoShow && (
          <div class="extend-info">
            <div class="title">
              任务概述
              <span class="closeDialog">
                <i class="close-icon" onClick={() => this.showTaskInfo()}></i>
              </span>
            </div>
            <div class="dialog-content">
              {(this.taskInfo.regionPeriodList || []).map(i => {
                return [
                  <div class="title">{i.regionName}</div>,
                  <div
                    class="extend-info-cont"
                    domPropsInnerHTML={(i.regionContent || "").replace(
                      /\r?\n|\r/g,
                      "<br>"
                    )}
                  />,
                  <div class="dialog-img-wrap">
                    {i.regionImages && i.regionImages.length > 0
                      ? i.regionImages.map(val => (
                          <img src={val} alt="概述图片" class="taskImages" />
                        ))
                      : ""}
                  </div>
                ];
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}
