import { Component, Vue, Prop, Model, Watch } from "vue-property-decorator";
import _Template from "./style.vue";
import * as $ from "jquery";
import service from "@/service/index";
import bus from "@/util/eventBus";

@Component({
  mixins: [_Template],
})
export default class DzMenu extends Vue {
  @Prop({ default: () => [] })
  public menuItems: { label: string; value: number }[];
  @Model("input")
  value;

  @Prop({ default: "label" })
  label: string;

  public isMainMenuWrapperShow: boolean = false;

  @Watch("value", { immediate: true })
  watchCount(newVal, oldVal) {
    if (newVal === oldVal) {
      return;
    }
    this.isMainMenuWrapperShow = !!newVal;
  }
  protected mounted() {
    //
  }
  protected onHideMainMenu(event) {
    event.stopPropagation();
    event.preventDefault();
    setTimeout(() => {
      this.isMainMenuWrapperShow = false;
      this.$emit("input", false);
    }, 0);
  }
  protected onMenuItemClick(menuVal, item, e) {
    this.$emit("menuItemClick", menuVal, item, e);
  }

  protected render(h) {
    return (
      <div v-show={this.isMainMenuWrapperShow} class="dz-menu">
        <div
          class="blackMarsk"
          onClick={$event => this.onHideMainMenu($event)}
        ></div>
        <div class="mainMenu">
          <div class="menuList">
            {(this.menuItems || []).map((d, index) => {
              return (
                <div
                  class="listItem"
                  key={index}
                  onClick={$event => this.onMenuItemClick(d.value, d, $event)}
                >
                  {d[this.label]}
                </div>
              );
            })}
          </div>
          <div
            class="cancelBtn"
            hover-class="pressedItem"
            onClick={$event => this.onHideMainMenu($event)}
          >
            取消
          </div>
        </div>
      </div>
    );
  }
}
