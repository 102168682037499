import Vue from "vue";
import Router from "vue-router";
import Home from "./views/home/home";
import CustomerCount from "./views/customerCount/customerCount";
import Portrait from "./views/portrait/portrait";
import PromoteSale from "./views/promoteSale/promoteSale";
import Recruit from "./views/recruit/recruit";
import ShopSales from "./views/shopSales/shopSales";
import Target from "./views/target/target";
import TaskCenter from "./views/taskCenter/taskCenter";
import TaskTrack from "./views/taskTrack/taskTrack";
import AfterSale from "./views/afterSale/afterSale";
import RankingList from "./views/rankingList/rankingList";

// 新的PG数据
const HomePG = () => import("./views-pg/home/home");
const ShopSalesPG = () => import("./views-pg/shopSales/shopSales");
const RecruitPG = () => import("./views-pg/recruit/recruit");
const PromoteSalePG = () => import("./views-pg/promoteSale/promoteSale");
const PortraitPG = () => import("./views-pg/portrait/portrait");
const CustomerCountPG = () => import("./views-pg/customerCount/customerCount");
const TargetPG = () => import("./views-pg/target/target");
const coStructure = () => import("./views-pg/coStructure/coStructure");
const RankingListPG = () => import("./views-pg/rankingList/rankingList");
const Functions = () => import("./views-pg/functions/index");


const sop = () => import("./views-pg/sop/index");
const raceHorse = () => import("./views-pg/racehorse/index");

const taskPreview = () => import("./views-pg/taskPreview/taskPreview")

Vue.use(Router);

export default new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "报表1",
            component: Home,
        },
        {
            path: "/home",
            name: "报表2",
            component: Home,
        },
        {
            path: "/customerCount",
            name: "报表3",
            component: CustomerCount,
        },
        {
            path: "/portrait",
            name: "报表4",
            component: Portrait,
        },
        {
            path: "/promoteSale",
            name: "报表5",
            component: PromoteSale,
        },
        {
            path: "/recruit",
            name: "报表6",
            component: Recruit,
        },
        {
            path: "/shopSales",
            name: "报表7",
            component: ShopSales,
        },
        {
            path: "/target",
            name: "报表8",
            component: Target,
        },
        {
            path: "/TaskCenter",
            name: "任务中心",
            component: TaskCenter,
        },
        {
            path: "/TaskTrack",
            name: "任务跟踪",
            component: TaskTrack,
        },
        {
            path: "/AfterSale",
            name: "售后回访",
            component: AfterSale,
        },
        {
            path: "/ranking",
            name: "排行榜",
            component: RankingList,
        },

        {
            path: "/home-pg",
            name: "报表9",
            component: HomePG,
        },
        {
            path: "/customerCount-pg",
            name: "报表10",
            component: CustomerCountPG,
        },
        {
            path: "/portrait-pg",
            name: "报表11",
            component: PortraitPG,
        },
        {
            path: "/promoteSale-pg",
            name: "报表12",
            component: PromoteSalePG,
        },
        {
            path: "/recruit-pg",
            name: "报表13",
            component: RecruitPG,
        },
        {
            path: "/shopSales-pg",
            name: "报表14",
            component: ShopSalesPG,
        },
        {
            path: "/target-pg",
            name: "报表15",
            component: TargetPG,
        },
        {
            path: "/coStructure-pg",
            name: "报表16",
            component: coStructure,
        },
        {
            path: "/RankingListPG",
            name: "报表17",
            component: RankingListPG,
        },
        {
            path: "/functions",
            name: "报表18",
            component: Functions,
        },
        {
            path: "/sop",
            name: "报表19",
            component: sop,
        },
        {
            path: "/racehorse",
            name: "报表20",
            component: raceHorse,
        },
        {
            path: "/taskPreview",
            name: "报表20",
            component: taskPreview,
        },
    ],
});
