// 首页
import * as F2 from "@antv/f2";
import * as $ from "jquery";
import * as moment from "moment";
import { QinggerLibURL } from "qingger-lib";
import service from "../../service/index";
import GuiderList from "@/components/guiderList";
import collect from "collect.js";
import SvgIcon from "@/components/svg-icon";

let tabType = "2";

import {
  formatNum,
  getPercent,
  formatData,
  createCircleChart
} from "@/util/utils";
/**
 * 更新页面数据
 * @param saleSum
 * @param recruitSum
 * @param saleTargetSum
 * @param recruitTargetSum
 */
function updatePage(saleSum: string, recruitSum: string): void {
  $("#saleSum").html(`<span>￥</span>${saleSum}`);
  $("#recruitSum").html(`${recruitSum}人`);
}

/**
 * 清除图表缓存
 */

function initToday() {
  $("#saleSum").html(`<span>￥</span>0`);
  $("#recruitSum").html("0人");
}

function initCanvas() {
  $("#saleTargetSum").html(`<span>￥</span>0`);
  $("#recruitTargetSum").html("0人");
  $("#saleTarget").html(`<span>￥</span>${0}`);
  $("#salePlan").html(`<span>￥</span>${0}`);

  $("#saleChartWrap").html(
    '<canvas style="width: 100%; height: 100%;" id="saleChart"></canvas>'
  );
  $("#recruitChartWrap").html(
    '<canvas style="width: 100%; height: 100%;" id="recruitChart"></canvas>'
  );
}

/**
 * 数据请求
 */
function getData(type: string = "today", param?) {
  // R8-3 . 报表 - 是否有客流统计任务

  service
    .customerCountGet(param)
    .then(result => {
      if (result.content.result) {
        $("#customerCount").show();
      } else {
        $("#customerCount").hide();
      }
    })
    .catch(error => {
      console.log(error);
    });
  function success(result: dayArchiveResponse | monthArchiveResponse) {
    const collection = collect(result.content.list);
    const saleSum: string = formatNum(collection.sum("saleAmt"), 1); // 销售总额
    const recruitSum: string = formatNum(collection.sum("recruitCnt"), 1); // 招募总数
    initToday();
    updatePage(saleSum, recruitSum);
  }
  function success2(result: monthArchiveResponse) {
    const collection = collect(result.content.list);
    const saleSumStr: string = formatNum(collection.sum("saleAmt"), 1);
    const recruitSumStr: string = formatNum(collection.sum("recruitCnt"), 1);
    const saleTargetSumStr: string = formatNum(
      +collection.sum("saleTarget"),
      1
    );
    let recruitTargetSumStr: string;
    initCanvas();
    recruitTargetSumStr = formatNum(collection.sum("recruitTarget"), 1);
    const salePercent: number = getPercent(
      +collection.sum("saleTarget"),
      +collection.sum("saleAmt")
    );

    const recruitPercent: number = getPercent(
      +collection.sum("recruitTarget"),
      +collection.sum("recruitCnt")
    );
    $(".chart-inner").remove();

    createCircleChart(salePercent < 0 ? 0 : salePercent, "saleChart", "达成率");
    createCircleChart(
      recruitPercent < 0 ? 0 : recruitPercent,
      "recruitChart",
      "达成率"
    );
    // createChart(recruitPercent, "recruitChart");

    $("#saleTarget").html(`<span>￥</span>${saleSumStr}`);
    $("#salePlan").html(`<span>￥</span>${saleTargetSumStr}`);
    $("#recruitTargetSum").html(`${recruitSumStr}人`);
    $("#recruitPlan").html(`${recruitTargetSumStr}人`);
  }

  const today = moment().format("YYYY-MM-DD");
  const guiderId =
    $("#guiderSelect").attr("data-id") ||
    window.localStorage.getItem("guiderId");

  // if (tabType === "2") {
  //   guiderId = undefined;
  // }

  service
    .dayArchive({
      ...{
        beginDay: today,
        endDay: today,
        needSummary: 1,
        guiderId
      },
      ...param
    })
    .then((result: dayArchiveResponse) => {
      success(result);
    })
    .catch(error => {
      console.log(error);
    });
  service
    .monthArchive({
      ...{
        guiderId
      },
      ...param
    })
    .then((result: monthArchiveResponse) => {
      success2(result);
    })

    .catch(error => {
      console.log(error);
    });
}

/**
 * 获取店铺列表
 */
function getShopList() {
  service
    .guiderInfo()
    .then((result: guiderInfoReponse<guiderInfoReponseItem>) => {
      let html = "";
      result.content.list.forEach(item => {
        if (item.isDefault) {
          $(".person-address").text(item.siteName);
          window.localStorage.setItem("siteCode", item.siteCode);
        }
        html += `<li site-code="${item.siteCode}" guider="${
          item.guider
        }" class="${item.isDefault ? "selected" : ""}">${item.siteName}</li>`;
      });
      $(".toggle-shop-wrap ul").html(html);
      getData();
    })
    .catch(error => {
      console.log(error);
    });
}

function productSalePerformance(param?) {
  service.productSalePerformance(param || null).then(result => {
    if (result.content) {
      $(".promote-goods").show();
      const num = $(".promote-goods").find(".num");
      const total = $(".promote-goods").find(".total");
      const res = result.content;
      num.find(".today").html(formatNum(res.dayQuantity, 1) + "");
      num.find(".moth").html(formatNum(res.quantity, 1) + "");
      num.find(".target").html(formatNum(res.numTarget, 1) + "");
      num.find(".rate").html(formatNum(res.numPercent, 1) + "%");
      const day = new Date().getUTCDate();
      const currentDays = moment().daysInMonth();
      const scale = (day / currentDays) * 100;

      num
        .find(".rate")
        .removeClass("red")
        .removeClass("up-color");
      total
        .find(".rate")
        .removeClass("red")
        .removeClass("up-color");

      if (+formatNum(res.numPercent, 1) >= scale) {
        num.find(".rate").addClass("up-color");
      } else {
        num.find(".rate").addClass("red");
      }
      if (+formatNum(res.salesPercent, 1) >= scale) {
        total.find(".rate").addClass("up-color");
      } else {
        total.find(".rate").addClass("red");
      }
      total.find(".today").html(formatNum(res.daySales, 1) + "");
      total.find(".moth").html(formatNum(res.sales, 1) + "");
      total.find(".target").html(formatNum(res.salesTarget, 1) + "");
      total.find(".rate").html(formatNum(res.salesPercent, 1) + "%");
    }
  });
}

function clickSpan() {
  if ($(".guider-list").is(":hidden")) {
    $(".guider-list").show();
    $(".popupMask").show();
  } else {
    $(".guider-list").hide();
    $(".popupMask").hide();
  }
  $(".data-list").hide();
}

function changeShop(siteCode: string) {
  service
    .siteChange({
      toSiteCode: siteCode
    })
    .then(() => {
      window.localStorage.setItem("siteCode", siteCode);
      getData($("#timeSelect").text() === "今日" ? "today" : "");
    })
    .catch(error => {
      console.log(error);
    });
}

function getInfo() {
  service
    .loginInfo()
    .then((result: loginInfoResponse) => {
      $(".person-img").css(
        "background-image",
        `url(${result.content.empAvatar})`
      );
      $(".person-name").text(result.content.empName);
    })
    .catch(error => {
      console.log(error);
    });
}

/**
 * 页面事件
 */
function pageAction() {
  /**
   * 切换店铺
   */
  function closeToggle() {
    $(".toggle-shop-wrap").hide();
    $(".toggle-shop-wrap .toggle-title").show();
    $(".toggle-shop-wrap ul").hide();
  }
  $(".toggle-shop").click((event: any) => {
    event.stopPropagation();
  });
  $(".toggle-shop-wrap").click(closeToggle);
  $("#toggleShop").click(() => {
    $(".toggle-shop-wrap").show();
  });
  $(".toggle-shop-wrap .cancel").click(closeToggle);

  $(".toggle-title").click(function() {
    $(this)
      .hide()
      .next()
      .show();
  });

  $(".toggle-shop").on("click", "li", function() {
    const siteCode = $(this).attr("site-code");
    closeToggle();
    if (!$(this).hasClass("selected")) {
      $(".person-address").text($(this).text());
      $(this)
        .addClass("selected")
        .siblings()
        .removeClass("selected");
      window.localStorage.setItem("siteCode", $(this).attr("site-code"));
      window.localStorage.setItem("guiderId", $(this).attr("guider"));
      changeShop(siteCode);
    }
  });

  /**
   * 切换时段
   */
  $(".data-list li").click(function(event: any) {
    event.stopPropagation();
    const type: string = $(this).attr("data-type");
    getData(type);
    $("#timeSelect").text(type === "today" ? "今日" : "本月");
    $(".data-time").text(type === "today" ? "今日" : "本月");
    $(".data-list").hide();
    $(".popupMask").hide();
  });

  /**
   * 时间段列表的切换
   */
  $("#timeSelect").click(() => {
    if ($(".data-list").is(":hidden")) {
      $(".data-list").show();
      $(".popupMask").show();
    } else {
      $(".data-list").hide();
      $(".popupMask").hide();
    }
    $(".guider-list").hide();
  });

  $(".popupMask").click(() => {
    $(".data-list").hide();
    $(".guider-list").hide();
    $(".popupMask").hide();
  });

  $(".top-tap-wrap .top-tap span").click(function() {
    const type = $(this).attr("data-type");
    tabType = type;
    let guiderId = "";
    $(this)
      .addClass("tap-on-selected")
      .siblings()
      .removeClass("tap-on-selected");
    if (type === "1") {
      $("#monthTarget").hide();
      window.localStorage.setItem("isGuider", "0");
      $(".top-tap-wrap .portrait-select").show();
      (window as any).guiderId = $("#guiderSelect").attr("data-id");
      guiderId =
        $("#guiderSelect").attr("data-id") ||
        window.localStorage.getItem("guiderId");
      (window as any).empCode = $("#guiderSelect").attr("data-code");
      $("#siteGuider").css("display", "inline-block");

      eventBus.emit("taskCenterOnSelectGuider", {
        empCode: "",
        empName: "全店",
        guiderId
      });
    } else {
      guiderId = window.localStorage.getItem("guiderId");
      console.log(guiderId);
      if (
        +moment().format("DD") >= +window.localStorage.getItem("visible_day")
      ) {
        $("#monthTarget").show();
      }
      $(".top-tap-wrap .portrait-select").hide();
      window.localStorage.setItem("isGuider", "1");
      $("#siteGuider").css("display", "none");


    }
    getData($("#timeSelect").text() === "今日" ? "today" : "");
    productSalePerformance({ guiderId });


  });

  $(".pro-title .tabs li").on("click", function() {
    const type = $(this).attr("data-type");
    $(".pro-title .tabs .active").removeClass("active");
    $(this).addClass("active");
    if (+type === 1) {
      $(".pro-content .num").show();
      $(".pro-content .total").hide();
    } else {
      $(".pro-content .num").hide();
      $(".pro-content .total").show();
    }
  });
}

const getPageData = (guiderId, empCode) => {
  const param = { guiderId, empCode };
  if (!guiderId) {
    param.guiderId = localStorage.getItem("guiderId");
  }
  productSalePerformance(param);
  getData($("#timeSelect").text() === "今日" ? "today" : "", param);
};

function getIsvMPConfig() {
  service
    .isvMPConfig()
    .then(result => {
      if (result.content.mpCode !== "MPRStore") {
        $(".top-nav-bar").show();
        getShopList();
        getInfo();
      } else {
        $(".top-nav-bar").hide();
      }
    })
    .catch(error => {
      console.log(error);
    });
}

function targetConfigGet() {
  service.targetConfigGet().then((resp: any) => {
    if (resp && resp.content) {
      const res: {
        visible_day: number;
        sale_divide: number;
        recruit_divide: number;
        main_goods_divide: number;
      } = resp.content;
      window.localStorage.setItem("visible_day", `${res.visible_day}`);
      window.localStorage.setItem("sale_divide", `${res.sale_divide}`);
      window.localStorage.setItem("recruit_divide", `${res.recruit_divide}`);
      window.localStorage.setItem(
        "main_goods_divide",
        `${res.main_goods_divide}`
      );
      const siteRole = window.localStorage.getItem("siteRole");
      if (!res.main_goods_divide) {
        $(".promote-goods").hide();
      }
      $("#monthTarget").hide();
      if (+moment().format("DD") >= +res.visible_day) {
        setTarget();
      }
    }
  });
  service
    .contactMemberGet({ appointedGuiderId: localStorage.getItem("guiderId") })
    .then((resp: any) => {
      const siteRole = window.localStorage.getItem("siteRole");

      if (siteRole !== "101" && +resp.content.performanceType !== 1) {
        $(".top-tap-wrap").hide();
      }
    });
}

function setTarget() {
  const month = new Date().getMonth() + 2;
  const text = `${month === 13 ? 1 : month}月目标`;
  $("#monthTarget").html(text);
  $("#monthTarget").show();
}

const onload = () => {
  document.title = "看板";

  const url = window.location.href;
  const urlLibUtil = QinggerLibURL.urlUtil(url);
  const tokenFromQuery = urlLibUtil.getUrlQueryItem("token");
  const siteFromQuery = urlLibUtil.getUrlQueryItem("siteCode");
  const guiderFromQuery = urlLibUtil.getUrlQueryItem("guiderId");
  const empFromQuery = urlLibUtil.getUrlQueryItem("empCode");
  const siteRoleQuery = urlLibUtil.getUrlQueryItem("siteRole");

  if (tokenFromQuery) {
    window.localStorage.setItem("token", tokenFromQuery || "token-1");
  }
  if (siteFromQuery) {
    window.localStorage.setItem("siteCode", siteFromQuery || "");
  }
  if (guiderFromQuery) {
    window.localStorage.setItem("guiderId", guiderFromQuery || "");
  }
  if (empFromQuery) {
    window.localStorage.setItem("empCode", empFromQuery || "");
  }
  if (siteRoleQuery) {
    window.localStorage.setItem("siteRole", siteRoleQuery || "");
  }
  // 当token失效时，可能已经有多个请求已同时发出。通过此属性，可以判断此token是否短期内被更新过，防止重复更新token
  window.localStorage.setItem("tokenTime", `${new Date().valueOf()}`);

  // 设置是否是导购
  window.window.localStorage.setItem("isGuider", "1");
  setTimeout(() => {
    $("body").show();
    // getShopList();
    pageAction();
    // getInfo();
    getIsvMPConfig();
    getData();
    targetConfigGet();
    productSalePerformance();
  }, 100);
};
import { Component, Vue } from "vue-property-decorator";
import _Template from "./home-temp.vue";
import InitJSSDK from "@/util/initJSSDK";
import {AppUrls} from "@/util/consts";
import eventBus from "@/util/eventBus";

@Component({
  mixins: [_Template],
  components: { GuiderList, SvgIcon }
})
export default class Home extends Vue {
  protected guiderList: any[] = [];

  mounted() {
    onload();
    this.getGuiderList();
  }

  getScan() {
    // InitJSSDK.Init("scanQRCode", res => {
    //   alert(JSON.stringify(res));
    // });
    //
    InitJSSDK.Init("navigateTo",()=> {
      (window as any).wx.miniProgram.navigateTo({url:AppUrls.about})
    });
  }

  getData(guiderId, empCode) {
    getPageData(guiderId, empCode);
  }

  getGuiderList() {
    service
      .siteGuiderList()
      .then((data: any) => {
        this.guiderList = data.content.list;
      })
      .catch(error => {
        console.log(error);
      });
  }
}
