import { Component, Vue, Prop, Model, Watch } from "vue-property-decorator";
import _Template from "./style.vue";
import * as $ from "jquery";
import service from "@/service/index";
import bus from "@/util/eventBus";

@Component({
  mixins: [_Template],
})
export default class DzPopup extends Vue {
  @Model("input")
  value;

  @Prop({ default: "" })
  info: string;

  @Prop({ default: "提示" })
  title: string;

  @Prop({ default: "取消" })
  cancelText: string;

  @Prop({ default: "确定" })
  confirmText: string;

  @Prop({ default: false, type: Boolean })
  padding: boolean;

  public isMainMenuWrapperShow: boolean = false;

  @Watch("value", { immediate: true })
  watchCount(newVal, oldVal) {
    if (newVal === oldVal) {
      return;
    }
    this.isMainMenuWrapperShow = !!newVal;
  }
  protected mounted() {
    //
  }
  protected onBlackMarskClick(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }
  protected onCancel(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    setTimeout(() => {
      this.isMainMenuWrapperShow = false;
      this.$emit("close", false);
      this.$emit("input", false);
    }, 0);
  }
  protected onOk(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    setTimeout(() => {
      this.isMainMenuWrapperShow = false;
      this.$emit("close", true);
      this.$emit("input", false);
    }, 0);
  }

  protected render(h) {
    return (
      <div v-show={this.isMainMenuWrapperShow} class="dz-popup">
        <div class="blackMarsk" onClick={($event) => this.onBlackMarskClick($event)}></div>
        <div class="mainPopup" onClick={($event) => this.onBlackMarskClick($event)}>
          <div class="mainContent">
            {this.title && <div class="title">{this.title}</div>}
            <div class={["info", this.padding&&'padding']}>{this.info}</div>
          </div>
          <div class="tool-buttons">
            <div class="cancelBtn" hover-class="pressedItem" onClick={($event) => this.onCancel($event)}>
              {this.cancelText}
            </div>
            {this.confirmText && (
              <div class="okBtn" hover-class="pressedItem" onClick={($event) => this.onOk($event)}>
                {this.confirmText}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
