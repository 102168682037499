// 会员招募
import * as F2 from "@antv/f2";
import * as $ from "jquery";
import service from "../../service/index";
import GuiderList from "@/components/guiderList";

import { formatNum, createCircleChart, createPieChart } from "@/util/utils";
import eventBus from "@/util/eventBus";

const yesterday = 1;
let type: string = "";

let topVa: any = yesterday; // 头部时间,默认昨天

let seleectParams: any = {};

let vueExa: any = null;
/**
 * 生成超募趋势及同期对比图表
 * @param {Array<yearVSListItem>} data
 * @param {string} id
 */
function createYearvsChart(data: yearVSListItem[], id: string): void {
  /**
   * 格式化日期
   * @param {string} oldDate
   * @return {string}
   */
  function createDate(oldDate: string): string {
    const array = oldDate.split("-");
    let str = "";
    str =
      array.length === 3
        ? `${array[0]}\n${array[1]} - ${array[2]}`
        : `${array[0]}\n${array[1]}`;
    return str;
  }
  // 格式化data
  const newData: { type: string; year: string; value: number }[] = [];
  data.reverse(); // 反转数组
  data.forEach(item => {
    newData.push({
      type: "同期",
      year: createDate(item.name),
      value: item.lyVal
    });
    newData.push({
      type: "本期",
      year: createDate(item.name),
      value: item.val
    });
  });

  const chart:any = new F2.Chart({
    id,
    pixelRatio: window.devicePixelRatio
  });
  chart.source(newData);
  chart.axis("year");
  chart.axis("value");
  chart
    .area()
    .position("year*value")
    .color("type")
    .shape("smooth");
  chart
    .line()
    .position("year*value")
    .color("type")
    .shape("smooth");
  chart.render();
}

/**
 * 更新页面数据
 * @param {historyRecruitResponse} result
 */
function updatePage(result: historyRecruitResponse): void {
  const content = result.content;
  // 招募达成
  $("#recruitFinishChartRecruitCnt").html(
    `${content.recruitFinish.recruitCnt}`
  );
  $("#recruitFinishChartLyRecruitCnt").html(
    `${content.recruitFinish.lyRecruitCnt}`
  );
  $("#recruitFinishChartLyRecruitTarget").html(
    `${content.recruitFinish.veteranMemberNum}`
  );
  // 招募率
  $("#memberDealCnt").html(`${content.recruitRate.memberDealCnt}`);
  $("#recruitOpportunityCnt").html(
    `${content.recruitRate.recruitOpportunityCnt}`
  );
  // 招募成交率
  $("#recruitSaleCnt").html(`${content.recruitDeal.recruitSaleCnt}`);
  $("#recruitCnt").html(`${content.recruitDeal.recruitCnt}`);
}

/**
 * 清除图表缓存
 */
function initCanvas() {
  $("#recruitFinishChartRecruitCnt").html("");
  $("#recruitFinishChartLyRecruitCnt").html("");
  $("#recruitFinishChartLyRecruitTarget").html("");
  $("#memberDealCnt").html("");
  $("#recruitOpportunityCnt").html("");
  $("#recruitSaleCnt").html("");
  $("#recruitCnt").html("");
  $("#recruitFinishChartWrap").html(
    '<canvas style="width: 100%; height: 100%;" id="recruitFinishChart"></canvas>'
  );
  $("#yearvsChartWrap").html(
    '<canvas style="width: 100%; height: 100%;" id="yearvsChart"></canvas>'
  );
  $("#recruitChannelChartWrap").html(
    '<canvas style="width: 100%; height: 100%;" id="recruitChannelChart"></canvas>'
  );
  $("#recruitDealRateChartWrap").html(
    '<canvas style="width: 100%; height: 100%;" id="recruitDealRateChart"></canvas>'
  );
  $("#recruitRateChartWrap").html(
    '<canvas style="width: 100%; height: 100%;" id="recruitRateChart"></canvas>'
  );
}

/**
 * 数据请求
 */
function getData(typeNumber: string = "") {
  service
    .historyRecruit({
      ...{
        va: topVa,
        items: [
          "recruitFinish",
          "yearVS",
          "recruitChannel",
          "recruitRate",
          "recruitDeal"
        ]
      },
      ...seleectParams
    })
    .then((result: historyRecruitResponse) => {
      if (typeNumber !== type) {
        return;
      }
      initCanvas();
      const content = result.content;
      updatePage(result);
      createCircleChart(
        content.recruitFinish.recruitPercent,
        "recruitFinishChart",
        "招募达成率"
      );
      createYearvsChart(content.yearVS.list, "yearvsChart");
      createPieChart(content.recruitChannel.list, "recruitChannelChart", {
        // 招募渠道
        pieLabel: {
          sidePadding: 35,
          label1: function label1(data: any) {
            return {
              text: data.name,
              fill: "#000"
            };
          },
          label2: function label2(data: any) {
            return {
              fill: "#999999",
              text: data.val ? formatNum(data.val.toFixed(2)):'',
              fontWeight: 500,
              fontSize: 10
            };
          }
        },
        guide: (total: number, fontSize: number) => {
          return `<span style="color: #999;white-space:nowrap;font-size: ${fontSize *
            0.2}px">入会人数</span><br><span style="font-size: ${fontSize *
            0.24}px">${formatNum(total)}</span>`;
        }
      });
      createCircleChart(
        content.recruitRate.memberDealRealRate,
        "recruitRateChart",
        "招募率"
      );
      createCircleChart(
        content.recruitDeal.recruitSaleRate,
        "recruitDealRateChart",
        "招募成交率"
      );
    })
    .catch((error: any) => {
      console.log(error);
    });

  if (vueExa) {
    vueExa.getRuictData();
  }
}

/**
 * 获取小程序对于企业的配置信息,是否显示老会员激活数
 */
function getEnterpriseConfig() {
  service.enterpriseConfig().then((res: enterpriseConfigResponse) => {
    if (res.content.isVeteranMember) {
      $("#old-member").show();
    } else {
      $("#old-member").hide();
    }
  });
}

/**
 * 获取会员招募说明
 */
function getExtendInfo() {
  service.extendInfo().then((result: extendInfoResponse) => {
    if (
      result.content.functional_module_desc.Recruit &&
      result.content.functional_module_desc.Recruit !== ""
    ) {
      $("#showDialog").show();
      $("#dialogCover .content").html(
        result.content.functional_module_desc.Recruit
      );
    } else {
      $("#showDialog").hide();
    }
  });
}
/**
 * 页面事件
 */
function pageEvents() {
  $("#help").click(() => {
    $("#dialogCover").show();
    $("body").addClass("lock-scroll");
    $("html").addClass("lock-scroll");
  });

  $(".top-nav-wrap li").click(function() {
    if (![3, 5, 6].find(i => i === +$(this).attr("data-day"))) {
      $(".lastDayTip").hide();
    } else {
      $(".lastDayTip").show();
    }
    $(this)
      .addClass("nav-on-selected")
      .siblings()
      .removeClass("nav-on-selected");
    topVa = +$(this).attr("data-day");
    type = "";
    getData();
  });
  $("#showDialog").click(() => {
    $("#dialogCover").show();
    $("body").addClass("lock-scroll");
    $("html").addClass("lock-scroll");
  });

  $("#closeDialog").click(() => {
    $("#dialogCover").hide();
    $("body").removeClass("lock-scroll");
    $("html").removeClass("lock-scroll");
  });

  $(".top-tap-wrap span").click(function() {
    type = $(this).attr("data-type");
    $(this)
      .addClass("tap-on-selected")
      .siblings()
      .removeClass("tap-on-selected");
    if (type === "1") {
      seleectParams = { empCode: localStorage.getItem("empCode") };
      seleectParams.guiderId = localStorage.getItem("guiderId");
      window.localStorage.setItem("isGuider", "1");
      $(".data-select-wrap").hide();
      vueExa.isShop = false;
    } else {
      vueExa.isShop = true;
      seleectParams = { empCode: "" };
      seleectParams.guiderId = localStorage.getItem("guiderId");
      $(".data-select-wrap").show();
      window.localStorage.setItem("isGuider", "0");
      (window as any).guiderId = $("#guiderSelect").attr("data-id");
      (window as any).empCode = $("#guiderSelect").attr("data-code");

      eventBus.emit("taskCenterOnSelectGuider", {
        empCode: "",
        empName: "全店",
        guiderId: ""
      });
    }

    getData(type);
  });

  $(".popupMask").click(() => {
    $(".guider-list").hide();
    $(".popupMask").hide();
  });
}

function getGuiderList() {
  service
    .siteGuiderList()
    .then((data: any) => {
      $("#siteGuider").empty();
      $("#siteGuider").html(data && data.content);
    })
    .catch(error => {
      console.log(error);
    });
}

function getIsvMPConfig() {
  service
    .isvMPConfig()
    .then(result => {
      if (result.content.mpCode !== "MPRStore") {
        $("#homeTab").show();
      } else {
        $("#homeTab").hide();
      }
    })
    .catch(error => {
      console.log(error);
    });
}

const getPageData = () => {
  topVa = 1;
  $(".top-nav-wrap")
    .children("li")
    .each((index: any, el: any) => {
      if ($(el).hasClass("nav-on-selected")) {
        topVa = $(el).attr("data-day");
      }
    });
  type = "2";
  getData("2");
};

function sale_divide() {
  service
    .contactMemberGet({ appointedGuiderId: localStorage.getItem("guiderId") })
    .then((resp: any) => {
      const siteRole = window.localStorage.getItem("siteRole");

      if (siteRole !== "101" && +resp.content.performanceType !== 1) {
        $(".top-tap-wrap").hide();
        $(".guider-tip-wrap").show();
      }
    });
}

const onload = () => {
  document.title = "会员入会";
  window.localStorage.setItem("isGuider", "1");
  $("body").show();
  pageEvents();
  getData();
  getExtendInfo();
  getIsvMPConfig();
  getEnterpriseConfig();
  getGuiderList();
  sale_divide();
};

import { Component, Vue } from "vue-property-decorator";
import _Template from "./recruit-temp.vue";
import HelpTips from "@/components/helpTips";

@Component({
  mixins: [_Template],
  components: { GuiderList, HelpTips }
})
export default class Recruit extends Vue {
  get showRuict() {
    return this.isShopSelectGuider && this.isShop;
  }
  protected guiderList: any[] = [];
  protected guiderMock: any[] = [];
  protected guiderListMore: boolean = false;
  protected isShop: boolean = false;
  protected isShopSelectGuider: boolean = true;
  mounted() {
    vueExa = this;

    setTimeout(() => {
      onload();

      this.getGuiderList();
      this.getRuictData();
    });
  }
  closeDialog(event, val) {
    event.stopPropagation();
    if (val === 1) {
      $("#dialogCover").hide();
    }
  }

  getRuictData() {
    service
      .reportGuiderRecruitList({
        ...{
          va: topVa
        },
        ...seleectParams
      })
      .then(res => {
        this.guiderMock = (res.content.list || []).sort((a, b) => {
          return b.recruitCnt - a.recruitCnt;
        });
      });
  }
  getData(guiderId, empCode) {
    seleectParams = { guiderId, empCode };
    if (!guiderId) {
      this.isShopSelectGuider = true;
      seleectParams.guiderId = localStorage.getItem("guiderId");
    } else {
      this.isShopSelectGuider = false;
    }
    getPageData();
    this.getRuictData();
  }
  getGuiderList() {
    service
      .siteGuiderList()
      .then((data: any) => {
        this.guiderList = data.content.list;
      })
      .catch(error => {
        console.log(error);
      });
  }
  protected showTipDialog() {
    $("#dialogCover").show();
  }
  protected selectGuider(item) {
    eventBus.emit("taskCenterOnSelectGuider", {
      empName: item.empName,
      empCode: item.empCode
    });

    this.getData(item.guiderId, item.empCode);
  }
}
