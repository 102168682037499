import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import cloneDeep from "lodash/cloneDeep";
import { taskTypeCustomerStatus } from "../taskCenterDetail/taskCentered/taskCentered";
import eventBus from "@/util/eventBus";
import DZMainService from "@/service";

@Component({ name: "task-filter" })
export default class TaskFilter extends Vue {
  get isHasSelect(): boolean {
    return this.taskTypeSelect.isOpen || this.commSelect.isOpen || this.execSelect.isOpen || this.saleAfterSelect.isOpen || this.saleAfterTypeSelect.isOpen || this.filterArrayList.some((i) => i.isOpen);
  }
  @Prop()
  taskId;

  @Prop()
  siteCode;

  @Prop()
  guiderId;

  @Prop()
  keywords;

  @Prop()
  taskTypeCode;

  @Prop()
  execGuiderId;

  // 忽略清空对象
  @Prop()
  ignoreObj: boolean;

  @Prop({ default: [] })
  setExecGuiderList: any[];

  @Prop({ default: false })
  isFilterShow: boolean;

  communicatesResults: {
    communicatesResult: string;
    communicatesResultName: string;
    isSelected?: boolean;
  }[] = [
    { communicatesResult: "WillIn", communicatesResultName: "会到店" },
    { communicatesResult: "WillNotIn", communicatesResultName: "不来" },
    { communicatesResult: "Uncertain", communicatesResultName: "不确定" },
  ];

  @Prop()
  saleAfterStatusList;

  @Prop()
  curTabIndex: number;

  @Prop()
  saleRegionList: any[]; // 只针对售后任务

  // saleAfterStatusList 处理后的数据
  initedSASList: any[] = [];

  loading: boolean = true;

  execGuiderList: any[] = [];
  filterArrayList: any[] = [];

  get openedFilterArrayList() {
    return this.filterArrayList.filter((i) => {
      return i?.isOpen;
    });
  }

  // 是不是搜索
  @Prop()
  filterSearchFlag: boolean = false;

  taskTypeSelect = {
    text: "",
    code: "",

    isOpen: false,
  };
  saleAfterTypeSelect = {
    text: "",
    code: "",

    isOpen: false,
  };
  execSelect = {
    text: "",
    code: "",

    isOpen: false,
  };

  commSelect = {
    text: "",
    code: "",

    isOpen: false,
  };
  saleAfterSelect = {
    text: "",
    code: "none",
    isOpen: false,
  };

  // 除了售后的任务
  public taskTypeCustomerStatus = taskTypeCustomerStatus;

  public custNumber: number = 0;
  // 没有请求的数据
  public postData: boolean = false;

  public taskLoadSuccess = false;

  public commResults = cloneDeep(this.communicatesResults);

  @Watch("isFilterShow", { immediate: true })
  onIsFilterShowChange(n, o) {
    if (!n) {
      this.setSelectStatus();
    }
  }
  @Watch("curTabIndex", { immediate: true })
  onCurTabIndexChange(n, o) {
    if (n && n !== o) {
      // 已忽略
      if (n === 6 && this.taskTypeCode !== "SaleAfterV2") {
        if (this.commResults.some((i) => i.communicatesResult === "ignore")) {
          return;
        }
        this.commResults.push({
          communicatesResult: "ignore",
          communicatesResultName: "已忽略",
        });
      } else {
        const res = this.commResults.filter((i) => i.communicatesResult !== "ignore");
        this.$set(this, "commResults", res);
      }
    }
  }
  @Watch("filterSearchFlag", { immediate: true })
  onFilterSearchFlagChange(n, o) {
    if (n !== o) {
      // 已忽略
      if (n && this.taskTypeCode !== "SaleAfterV2") {
        if (this.commResults.some((i) => i.communicatesResult === "ignore")) {
          return;
        }
        this.commResults.push({
          communicatesResult: "ignore",
          communicatesResultName: "已忽略",
        });
      } else {
        const res = this.commResults.filter((i) => i.communicatesResult !== "ignore");
        this.$set(this, "commResults", res);
      }
    }
  }

  public mounted() {
    this.custNumber = 0;
    eventBus.on("taskLoadSuccess", (val) => {
      if (val) {
        if (this.loading) {
          this.getCorpTagsAll().then(() => {
            this.$nextTick(() => {
              this.execGuiderList = cloneDeep(this.setExecGuiderList);
              this.loading = false;
              this.taskLoadSuccess = true;
              this.clearAllStatus();
              this.onFilterSearchFlagChange(1, 0);
              this.onCurTabIndexChange(1, 0);
            });
          });
        } else {
          this.taskLoadSuccess = true;
          this.clearAllStatus();
        }
      }
    });

    eventBus.on("clearFilterData", (val) => {
      if (val && this.taskLoadSuccess) {
        this.$nextTick(() => {
          this.clearAllStatus();
          this.custNumber = 0;
        });
      }
    });
    this.initedSASList = this.saleAfterStatusList || [];
  }

  @Watch("saleAfterStatusList")
  onSaleAfterStatusListChange(e) {
    this.initedSASList = e;
  }

  setSelectStatus() {
    this.taskTypeSelect.isOpen = false;
    this.saleAfterTypeSelect.isOpen = false;
    this.saleAfterSelect.isOpen = false;
    this.commSelect.isOpen = false;
    this.execSelect.isOpen = false;
    this.filterArrayList = this.filterArrayList.map((val, index) => {
      val.isOpen = false;
      return val;
    });
  }

  clearFilterStatus() {
    const filterArrayList = cloneDeep(this.filterArrayList); // 复制不改写对象
    filterArrayList.map((item, ind) => {
      item.isOpen = false;
      return item;
    });
    this.filterArrayList = filterArrayList;
  }

  /**
   *
   * @param list
   * @param param
   * @param value
   */
  public handleChangeStatus(list: any[], param: string, value: string) {
    return list.find((i) => i[param] === value);
  }

  filterSelectValue() {
    let count = 0; // 记录选中项
    this.filterArrayList.forEach((item) => {
      item.data.forEach((tagItem) => {
        if (tagItem.isSelected) {
          count++;
        }
      });
    });
    const selectedTagsArray = [];
    this.filterArrayList.map((item) => {
      const itemObj = {
        key: "",
        values: [],
      };
      item.data.map((tagItem) => {
        if (tagItem.isSelected) {
          if (!itemObj.key) {
            itemObj.key = tagItem.tagKey;
          }
          itemObj.values.push(tagItem.tagVal);
        }
      });
      if (itemObj.key) {
        selectedTagsArray.push(itemObj);
      }
    });

    return selectedTagsArray;
  }

  public clearAllStatus() {
    this.execSelect = {
      text: "",
      code: "",

      isOpen: false,
    };

    this.taskTypeSelect = {
      text: "",
      code: "",

      isOpen: false,
    };
    this.saleAfterTypeSelect = {
      text: "",
      code: "",

      isOpen: false,
    };

    this.commSelect = {
      text: "",
      code: "",
      isOpen: false,
    };
    this.saleAfterSelect = {
      text: "",
      code: "none",
      isOpen: false,
    };
    this.execGuiderList = this.execGuiderList.map((i) => {
      i.isSelected = false;
      return i;
    });
    this.taskTypeCustomerStatus = this.taskTypeCustomerStatus.map((i: any) => {
      i.isSelected = false;
      return i;
    });

    if (this.initedSASList) {
      this.initedSASList = this.initedSASList.map((i) => {
        i.isSelected = false;
        return i;
      });
    }
    if (this.commResults) {
      this.commResults = this.commResults.map((i) => {
        i.isSelected = false;
        return i;
      });
    }

    this.filterArrayList = this.filterArrayList.map((i) => {
      i.siganl = false;
      i.isOpen = false;
      i.data = i.data.map((v) => {
        v.isSelected = false;
        return v;
      });
      return i;
    });
    // 特殊处理，售后任务选中待回访
    if (this.taskTypeCode === "SaleAfterV2" && !this.filterSearchFlag) {
      this.saleAfterSelect.code = "0";
      this.saleAfterSelect.text = "待回访";

      this.initedSASList = this.initedSASList.map((i) => {
        if (i.value === 0) {
          i.isSelected = true;
        }
        return i;
      });
    } else {
      this.saleAfterSelect = {
        text: "",
        code: "none",
        isOpen: false,
      };
    }
  }

  /**
   * 获取标签列表
   * @return null
   */
  public getCorpTagsAll(): Promise<any> {
    return DZMainService.getCorpTagsAct({ type: 1 }).then((res) => {
      // 加入标签状态
      const resList = res.content.list.map((item, index) => {
        item.siganl = false;
        item.data.map((tagItem) => {
          tagItem.isSelected = false;
        });
        return item;
      });

      // 复制原始数据
      this.filterArrayList = JSON.parse(JSON.stringify(resList));
    });
  }
  /**
   * 组建不显示
   * @return null
   */
  public onFilterHide(): void {
    this.onConfirmFilter();
  }
  /**
   * 筛选项折叠和展开
   * @return null
   * @param index
   * @param status
   */
  public onFoldfilterItem(index: number, status?): void {
    const filterArrayList = cloneDeep(this.filterArrayList); // 复制不改写对象

    if (status === "saleAfter") {
      this.taskTypeSelect.isOpen = false;
      this.commSelect.isOpen = false;
      this.execSelect.isOpen = false;
      this.saleAfterSelect.isOpen = false;
      this.saleAfterTypeSelect.isOpen = !this.saleAfterTypeSelect.isOpen;

      this.clearFilterStatus();
      return;
    }

    if (status === "taskType") {
      this.saleAfterTypeSelect.isOpen = false;
      this.commSelect.isOpen = false;
      this.execSelect.isOpen = false;
      this.saleAfterSelect.isOpen = false;
      this.taskTypeSelect.isOpen = true;

      this.clearFilterStatus();
      return;
    }

    if (status === "sale") {
      this.commSelect.isOpen = false;
      this.execSelect.isOpen = false;
      this.taskTypeSelect.isOpen = false;
      this.saleAfterSelect.isOpen = !this.saleAfterSelect.isOpen;
      this.saleAfterTypeSelect.isOpen = false;

      this.clearFilterStatus();
      return;
    }

    if (status === "comm") {
      this.taskTypeSelect.isOpen = false;
      this.commSelect.isOpen = !this.commSelect.isOpen;
      this.execSelect.isOpen = false;
      this.saleAfterSelect.isOpen = false;
      this.saleAfterTypeSelect.isOpen = false;

      this.clearFilterStatus();
      return;
    }
    if (status === "exec") {
      this.taskTypeSelect.isOpen = false;
      this.execSelect.isOpen = !this.execSelect.isOpen;
      this.commSelect.isOpen = false;
      this.saleAfterSelect.isOpen = false;
      this.saleAfterTypeSelect.isOpen = false;

      this.clearFilterStatus();

      return;
    }

    this.execSelect.isOpen = false;
    this.commSelect.isOpen = false;
    this.taskTypeSelect.isOpen = false;
    this.saleAfterSelect.isOpen = false;
    this.saleAfterTypeSelect.isOpen = false;

    filterArrayList.map((item, ind) => {
      item.isOpen = index === ind ? !item.isOpen : false;
      return item;
    });
    this.filterArrayList = filterArrayList;
  }

  /**
   * 筛选项被选中
   * @return null
   * @param index
   * @param taskIndex
   */
  public onFilterItemSelect(index, taskIndex): void {
    const filterArrayList = cloneDeep(this.filterArrayList); // 复制不改写对象
    const item = filterArrayList.find((v, i) => i === index);
    const tagItem = item.data.find((v, i) => i === taskIndex);

    if (item.multiple) {
      // 多选
      tagItem.isSelected = !tagItem.isSelected;
    } else {
      // 单选
      if (tagItem.isSelected) {
        // 点击已选中的本身未选中
        tagItem.isSelected = false;
      } else {
        item.data.map((single, indexs) => {
          single.isSelected = indexs === taskIndex;
        });
      }
    }
    // 有选中的标记
    item.siganl = item.data.some((i) => i.isSelected);
    // 选中的文字
    item.selectText = item.data
      .filter((single) => {
        return single.isSelected;
      })
      .map((i) => {
        return i.tagValName;
      })
      .join("，");

    // 重置沟通导购和沟通结果
    this.commSelect.isOpen = false;
    this.execSelect.isOpen = false;

    item[taskIndex] = tagItem;
    filterArrayList[index] = item;

    this.filterArrayList = filterArrayList;

    this.getCustomerCount();
  }

  public handleTaskTypeSelect(regionId) {
    this.saleRegionList = this.saleRegionList.map((i: any) => {
      i.isSelected = i.regionId === regionId ? !i.isSelected : false;
      return i;
    });

    const select = this.handleChangeStatus(this.saleRegionList, "regionId", regionId);

    if (select.isSelected) {
      this.saleAfterTypeSelect.text = select.regionName;
      this.saleAfterTypeSelect.code = select.regionId;
      this.saleAfterTypeSelect.isOpen = true;
    } else {
      this.saleAfterTypeSelect.text = "";
      this.saleAfterTypeSelect.code = "";
      this.saleAfterTypeSelect.isOpen = true;
    }
    this.getCustomerCount();
  }

  public handleTaskSelect(val) {
    this.taskTypeCustomerStatus = this.taskTypeCustomerStatus.map((i: any) => {
      i.isSelected = i.val === val ? !i.isSelected : false;
      return i;
    });

    const select = this.handleChangeStatus(this.taskTypeCustomerStatus, "val", val);

    if (select.isSelected) {
      this.taskTypeSelect.text = select.text;
      this.taskTypeSelect.code = select.val;
      this.taskTypeSelect.isOpen = true;
    } else {
      this.taskTypeSelect.text = "";
      this.taskTypeSelect.code = "";
      this.taskTypeSelect.isOpen = true;
    }
    this.getCustomerCount();
  }

  public handleSale(id) {
    this.initedSASList = this.initedSASList.map((i) => {
      i.isSelected = i.value === id ? !i.isSelected : false;
      return i;
    });

    const select = this.handleChangeStatus(this.initedSASList, "value", id);

    if (select.isSelected) {
      this.saleAfterSelect.text = select.communicatesResultName;
      this.saleAfterSelect.code = select.value;
      this.saleAfterSelect.isOpen = true;
    } else {
      this.saleAfterSelect.text = "";
      this.saleAfterSelect.code = "none";
      this.saleAfterSelect.isOpen = true;
    }
    this.getCustomerCount();
  }
  public handleGuider(id) {
    this.execGuiderList = this.execGuiderList.map((i) => {
      i.isSelected = i.guiderId === id ? !i.isSelected : false;
      return i;
    });

    const select = this.handleChangeStatus(this.execGuiderList, "guiderId", id);

    if (select.isSelected) {
      this.execSelect.text = select.empName;
      this.execSelect.code = select.guiderId;
      this.execSelect.isOpen = true;
    } else {
      this.execSelect.text = "";
      this.execSelect.code = "";
      this.execSelect.isOpen = true;
    }
    this.getCustomerCount();
  }
  public handleComm(comm) {
    this.commResults = this.commResults.map((i) => {
      i.isSelected = i.communicatesResult === comm ? !i.isSelected : false;
      return i;
    });

    const select = this.handleChangeStatus(this.commResults, "communicatesResult", comm);

    if (select.isSelected) {
      this.commSelect.text = select.communicatesResultName;
      this.commSelect.code = select.communicatesResult;
      this.commSelect.isOpen = true;
    } else {
      this.commSelect.text = "";
      this.commSelect.code = "";
      this.commSelect.isOpen = true;
    }

    this.getCustomerCount();
  }

  public setSelectFilter(list, status) {
    list.forEach((val) => {
      if (val) {
        // tslint:disable-next-line:no-parameter-reassignment
        val = val.map((i) => {
          if (status) {
            i.onIsSelected = i.isSelected;
          } else {
            i.isSelected = i.onIsSelected;
          }
          return i;
        });
      }
    });

    this.filterArrayList = this.filterArrayList.map((i) => {
      i.siganl = false;
      i.isOpen = false;
      i.data = i.data.map((v) => {
        if (status) {
          i.onIsSelected = i.isSelected;
        } else {
          i.isSelected = i.onIsSelected;
        }
        return v;
      });
      return i;
    });
  }

  /**
   *
   * @param list    列表
   * @param status  状态
   */
  public setTopSelect(list, status) {
    // tslint:disable-next-line:no-parameter-reassignment
    list = list.map((i) => {
      if (i) {
        if (status) {
          i.reText = i.text;
          i.reCode = i.code;
          i.text = "";
          i.code = "";
        } else {
          i.text = i.reText;
          i.code = i.reCode;
        }

        return i;
      }
    });
  }
  /**
   * 确定筛选
   * @return null
   */
  public onConfirmFilter(): void {
    this.setSelectStatus();
    const selectedTagsArray = this.filterSelectValue();
    const taskResult = this.commSelect.code || undefined;
    const regionStatus = this.saleAfterSelect.code === "none" ? 9 : +this.saleAfterSelect.code;
    const taskType = this.taskTypeSelect.code || 0;
    const regionId = this.filterSearchFlag ? this.saleAfterTypeSelect.code || 0 : this.curTabIndex;

    this.$emit(
      "selectedTagsArray",
      {
        customerFlag: this.taskTypeCode === "SaleAfterV2" ? undefined : this.filterSearchFlag ? taskType : this.curTabIndex,
        taskResult,
        regionStatus: this.taskTypeCode === "SaleAfterV2" ? regionStatus : undefined,
        regionId: this.taskTypeCode === "SaleAfterV2" && regionId !== "none" ? regionId : undefined,
        tags: selectedTagsArray,
      },
      this.postData,
    );
  }

  /**
   * 重置筛选
   * @return null
   */
  public onResetFilter(): void {
    if (this.saleAfterSelect.isOpen) {
      this.initedSASList = this.initedSASList.map((i) => {
        i.isSelected = false;
        return i;
      });

      this.saleAfterSelect = {
        text: "",
        code: "none",
        isOpen: true,
      };
    }

    if (this.saleAfterTypeSelect.isOpen) {
      this.saleRegionList = this.saleRegionList.map((i) => {
        i.isSelected = false;
        return i;
      });

      this.saleAfterTypeSelect = {
        text: "",
        code: "",
        isOpen: true,
      };
    }
    if (this.commSelect.isOpen) {
      this.commResults = this.commResults.map((i) => {
        i.isSelected = false;
        return i;
      });

      this.commSelect = {
        text: "",
        code: "",
        isOpen: true,
      };
    }

    if (this.taskTypeSelect.isOpen) {
      this.taskTypeCustomerStatus = this.taskTypeCustomerStatus.map((i) => {
        i.isSelected = false;
        return i;
      });

      this.taskTypeSelect = {
        text: "",
        code: "",
        isOpen: true,
      };
    }

    if (this.execSelect.isOpen) {
      this.execGuiderList = this.execGuiderList.map((i) => {
        i.isSelected = false;
        return i;
      });

      this.execSelect = {
        text: "",

        code: "",
        isOpen: true,
      };
    }

    this.filterArrayList = this.filterArrayList.map((i) => {
      if (i.isOpen) {
        i.data.map((val) => {
          val.isSelected = false;
        });

        i.siganl = false;
        i.selectText = null;
      }
      return i;
    });

    this.getCustomerCount();
  }

  public getCustomerCount() {
    const selectedTagsArray = this.filterSelectValue();
    const taskResult = this.commSelect.code || undefined;
    const regionStatus = this.saleAfterSelect.code === "none" ? 9 : this.saleAfterSelect.code;
    const taskType = this.taskTypeSelect.code || 0;
    const regionId = this.filterSearchFlag ? this.saleAfterTypeSelect.code || 0 : this.curTabIndex;

    this.custNumber = 0;
    this.postData = true;

    // 售后个普通任务不同
    if (this.taskTypeCode !== "SaleAfterV2") {
      // 是不是搜索
      if (!this.filterSearchFlag) {
        // 选择的标签 和是不是所有导购均可执行
        if (!selectedTagsArray.length && !(this.curTabIndex !== 4 ? taskResult : true)) {
          this.postData = false;
        }
        // 是搜索，就要加上沟通状态的判断，逻辑和上面相同
      } else {
        if (!selectedTagsArray.length && !taskResult && !taskType) {
          this.postData = false;
        }
      }
      // 如果是售后
    } else {
      // 是不是搜索
      if (!this.filterSearchFlag) {
        // 选择的标签 和是不是所有导购均可执行
        if (!selectedTagsArray.length && regionStatus === 9) {
          this.postData = false;
        }
        // 是搜索，就要加上沟通状态的判断，逻辑和上面相同
      } else {
        if (!selectedTagsArray.length && regionStatus === 9 && !this.saleAfterTypeSelect.code) {
          this.postData = false;
        }
      }
    }

    const param = {
      customerFlag: this.taskTypeCode === "SaleAfterV2" ? undefined : this.filterSearchFlag ? taskType : this.curTabIndex,
      taskId: this.taskId,
      siteCode: this.siteCode,
      guiderId: this.guiderId,
      execGuiderId: this.execGuiderId,
      taskResult,
      regionStatus: this.taskTypeCode === "SaleAfterV2" ? regionStatus : undefined,
      regionId: this.taskTypeCode === "SaleAfterV2" && regionId !== "none" ? regionId : undefined,
      tags: selectedTagsArray,
      keywords: this.keywords,
      requestType: 2,
    };
    DZMainService.taskCustomerCount(param).then((res) => {
      this.custNumber = res.content.custNumber;
    });
  }

  /**
   * 阻止页面滚动
   * @return null
   */
  public onStopPageScroll() {
    return;
  }
}
