import { Component, Vue } from "vue-property-decorator";
import _Template from "./style.vue";
import TaskTrackItem from "@/components/taskTrackItem";
import DzTabs from "@/components/dzTabs";
import service from "@/service";
import DzMenu from "@/components/dzMenu";
import { QinggerLibURL } from "qingger-lib";
@Component({
  mixins: [_Template],
  components: { DzTabs, TaskTrackItem, DzMenu },
})
export default class TaskTrack extends Vue {
  protected isMainMenuWrapperShow: boolean = false;
  protected curTabIndex: number = 1; // 当前选中的tabIndex，也是taskStatus，任务列表状态 ：1：进行中，4：已中止，3：已结束。不填默认取 1-进行中
  protected taskType: number = 2; // 任务所属 ： 1：店铺参与，2：本人(当前导购)参与，3：本人(当前导购)发布

  protected taskList: ITaskTrackListItem[] = [];
  protected taskTrackList: any[] = [];
  protected tabs: { label: string; value: number }[] = [
    {
      label: "进行中",
      value: 1,
    },
    // {
    //   label: "已中止",
    //   value: 4,
    // },
    {
      label: "已结束",
      value: 3,
    },
  ];
  protected menuItems: { label: string; value: number }[] = [
    {
      label: "店铺任务",
      value: 1,
    },
    {
      label: "我的任务",
      value: 2,
    },
    // {
    //   label: "我发布的任务",
    //   value: 3,
    // },
  ];

  /**
   * 当前用户身份  // 102:导购，101：店长
   * @type {string}
   */
  protected guiderPost: string = "102";
  protected guiderId: string = "";
  protected siteCode: string = "";
  protected empCode: string = "";
  protected observe: string = "0";
  /**
   * 分页使用的数据
   * @type {number}
   */
  protected page = 1;
  protected pageSize = 30;
  protected isScroll: boolean = true; // 是否还能滚动
  protected isSearchedListEnd: boolean = false;
  protected listLoading: boolean = false;
  protected scrollFunction = this.debounce(this.scrollFunc, 250); // 注册事件方法

  protected taskGroupListLength: number = 0;
  protected taskGroupList = [];

  // tab 点击事件
  protected onTabClick(e) {
    console.log(e);
    this.curTabIndex = +e;
    this.clearData();
    this.getTaskListGroup();
    this.getTaskTrackList();
  }
  // TaskTrackItem 中点击中止和重启后，刷新当前tab
  protected onRefreshTab() {
    this.clearData();
    this.getTaskTrackList();
  }
  // 菜单 点击事件
  protected onMenuItemClick(e) {
    this.isMainMenuWrapperShow = false;
    this.taskType = +e;
    this.clearData();
    if (e !== 3) {
      this.getTaskListGroup();
    }
    this.getTaskTrackList();
  }

  // 获取数据
  protected getTaskTrackList(formScroll?: boolean) {
    this.listLoading = true;
    // 修复滚动条到底部后显示加载中
    if (formScroll) {
      setTimeout(() => {
        const scrollDiv = this.$el.querySelector(".track-list");
        const clientHeight = scrollDiv.clientHeight;
        const scrollHeight = scrollDiv.scrollHeight;
        console.log(scrollDiv.scrollTop, scrollHeight - clientHeight - 1);
        scrollDiv.scrollTop = scrollHeight - clientHeight - 1;
      });
    }
    const params = {
      empCode: undefined,
      siteCode: this.siteCode,
      guiderId: this.guiderId,
      taskStatus: this.curTabIndex,
      taskType: this.taskType,
      page: this.page,
      pageSize: this.pageSize,
    };
    service
      .taskTrackList(params)
      .then((resp) => {
        if (resp.code === 20000 && resp.content) {
          if (resp.content.list.length === 0 && this.page !== 1) {
            this.isSearchedListEnd = true;
            this.isScroll = false;
          } else {
            // 修复连续切换tab时，加载数据出错问题
            if (this.page === 1 || params.page === 1) {
              this.page = 1;
              this.taskList = resp.content.list;
            } else {
              this.taskList = this.taskList.concat(resp.content.list);
            }
            this.isScroll = true;
            this.page++;
          }
        }
      })
      .finally(() => (this.listLoading = false));

    // const trackParams = {
    //   siteCode: this.siteCode,
    //   guiderId: this.guiderId,
    //   taskStatus: this.curTabIndex,
    //   taskTypeCode: "CustomerStaticV2"
    // };
    // service
    //   .taskReportTrackList(trackParams)
    //   .then(resp => {
    //     if (resp.code === 20000 && resp.content) {
    //       this.taskTrackList = resp.content.list;
    //     }
    //   })
    //   .finally(() => (this.listLoading = false));
  }

  /*清除数据*/
  protected clearData() {
    this.page = 1;
    this.isScroll = true;
    this.isSearchedListEnd = false;
    this.taskList = [];
    this.taskGroupList = [];
  }

  // 防抖动函数
  protected debounce(cb, waitTime, immediate?) {
    let timeout;
    return function() {
      const args = arguments;
      const later = () => {
        timeout = null;
        if (!immediate) {
          cb.apply(this, args);
        }
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, waitTime);
      if (callNow) {
        cb.apply(this, args);
      }
    };
  }

  // 监听滚动条事件
  protected scrollFunc(e) {
    const scrollDiv = this.$el.querySelector(".track-list");
    const clientHeight = scrollDiv.clientHeight;
    const scrollTop = scrollDiv.scrollTop;
    const scrollHeight = scrollDiv.scrollHeight;
    console.log(scrollTop, clientHeight, scrollHeight);
    if (scrollTop + clientHeight + 100 > scrollHeight && this.isScroll) {
      console.log("到底部了！");
      this.isScroll = false;
      this.getTaskTrackList(true);
    }
  }
  protected onload() {
    document.title = "任务跟踪";

    const url = window.location.href;
    const urlLibUtil = QinggerLibURL.urlUtil(url);
    const tokenFromQuery = urlLibUtil.getUrlQueryItem("token");
    const siteFromQuery = urlLibUtil.getUrlQueryItem("siteCode");
    const guiderFromQuery = urlLibUtil.getUrlQueryItem("guiderId");
    const empFromQuery = urlLibUtil.getUrlQueryItem("empCode");
    const siteRoleQuery = urlLibUtil.getUrlQueryItem("siteRole");
    const observeQuery = urlLibUtil.getUrlQueryItem("observe");

    if (tokenFromQuery) {
      window.localStorage.setItem("token", tokenFromQuery || "token-1");
    }
    if (siteFromQuery) {
      window.localStorage.setItem("siteCode", siteFromQuery || "");
    }
    if (guiderFromQuery) {
      window.localStorage.setItem("guiderId", guiderFromQuery || "");
    }
    if (empFromQuery) {
      window.localStorage.setItem("empCode", empFromQuery || "");
    }
    if (siteRoleQuery) {
      window.localStorage.setItem("siteRole", siteRoleQuery || "");
    }
    if (observeQuery) {
      window.localStorage.setItem("observe", observeQuery || "");
    }
    // 当token失效时，可能已经有多个请求已同时发出。通过此属性，可以判断此token是否短期内被更新过，防止重复更新token
    window.localStorage.setItem("tokenTime", `${new Date().valueOf()}`);

    // 设置是否是导购
    window.window.localStorage.setItem("isGuider", "1");
    // setTimeout(() => {
    //   $('body').show();
    // }, 100);
  }

  protected getTaskListGroup() {
    const params = {
      empCode: undefined,
      siteCode: this.siteCode,
      guiderId: this.guiderId,
      taskStatus: 1,
      taskType: this.taskType,
    };
    return service.taskTrackListGroup(params).then((res) => {
      console.log(res.content.list);
      this.taskGroupList = res.content.list.map((i) => {
        i.isOpen = true;
        return i;
      });
      this.taskGroupListLength = res.content.list.length;
    });
  }

  protected mounted() {
    this.onload();
    this.guiderPost = window.localStorage.getItem("siteRole");
    this.siteCode = window.localStorage.getItem("siteCode");
    this.guiderId = window.localStorage.getItem("guiderId");
    this.empCode = window.localStorage.getItem("empCode");
    this.observe = window.localStorage.getItem("observe");
    this.taskType = this.guiderPost === "101" ? 1 : 2;
    console.log("mounted");
    this.getTaskListGroup().then(() => {
      this.getTaskTrackList();
      service.dzGroupCommonConfig({}).then((res) => {
        console.log(res);
        window.localStorage.setItem("isHandlePhone", res.content.handlePhone);
      });
    });

    // 绑定监听
    this.$el
      .querySelector(".track-list")
      .addEventListener("scroll", this.scrollFunction);
  }

  protected beforeDestroy() {
    this.$el
      .querySelector(".track-list")
      .removeEventListener("scroll", this.scrollFunction);
  }

  protected toTaskDetail(item) {
    if (item.taskTypeCode === "CustomerStaticV2") {
      this.$router.push({
        path: "/customerCount",
        query: { taskId: item.taskId, status: `${this.curTabIndex}` },
      });

      return;
    }
    if (item.taskTypeCode === "SaleAfterV2") {
      this.$router.push({
        path: "/AfterSale",
        query: { taskId: item.taskId, status: `${this.curTabIndex}` },
      });

      return;
    }
    this.$router.push({
      path: "/TaskCenter",
      query: { taskId: item.taskId, status: `${this.curTabIndex}` },
    });
  }

  protected render(h) {
    let headerName = "我的任务";
    if (this.guiderPost === "101") {
      const menuItem = this.menuItems.find((d) => d.value === this.taskType);
      headerName = menuItem ? menuItem.label : "店铺任务";
    }
    return (
      <div class="task-track">
        <div class="track-header">
          {this.guiderPost === "101" ? (
            [
              <div class="left-name">{headerName}</div>,
              <div
                class="right-btn"
                onClick={() => (this.isMainMenuWrapperShow = true)}
              >
                <span class="iconfont icon-gengduo11"></span>
              </div>,
            ]
          ) : (
            <div class="left-name">{headerName}</div>
          )}
        </div>
        <DzTabs
          tabs={this.tabs}
          curTabIndex={this.curTabIndex}
          onTabClick={(e) => this.onTabClick(e)}
        />
        <div class="track-list" style="margin-top:0.25rem">
          {this.taskGroupListLength > 1 &&
          this.curTabIndex === 1 &&
          this.taskType !== 3 ? (
            <div class="task-group-list">
              {this.taskGroupList.map((val, ind) => {
                return (
                  <div class="track-list-content" key={ind}>
                    <div
                      class="last-title"
                      style={{
                        borderBottom: val.isOpen ? "none" : "1px solid #eee",
                        marginBottom: val.isOpen ? "10px" : "20px",
                      }}
                      onClick={() => {
                        this.taskGroupList = this.taskGroupList.map(
                          (value, index, array) => {
                            if (index === ind) {
                              value.isOpen = !value.isOpen;
                            }
                            return value;
                          }
                        );
                      }}
                    >
                      {val.groupName}
                      {val.isOpen ? (
                        <i class="iconfont icon-less" />
                      ) : (
                        <i class="iconfont icon-moreunfold" />
                      )}
                    </div>
                    {val.isOpen
                      ? val.taskList.map((d, index) => {
                          return (
                            <TaskTrackItem
                              key={index}
                              taskTrackDetail={d}
                              guiderId={this.guiderId}
                              toDetail={() => {
                                this.toTaskDetail(d);
                              }}
                              curTabIndex={this.curTabIndex}
                              showItems={
                                this.curTabIndex === 1 &&
                                this.guiderPost === "101"
                              }
                              empCode={this.empCode}
                              onRefreshTab={() => this.onRefreshTab()}
                              class={{
                                "track-last-item":
                                  index === this.taskList.length - 1,
                              }}
                            />
                          );
                        })
                      : ""}
                  </div>
                );
              })}
            </div>
          ) : (
            <div class="track-list-content">
              {this.taskList.map((d, index) => {
                return (
                  <TaskTrackItem
                    key={index}
                    taskTrackDetail={d}
                    guiderId={this.guiderId}
                    toDetail={() => {
                      this.toTaskDetail(d);
                    }}
                    curTabIndex={this.curTabIndex}
                    showItems={
                      this.curTabIndex === 1 && this.guiderPost === "101"
                    }
                    empCode={this.empCode}
                    onRefreshTab={() => this.onRefreshTab()}
                    class={{
                      "track-last-item": index === this.taskList.length - 1,
                    }}
                  />
                );
              })}
            </div>
          )}

          <div class="loading-wrap" v-show={this.listLoading}>
            - 加载中 -
          </div>
          <div
            v-show={
              this.taskList.length === 0 && this.taskGroupListLength === 0
            }
            class="taskList-noData"
          >
            <div class="noData">
              <i class="iconfont icon-kongshuju"></i>
              <div class="txt">暂无数据</div>
            </div>
          </div>
          <div class="noListItem" v-show={this.isSearchedListEnd}>
            ~ 这是我的底线 ~
          </div>
        </div>
        <DzMenu
          v-model={this.isMainMenuWrapperShow}
          menu-items={this.menuItems}
          onMenuItemClick={(e) => this.onMenuItemClick(e)}
        />
      </div>
    );
  }
}
