import Vue from "vue";
export default function(DefaultComponent) {
  return ({ className, class: klass, style, component, root, parent, ...props }: any) => {
    let vm = null;
    if (klass !== void 0) {
      props.cardClass = klass;
    }
    if (style !== void 0) {
      props.cardStyle = style;
    }

    const okFns = [];
    const cancelFns = [];
    const beforeFns = [];
    const clickFns = [];
    const API = {
      onBefore(fn) {
        beforeFns.push(fn);
        return API;
      },
      onClick(fn) {
        clickFns.push(fn);
        return API;
      },
      onOk(fn) {
        okFns.push(fn);
        return API;
      },
      onCancel(fn) {
        cancelFns.push(fn);
        return API;
      },
      onDismiss(fn) {
        okFns.push(fn);
        cancelFns.push(fn);
        return API;
      },
      hide() {
        (vm.$refs.dialog as any).hide();
        return API;
      },
    };

    const node = document.createElement("div");
    document.body.appendChild(node);

    let emittedOK = false;

    const on = {
      before: (name, e) => {
        if (!beforeFns.length) {
          if (e && e.done) {
            e.done();
          }
          if (e && e.next) {
            e.next();
          }
        }
        beforeFns.forEach((fn) => {
          if (typeof fn === "function") {
            fn(name, e, { dlg: vm.$refs.dialog, content: vm.$refs.dialog.$refs.contentRef });
          }
        });
      },
      click: (name, e) => {
        clickFns.forEach((fn) => {
          if (typeof fn === "function") {
            fn(name, e);
          }
        });
      },
      ok: (data) => {
        emittedOK = true;
        okFns.forEach((fn) => {
          if (typeof fn === "function") {
            fn(data, { dlg: vm.$refs.dialog, content: vm.$refs.dialog.$refs.contentRef });
          }
        });
      },

      hide: () => {
        vm.$destroy();
        vm.$el.remove();
        vm = null;

        if (emittedOK !== true) {
          cancelFns.forEach((fn) => {
            if (typeof fn === "function") {
              fn();
            }
          });
        }
      },
    };

    Vue.observable(props);
    const DialogComponent = component !== void 0 ? component : DefaultComponent;
    const attrs = component === void 0 ? {} : void 0;
    const p = parent === void 0 ? root : parent;
    if (!p) {
      throw Error("请指定dialog的parent组件 或者 root组件");
    }
    let unwatch = null;
    vm = new Vue({
      name: "QGlobalDialog",

      el: node,
      parent: p,
      render(h) {
        return h(DialogComponent, {
          ref: "dialog",
          props,
          attrs,
          on,
        });
      },
      mounted() {
        this.$refs.dialog.show();
        unwatch = p.$root.$watch("$route", () => {
          this.$refs.dialog.hide();
        });
      },
      beforeDestroy() {
        try {
          unwatch();
        } catch (error) {
          console.info(error);
        }
      },
    });

    return API;
  };
}
