var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"task-centered"},[_c('div',{staticClass:"tabs-wrap"},[_c('DzTabs',{attrs:{"tabs":_vm.tabs,"curTabIndex":_vm.curTabIndex},on:{"tabClick":_vm.onCommunicateInfoListSwitch}}),_c('div',{staticClass:"search-icon"},[_c('i',{staticClass:"iconfont icon-sousuo",on:{"click":_vm.openSearchElement}})]),_c('div',{staticClass:"search-el",style:({ width: _vm.searchWidth })},[_c('i',{staticClass:"iconfont icon--Back search-icon-back",on:{"click":_vm.closeSearchElement}}),_c('i',{staticClass:"iconfont icon-search search-icon-seac"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keywords),expression:"keywords"}],staticClass:"search-input",attrs:{"type":"text","placeholder":"顾客姓名/微信昵称/手机号"},domProps:{"value":(_vm.keywords)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchHandle.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.keywords=$event.target.value}}}),(_vm.keywords)?_c('i',{staticClass:"keywordsClear iconfont icon-close",on:{"click":function($event){_vm.keywords = ''}}}):_vm._e()])],1),(_vm.searchFlag)?_c('div',{staticClass:"search-wrap"}):_vm._e(),_c('div',{staticClass:"cont-num"},[_c('div',{staticClass:"cont-num-wrap"},[_c('span',[_vm._v("共 "+_vm._s(_vm.count)+" 人")]),_c('div',{staticClass:"sort-item-wrap"},_vm._l((_vm.sortItem),function(item,index){return _c('span',{key:index,staticClass:"sort-item",class:{ 'sort-item-active': item.active },on:{"click":function($event){return _vm.handleSortItem(item, index)}}},[_vm._v(" "+_vm._s(item.text)+" "),_c('i',{staticClass:"iconfont sort-icon",class:[
              item.sort
                ? item.sort === 'desc'
                  ? 'icon-paixuxiajiantou'
                  : 'icon-paixushangjiantou'
                : 'icon-paixumoren',
            ]})])}),0),_c('span',{staticClass:"filter",class:{ filterSelectColor: _vm.isFilterSelect },on:{"click":function($event){_vm.isFilterShow = !_vm.isFilterShow}}},[_c('i',{staticClass:"iconfont icon-shaixuan"}),_vm._v(" 筛选 ")])]),_c('TaskFilter',{attrs:{"taskId":_vm.taskId,"siteCode":_vm.siteCode,"guiderId":_vm.guiderId,"keywords":_vm.keywords,"curTabIndex":_vm.curTabIndex,"taskTypeCode":_vm.taskTypeCode,"isFilterShow":_vm.isFilterShow,"execGuiderId":_vm.execGuiderId,"setExecGuiderList":_vm.execGuiderList,"filterSearchFlag":_vm.filterSearchFlag,"communicatesResultsValue":_vm.communInfo.communicatesResults},on:{"selectedTagsArray":_vm.selectedTagsArray}})],1),_c('div',{staticClass:"con-list"},[_vm._l((_vm.customerList),function(item,index){return (_vm.count)?_c('div',{key:index},[_c('div',{staticClass:"item clearfix",class:{ changedItem: item.isChanged }},[_c('div',{staticClass:"customerAvatar",style:({ backgroundImage: 'url(' + item.avatar + ')' }),on:{"click":function($event){return _vm.goCostomerCenter(item.cstId)}}}),_c('div',{staticClass:"center",on:{"click":function($event){return _vm.showDetail(item)}}},[_c('div',{staticClass:"nick",staticStyle:{"overflow":"hidden"}},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(item.ruleName))]),_c('span',{staticClass:"talkedTag"},[(
                  item.mySiteCode === _vm.siteCode && item.myGuider === _vm.guiderId
                )?_c('span',{staticClass:"memberLabel",staticStyle:{"background":"#ed513f"}},[_vm._v("我的会员")]):(
                  item.mySiteCode === _vm.siteCode && item.myGuider !== _vm.guiderId
                )?_c('span',{staticClass:"memberLabel",staticStyle:{"background":"#211bc3"}},[_vm._v("本店会员")]):(item.mySiteCode !== _vm.siteCode)?_c('span',{staticClass:"memberLabel",staticStyle:{"background":"#21c38c"}},[_vm._v("非本店会员")]):_vm._e()])]),_c('div',{staticClass:"notes"},[_vm._v(" "+_vm._s(item.phone ? _vm.mobileNumberChange(item.phone) : "暂无手机号")+" ")]),_c('div',{staticClass:"notes"},[(_vm.changeSortItemValue)?_c('span',{staticClass:"notes-item"},[_c('i',{staticClass:"iconfont",class:_vm.changeSortItemValue.icon,style:({ color: _vm.changeSortItemValue.color })}),_vm._v(" "+_vm._s(item[_vm.changeSortItemValue.getCode] ? _vm.changeSortItemValue.getCode === "totalNumsFor365Days" ? "近一年消费" + item[_vm.changeSortItemValue.getCode] + "次" : item[_vm.changeSortItemValue.getCode] : _vm.changeSortItemValue.tip)+" ")]):_vm._e(),(
                !(_vm.filterSearchFlag && item.isPay) &&
                !(_vm.filterSearchFlag && !item.isFollow && item.execTime)
              )?_c('span',{staticClass:"notes-item"},[_c('i',{staticClass:"iconfont icon-biaoqian-yigoutong",staticStyle:{"color":"#21c38c"}}),_vm._v(" "+_vm._s(item.execExplain)+" ")]):_vm._e(),(_vm.filterSearchFlag && item.isPay)?_c('span',{staticClass:"notes-item"},[_c('i',{staticClass:"iconfont icon-radiochecked",staticStyle:{"color":"#21c38c"}}),_vm._v(" 已买单 ")]):(_vm.filterSearchFlag && !item.isFollow && item.execTime)?_c('span',{staticClass:"notes-item"},[_c('i',{staticClass:"iconfont icon-biaoqian-bugenjin",staticStyle:{"color":"#666666"}}),_vm._v(" 不跟进 ")]):_vm._e()])]),_c('div',{staticClass:"right relative",on:{"click":function($event){return _vm.showDetail(item)}}},[_c('div',{staticClass:"right-container"},[(item.execState == 1)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                (_vm.curTabIndex === 4 ||
                  _vm.curTabIndex === 5 ||
                  _vm.curTabIndex === 6) &&
                item.execResult !== '沟通中'
              ),expression:"\n                (curTabIndex === 4 ||\n                  curTabIndex === 5 ||\n                  curTabIndex === 6) &&\n                item.execResult !== '沟通中'\n              "}],staticClass:"colorGreen"},[_vm._v(_vm._s(item.execResult))]):(item.execState == 5)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                (_vm.curTabIndex === 4 ||
                  _vm.curTabIndex === 5 ||
                  _vm.curTabIndex === 6) &&
                item.execResult !== '沟通中'
              ),expression:"\n                (curTabIndex === 4 ||\n                  curTabIndex === 5 ||\n                  curTabIndex === 6) &&\n                item.execResult !== '沟通中'\n              "}],staticClass:"colorYellow"},[_vm._v(_vm._s(item.execResult))]):(
                (item.execState === 2 && item.execResult) ||
                item.execState === 3
              )?_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                (_vm.curTabIndex === 4 ||
                  _vm.curTabIndex === 5 ||
                  _vm.curTabIndex === 6) &&
                item.execResult !== '沟通中'
              ),expression:"\n                (curTabIndex === 4 ||\n                  curTabIndex === 5 ||\n                  curTabIndex === 6) &&\n                item.execResult !== '沟通中'\n              "}],staticClass:"colorGrey2"},[_vm._v(_vm._s(item.execResult))]):(item.execState == 9)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                (_vm.curTabIndex === 4 ||
                  _vm.curTabIndex === 5 ||
                  _vm.curTabIndex === 6) &&
                item.execResult !== '沟通中'
              ),expression:"\n                (curTabIndex === 4 ||\n                  curTabIndex === 5 ||\n                  curTabIndex === 6) &&\n                item.execResult !== '沟通中'\n              "}],staticClass:"colorRed"},[_vm._v(_vm._s(item.execResult))]):_c('span',[_vm._v(" ")]),(item.execGuiderName && _vm.guiderPost !== '102')?_c('div',{staticClass:"guider"},[_vm._v(" "+_vm._s(item.execGuiderName)+" ")]):_vm._e()])])])]):_vm._e()}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.listLoading),expression:"listLoading"}],staticClass:"loading-wrap"},[_vm._v("- 加载中 -")]),(!_vm.customerList || _vm.customerList.length === 0)?_c('div',{staticClass:"customerList-noData"},[_vm._m(0)]):_vm._e(),(_vm.isSearchedListEnd)?_c('div',{staticClass:"noListItem"},[_vm._v("~ 这是我的底线 ~")]):_vm._e()],2),(_vm.ExecutiveDetailsFlag)?_c('ExecutiveDetails',{attrs:{"execResult":_vm.listItem.execResult,"taskTypeCode":_vm.taskTypeCode,"tabs":_vm.tabs,"taskId":_vm.taskId,"item":_vm.listItem,"isAllGuider":_vm.isAllGuider},on:{"cancel":function($event){_vm.ExecutiveDetailsFlag = !_vm.ExecutiveDetailsFlag}}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"noData"},[_c('i',{staticClass:"iconfont icon-kongshuju"}),_c('div',{staticClass:"txt"},[_vm._v("暂无数据")])])
}]

export { render, staticRenderFns }