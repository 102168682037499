import { Component, Vue, Prop } from "vue-property-decorator";
import _Template from "./style.vue";
import DzMenu from "../dzMenu";
import api from "@/service";
import DzPopup from "../dzPopup";

const TASK_OPT_STRING = {
  STOP: "是否停止该任务",
  DELETE: "是否删除该任务",
  OPEN: "是否开启该任务"
};
@Component({
  mixins: [_Template]
})
export default class TaskTrackItem extends Vue {
  @Prop()
  public taskTrackDetail: ITaskTrackListItem | any;

  // 是否显示导购和合计数据
  @Prop({ default: false })
  public showItems: boolean;

  // 雇员ID
  @Prop()
  public empCode: string;

  // goto detail
  @Prop()
  toDetail: any;

  @Prop()
  guiderId: string;

  // 任务状态
  @Prop()
  curTabIndex: number;

  protected isMainMenuWrapperShow: boolean = false;
  protected popupVisible: boolean = false; // 确认弹框
  protected selectMenuValue: number = null; // 点击菜单的值，用于确认弹框结束后 call
  protected showMore: boolean = false; // 是否展开更多
  protected showMoreLen: number = 2; // 默认显示几个

  protected stopMenuItems: { label: string; value: number }[] = [
    {
      label: "中止任务",
      value: 0
    }
  ];
  protected restartMenuItems: { label: string; value: number }[] = [
    {
      label: "重启任务",
      value: 1
    }
  ];

  protected changeMenuShow(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.isMainMenuWrapperShow = !this.isMainMenuWrapperShow;
  }
  // 菜单 点击事件
  protected onMenuItemClick(value, item, event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.selectMenuValue = value;
    this.popupVisible = true;
  }

  // 确认弹框 点击确认和取消事件
  protected onClose(result) {
    if (result) {
      const siteCode = window.localStorage.getItem("siteCode");
      const guiderId = window.localStorage.getItem("guiderId");
      const postParams = {
        siteCode,
        guiderId,
        taskId: this.taskTrackDetail.taskId,
        status: this.selectMenuValue
      };
      api.taskStatusUpdate(postParams).then(resp => {
        if (
          resp &&
          resp.code === 20000 &&
          resp.content &&
          resp.content.result
        ) {
          this.$emit("refreshTab");
        }
        this.isMainMenuWrapperShow = !this.isMainMenuWrapperShow;
      });
    } else {
      this.isMainMenuWrapperShow = !this.isMainMenuWrapperShow;
    }
    this.selectMenuValue = null;
  }

  get headerDesc() {
    if (this.taskTrackDetail) {
      // 任务来源  1后台 2前台
      if (this.taskTrackDetail.taskSource === 1) {
        return { text: "公司任务", color: "#21C38C" };
      } else if (this.taskTrackDetail.taskSource === 2) {
        return this.guiderId === this.taskTrackDetail.createdEmpCode
          ? { text: "本人发布", color: "#ED513F" }
          : { text: "本店发布", color: "#211BC3" };
      }
      return "";
    }
    return "";
  }

  protected mounted() {
    //
  }
  protected render(h) {
    return (
      <div
        class="task-track-item"
        onClick={() => this.toDetail()}
        style={{
          marginTop:
            this.taskTrackDetail.taskTypeCode === "CustomerStaticV2"
              ? "10px"
              : ""
        }}
      >
        <div class="header-row">
          <div class="header-img">
            <img
              style="width:100%;height:100%;"
              mode="aspectFit"
              src={this.taskTrackDetail.taskTypeImage}
              alt=""
            />
          </div>
          <div class="header-name">
            <div class="header-title">{this.taskTrackDetail.taskName}</div>
            <div class="header-content">
              <span class="header-date">
                {this.taskTrackDetail.taskTypeCode !== "CustomerStaticV2"
                  ? `${this.taskTrackDetail.fmtBeginInDay} ~ ${this.taskTrackDetail.fmtEndInDay}`
                  : "长期任务"}
              </span>
              {!!this.headerDesc && (
                <span
                  class="header-desc"
                  style={{
                    backgroundColor: this.headerDesc.color
                  }}
                >
                  {this.headerDesc.text}
                </span>
              )}
            </div>
          </div>
            <i class="icon-right icon-xiangyou iconfont"></i>
        </div>
        <div v-show={this.showItems}>
          <div class="track-item">
            <div class="item-name sum-color">本店合计</div>
            {this.taskTrackDetail.taskTypeCode === "SaleAfterV2" ? (
              <div class="item-content">
                <div class="item-col col-width-three">
                  <div class="col-num mark-color">
                    {this.taskTrackDetail.siteVisitedNum || 0}
                  </div>
                  <div class="col-txt">今日回访</div>
                </div>
                <div class="item-col col-width-three">
                  <div class="col-num mark-color">
                    {this.taskTrackDetail.siteWaitVisitNum || 0}
                  </div>
                  <div class="col-txt">等待回访</div>
                </div>
                <div class="item-col  col-width-three">
                  <div class="col-num num-color">
                    {this.taskTrackDetail.siteCumulativeVisitNum || 0}
                    <span class="small-num">人次</span>
                  </div>
                  <div class="col-txt">累计回访</div>
                </div>
              </div>
            ) : (
              <div class="item-content">
                <div class="item-col col-width">
                  <div class="col-num mark-color">
                    {this.taskTrackDetail.siteNewCommunicateNum || 0}
                  </div>
                  <div class="col-txt">今日新沟通</div>
                </div>
                <div class="item-col col-width">
                  <div class="col-num mark-color">
                    {this.taskTrackDetail.siteCommuicateFollowNum || 0}
                  </div>
                  <div class="col-txt">今日跟进</div>
                </div>
                <div class="item-col col-width">
                  <div class="col-num num-color">
                    {this.taskTrackDetail.siteTotalFinishNum || 0}
                  </div>
                  <div class="col-txt">累计沟通</div>
                </div>
                <div class="item-col  col-width">
                  <div class="col-num num-color">
                    {this.taskTrackDetail.siteTotalNum || 0}
                    <span class="link-str">/</span>
                    <span class="small-num">
                      {this.taskTrackDetail.siteSurplusNum || 0}
                    </span>
                  </div>
                  <div class="col-txt">全部/剩余</div>
                </div>
              </div>
            )}
          </div>

          {this.taskTrackDetail.taskTypeCode === "SaleAfterV2"
            ? this.taskTrackDetail &&
              Array.isArray(this.taskTrackDetail.partakeGuiders) &&
              (this.taskTrackDetail.partakeGuiders || []).map((item, index) => {
                if (!this.showMore && this.showMoreLen < index + 1) {
                  return;
                }
                return (
                  <div class="track-item" key={index}>
                    <div class="item-name person-color mark-color">
                      {item.empName}
                    </div>
                    <div class="item-content">
                      <div class="item-col col-width-three">
                        <div class="col-num mark-color">
                          {item.visitedNum || 0}
                        </div>
                        <div class="col-txt">今日回访</div>
                      </div>
                      <div class="item-col col-width-three">
                        <div class="col-num mark-color">
                          {item.waitVisitNum || 0}
                        </div>
                        <div class="col-txt">等待回访</div>
                      </div>
                      <div class="item-col  col-width-three">
                        <div class="col-num num-color">
                          {item.cumulativeVisitNum || 0}
                          <span class="small-num">人次</span>
                        </div>
                        <div class="col-txt">累计回访</div>
                      </div>
                    </div>
                  </div>
                );
              })
            : this.taskTrackDetail &&
              Array.isArray(this.taskTrackDetail.partakeGuiders) &&
              (this.taskTrackDetail.partakeGuiders || []).map((item, index) => {
                if (!this.showMore && this.showMoreLen < index + 1) {
                  return;
                }
                return (
                  <div class="track-item" key={index}>
                    <div class="item-name person-color mark-color">
                      {item.empName}
                    </div>
                    <div class="item-content">
                      <div class="item-col col-width">
                        <div class="col-num mark-color">
                          {item.newCommunicateNum || 0}
                        </div>
                        <div class="col-txt">今日新沟通</div>
                      </div>
                      <div class="item-col col-width">
                        <div class="col-num mark-color">
                          {item.commuicateFollowNum || 0}
                        </div>
                        <div class="col-txt">今日跟进</div>
                      </div>
                      <div class="item-col col-width">
                        <div class="col-num num-color">
                          {item.totalFinishNum || 0}
                        </div>
                        <div class="col-txt">累计沟通</div>
                      </div>
                      {this.taskTrackDetail.taskGuiderType !== "AllGuider" && (
                        <div class="item-col col-width">
                          <div class="col-num num-color">
                            {item.totalNum || 0}
                            <span class="link-str">/</span>
                            <span class="small-num">
                              {item.surplusNum || 0}
                            </span>
                          </div>
                          <div class="col-txt">全部/剩余</div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}

          {this.taskTrackDetail &&
          this.taskTrackDetail.partakeGuiders &&
          (this.taskTrackDetail.partakeGuiders.length || []) > 2 ? (
            <p
              class="more"
              onClick={event => {
                event.stopPropagation();
                this.showMore = !this.showMore;
              }}
            >
              <span class="date-text" id="guiderSelect">
                {this.showMore ? "收起" : "展开"}
              </span>
              {this.showMore ? (
                <i class="iconfont icon-less" />
              ) : (
                <i class="iconfont icon-moreunfold" />
              )}
            </p>
          ) : (
            ""
          )}
        </div>
        <DzMenu
          v-model={this.isMainMenuWrapperShow}
          menuItems={
            this.curTabIndex === 1 ? this.stopMenuItems : this.restartMenuItems
          }
          onMenuItemClick={(value, item, event) =>
            this.onMenuItemClick(value, item, event)
          }
        />
        <DzPopup
          v-model={this.popupVisible}
          info={this.curTabIndex === 1 ? "是否停止该任务" : "是否开启该任务"}
          onClose={e => this.onClose(e)}
        />
      </div>
    );
  }
}
