import { Component, Vue } from 'vue-property-decorator';
import _Template from './style.vue';
import api from '@/service';
import { QinggerLibURL } from 'qingger-lib';
import DzSelectBox from '@/components/dzSelectBox';
import SvgIcon from '@/components/svg-icon';
import DzInfoDialog from '@/components/dzInfoDialog';
import { numToTHS } from '@/util/utils';
import HelpTips from "@/components/helpTips";
@Component({
  mixins: [_Template]
})
export default class RankingList extends Vue {
  protected guiderPost: string = '102'; // 当前用户身份  // 102:导购，101：店长
  protected guiderId: string = '';
  protected siteCode: string = '';
  protected empCode: string = '';

  // 菜单是否显示
  protected isSettingPageShow: boolean = false; // 区域和品牌设置弹框是否显示
  protected isInfoDialogShow: boolean = false; // 排行榜的帮助信息弹窗
  protected isDetailTipShow: boolean = false; // 关于店铺或者导购色柱下面的注释图例是否展开，默认不展开
  protected isCallAPINoData: boolean = false; // 查询api后，无数据显示特殊样式

  /**
   * 分页使用的数据
   * @type {number}
   */
  protected page = 1;
  protected pageSize = 10;
  protected isScroll: boolean = true; // 是否还能滚动
  protected isSearchedListEnd: boolean = false;
  protected listLoading: boolean = false;
  protected scrollFunction = this.debounce(this.scrollFunc, 250); // 注册事件方法

  protected helpInfoHtml: string = '';

  // 区域范围配置和 品牌范围配置 选中的值,取消时不更新 之前选择的，所以不能用同一变量
  protected selectedSetting: {
    org: { code: string; name: string; isSite: boolean; level: number };
    goods: IReportRankingSiteMainBrand;
  } = null;

  // 选择的区域，默认倒数第二个节点
  protected selectedOrg: {
    code: string;
    name: string;
    isSite: boolean;
    level: number;
  } = null;

  // 品牌范围List
  protected goodsList: IReportRankingSiteMainBrand[] = [
    {
      brandId: null,
      brandName: '全品牌',
      brandCode: null
    }
  ];

  // 选择的商品，默认全品牌
  protected selectedGoods: IReportRankingSiteMainBrand = this.goodsList[0];

  // 选择的地区，数组
  protected areaList: {
    code: string;
    name: string;
    isSite: boolean;
    level: number;
  }[] = [];

  // 我的位置的配置信息，及X Y值【默认值 头部数据百分比	20%，尾部数据百分比	10%】 （1：显示，0：不显示）
  protected config: IMpGroupAchievementConfig = {
    sales_revenue: 0, // 销售收入
    recruit_member: 0, // 招募会员
    recruit_rate: 1, // 招募率
    member_sale_rate: 1, // 会销比
    unit_price: 1, // 客单价
    unit_quantity: 1, // 客单量
    top_value: 20, // 头部百分比
    bottom_value: 10 // 尾部百分比
  };

  // 排行榜List
  protected rankingTitleList: {
    id: number;
    configKey: string;
    name: string;
    title: string;
  }[] = [
    {
      id: 1,
      configKey: 'sales_revenue',
      name: '销售收入',
      title: '销售收入排行榜'
    },
    {
      id: 2,
      configKey: 'recruit_member',
      name: '招募会员',
      title: '招募会员排行榜'
    },
    {
      id: 3,
      configKey: 'recruit_rate',
      name: '招募率',
      title: '招募率排行榜'
    },
    {
      id: 4,
      configKey: 'member_sale_rate',
      name: '会销比',
      title: '会销比排行榜'
    },
    {
      id: 5,
      configKey: 'unit_price',
      name: '客单价',
      title: '客单价排行榜'
    },
    {
      id: 6,
      configKey: 'unit_quantity',
      name: '客单量',
      title: '客单量排行榜'
    }
  ];

  // 默认选择销售收入排行榜
  protected selectedRanking = this.rankingTitleList[0];

  // 店铺和导购 下拉选项
  protected siteGuiderList = [
    {
      empCode: null,
      empName: '店铺',
      guiderId: null
    }
  ];
  // 默认选择 店铺
  protected selectedSiteGuider = this.siteGuiderList[0]; // 默认选择店铺

  // 月份下拉选项 上月/本月, 4:上月；5:本月
  protected monthList = [
    {
      id: 5,
      name: '本月'
    },
    {
      id: 4,
      name: '上月'
    }
  ];
  // 默认选择本月
  protected selectedMonth = this.monthList[0];

  // 排行榜头部显示数据 以及 对应色柱文本宽度
  protected siteInfo: {
    totalNum: number;
    ownerRank: number;
    averageValue: string;
    topResult: string;
    bottomResult: string;
    ownerValue: string;
    topResultWidth: number;
    bottomResultWidth: number;
    ownerValueWidth: number;
  } = {
    totalNum: 0,
    ownerRank: 0,
    averageValue: '',
    topResult: '',
    bottomResult: '',
    ownerValue: '',
    topResultWidth: null,
    bottomResultWidth: null,
    ownerValueWidth: null
  };

  // 列表绑定数据
  protected rankingList: IReportRankingSiteGuiderListItem[] = [];

  // 是不是店长
  get isStoreManager() {
    return this.guiderPost === '101';
  }

  // 是否显示店铺信息还是导购信息，true: 显示店铺类型信息，false: 显示导购类型信息
  get isSiteInfoShow() {
    return this.isStoreManager && !this.selectedSiteGuider.empCode;
  }

  // 获取查询  头部信息 和列表信息参数【列表查询时，多了page，和pageSize查询】
  get queryParam(): IReportRankingSiteGuiderInfoParam {
    const realOrg = this.selectedOrg.isSite
      ? this.areaList.length > 1
        ? this.areaList[this.areaList.length - 2]
        : { code: '', level: null }
      : this.selectedOrg;
    return {
      siteCode: this.siteCode, // 当前登录网点编号
      va: this.selectedMonth.id, // 上月/本月, 4:上月；5:本月
      queryType: this.selectedRanking.id, // 1：销售收入，2：招募会员，3：招募率，4：会销比，5：客单价，6：客单量，默认设置1
      orId: this.selectedOrg.isSite ? undefined : this.selectedOrg.code, // 区域id,选本店 传上一级组织id
      level: this.selectedOrg.isSite ? undefined : this.selectedOrg.level, // 节点层级 选本店 传上一级组织level
      brandCode: this.selectedGoods.brandCode, // 主打品牌code
      empCode: this.isStoreManager
        ? this.selectedSiteGuider.empCode
        : this.empCode, // 雇员编号，默认设置为登录者雇员编号。店长切换为其他雇员时，更换为选择雇员的编号。如果选择查看店铺。则设置为空
      // 导购id，默认设置为登录者导购id。店长切换为其他雇员时，更换为选择雇员的导购id。如果选择查看店铺。则恢复为登录者导购id
      guiderId: this.selectedSiteGuider.guiderId || this.guiderId
    };
  }

  protected mounted() {
    const url = window.location.href;
    const urlLibUtil = QinggerLibURL.urlUtil(url);
    const tokenFromQuery = urlLibUtil.getUrlQueryItem('token');
    const siteFromQuery = urlLibUtil.getUrlQueryItem('siteCode');
    const guiderFromQuery = urlLibUtil.getUrlQueryItem('guiderId');
    const empFromQuery = urlLibUtil.getUrlQueryItem('empCode');
    const siteRoleQuery = urlLibUtil.getUrlQueryItem('siteRole');
    const taskIdQuery = urlLibUtil.getUrlQueryItem('taskId');

    if (tokenFromQuery) {
      window.localStorage.setItem('token', tokenFromQuery || 'token-1');
    }
    if (siteFromQuery) {
      window.localStorage.setItem('siteCode', siteFromQuery || '');
    }
    if (guiderFromQuery) {
      window.localStorage.setItem('guiderId', guiderFromQuery || '');
    }
    if (empFromQuery) {
      window.localStorage.setItem('empCode', empFromQuery || '');
    }
    if (siteRoleQuery) {
      window.localStorage.setItem('siteRole', siteRoleQuery || '');
    }
    // 当token失效时，可能已经有多个请求已同时发出。通过此属性，可以判断此token是否短期内被更新过，防止重复更新token
    window.localStorage.setItem('tokenTime', `${new Date().valueOf()}`);

    // 设置是否是导购
    window.window.localStorage.setItem('isGuider', '1');
    this.guiderPost = window.localStorage.getItem('siteRole');
    this.siteCode = window.localStorage.getItem('siteCode');
    this.guiderId = window.localStorage.getItem('guiderId');
    this.empCode = window.localStorage.getItem('empCode');
    this.init();
    // 绑定监听
    this.$el
      .querySelector('.ranking-content')
      .addEventListener('scroll', this.scrollFunction);
  }

  protected beforeDestroy() {
    this.$el
      .querySelector('.ranking-content')
      .removeEventListener('scroll', this.scrollFunction);
  }

  /*清除数据*/
  protected clearData() {
    this.page = 1;
    this.isScroll = true;
    this.isSearchedListEnd = false;
    this.listLoading = false;
    this.rankingList = [];
    this.isCallAPINoData = false;
  }

  // 防抖动函数
  protected debounce(cb, waitTime, immediate?) {
    let timeout;
    return function() {
      const args = arguments;
      const later = () => {
        timeout = null;
        if (!immediate) {
          cb.apply(this, args);
        }
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, waitTime);
      if (callNow) {
        cb.apply(this, args);
      }
    };
  }

  // 监听滚动条事件
  protected scrollFunc(e) {
    const scrollDiv = this.$el.querySelector('.ranking-content');
    const clientHeight = scrollDiv.clientHeight;
    const scrollTop = scrollDiv.scrollTop;
    const scrollHeight = scrollDiv.scrollHeight;
    if (scrollTop + clientHeight + 100 > scrollHeight && this.isScroll) {
      this.isScroll = false;
      this.getReportRankingSiteGuiderList(true);
    }
  }

  // 初始化获取数据
  protected init() {
    this.clearData();
    if (!this.isStoreManager) {
      this.selectedSiteGuider = {
        empCode: this.empCode,
        empName: '', // 名称在this.getGuiderList(); 方法中设置
        guiderId: this.guiderId
      };
    }
    this.getGuiderList();
    this.getAchievementConfig();
    this.getSiteMainBrand();
    this.getSiteRegionList().then(() => {
      this.getRankingSiteGuiderInfo();
      this.getReportRankingSiteGuiderList();
    });
    this.getextendInfo();
  }

  // 额外配置信息
  protected getextendInfo() {
    api.extendInfo().then(res => {
      const extendIn = res.content.functional_module_desc.Ranking;
      this.helpInfoHtml = extendIn.replace(/<br>/g, '</br>');
    });
  }

  // 导购、月份、区域、品牌、排行榜类型发生变化时更新排行榜头部信息和列表信息
  protected loadDate(isOnlyGuiderChange = false) {
    this.getRankingSiteGuiderInfo();
    // isOnlyGuiderChange = true, 店长时，导购之间的切换，那么只需要刷新头部数据，列表数据无需重新获取
    if (!isOnlyGuiderChange) {
      this.clearData();
      this.getReportRankingSiteGuiderList();
    }
  }

  // 获取导购下拉中数据
  protected getGuiderList() {
    api
      .siteGuiderList({ querySiteCode: this.siteCode,roleList:["101","102"] })
      .then((data: any) => {
        // 如果是店长 需要导购下拉 数据获取，否则默认选择本人
        this.siteGuiderList.push(...data.content.list);
        if (!this.isStoreManager) {
          const curEmp = data.content.list.find(
            d => d.empCode === this.empCode
          );
          if (curEmp) {
            this.selectedSiteGuider = curEmp;
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  // 获取排行榜配置信息（头尾部数据的百分比、是否展示数据的配置）
  protected getAchievementConfig() {
    api
      .mpGroupAchievementConfigGet({})
      .then(resp => {
        if (resp.code === 20000 && resp.content && resp.content.ranking) {
          this.config = resp.content.ranking;
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  // 获取排行榜-店铺主打品牌,添加到goodsList 中
  protected getSiteMainBrand() {
    api
      .reportRankingSiteMainBrand({})
      .then(resp => {
        if (resp.code === 20000 && resp.content && resp.content.brandCode) {
          this.goodsList.push(resp.content);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  // 获取排行榜-网点所在区域树,添加到areaList 中
  protected getSiteRegionList() {
    return api
      .reportRankingSiteRegionList({})
      .then(resp => {
        if (
          resp.code === 20000 &&
          Array.isArray(resp.content) &&
          resp.content[0]
        ) {
          const areaList = [];
          const getChildrenOrg = data => {
            areaList.push({
              code: data.code,
              name: data.isSite ? '本店' : data.name,
              isSite: data.isSite,
              level: data.level
            });
            if (Array.isArray(data.children) && data.children[0]) {
              getChildrenOrg(data.children[0]);
            }
          };
          getChildrenOrg(resp.content[0]);
          this.selectedOrg =
            areaList.length > 1 ? areaList[areaList.length - 2] : areaList[0];
          this.areaList = areaList;
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  // 根据条件查询排行榜头部信息
  protected getRankingSiteGuiderInfo() {
    api
      .reportRankingSiteGuiderInfo({
        ...this.queryParam
      })
      .then(resp => {
        if (resp.code === 20000 && resp.content) {
          const val = resp.content;
          this.siteInfo = {
            totalNum: val.totalNum,
            ownerRank: val.ownerRank,
            averageValue: this.getExtraText(val.averageValue),
            topResult: this.getExtraText(val.topResult),
            bottomResult: this.getExtraText(val.bottomResult),
            ownerValue: this.getExtraText(val.ownerValue),
            topResultWidth: null,
            bottomResultWidth: null,
            ownerValueWidth: null
          };
        }
      })
      .catch(error => {
        this.siteInfo = {
          totalNum: 0,
          ownerRank: 0,
          averageValue: '',
          topResult: '',
          bottomResult: '',
          ownerValue: '',
          topResultWidth: null,
          bottomResultWidth: null,
          ownerValueWidth: null
        };
        console.log(error);
      });
  }

  // 获取列表数据，初始化获取，或者查询条件发生变化
  protected getReportRankingSiteGuiderList(formScroll?: boolean) {
    this.listLoading = true;
    // 修复滚动条到底部后显示加载中
    if (formScroll) {
      setTimeout(() => {
        const scrollDiv = this.$el.querySelector('.ranking-content');
        const clientHeight = scrollDiv.clientHeight;
        const scrollHeight = scrollDiv.scrollHeight;
        scrollDiv.scrollTop = scrollHeight - clientHeight - 1;
      }, 10);
    }
    api
      .reportRankingSiteGuiderList({
        ...this.queryParam,
        page: this.page,
        pageSize: this.pageSize
      })
      .then(resp => {
        if (resp.code === 20000 && resp.content && resp.content.list) {
          if (resp.content.list.length === 0 && this.page !== 1) {
            this.isSearchedListEnd = true;
            this.isScroll = false;
          } else {
            this.rankingList = this.rankingList.concat(resp.content.list);
            this.isScroll = true;
            this.page++;
          }
          // 判断是否获取到数据,是否显示无数据的样式
          this.isCallAPINoData = this.rankingList.length === 0;
        }
      })
      .finally(() => (this.listLoading = false));
  }

  // 打开区域和品牌范围配置页面
  protected openSettingWrap() {
    this.selectedSetting = {
      org: this.selectedOrg,
      goods: this.selectedGoods
    };
    this.isSettingPageShow = true;
  }

  // 区域和品牌范围配置修改确定事件
  protected onSettingChange() {
    this.selectedOrg = this.selectedSetting.org;
    this.selectedGoods = this.selectedSetting.goods;
    // 如果选择本店，并且还是店长的情况下，默认选择第一个导购，
    if (
      this.isStoreManager &&
      this.selectedOrg.isSite &&
      this.siteGuiderList[1]
    ) {
      this.selectedSiteGuider = this.siteGuiderList[1];
    }
    this.isSettingPageShow = false;
    this.loadDate();
  }

  protected onSiteGuiderChange(oldVal: {
    empCode: string;
    empName: string;
    guiderId: string;
  }) {
    // 如果选中店铺，且是店长时，且区域选择的是本店，那么区域设置成本店上一级，切换成店铺列表
    if (
      !this.selectedSiteGuider.empCode &&
      this.isStoreManager &&
      this.selectedOrg.isSite
    ) {
      this.selectedOrg =
        this.areaList.length > 1
          ? this.areaList[this.areaList.length - 2]
          : this.areaList[0];
    }
    // 店长时，旧值和新值都是导购，即导购之间的切换，那么只需要刷新头部数据，列表数据无需重新获取
    const isOnlyGuiderChange =
      oldVal.empCode && this.selectedSiteGuider.empCode && this.isStoreManager;

    this.loadDate(isOnlyGuiderChange);
  }

  // 计算色柱 中显示字体长度，用于让其居中
  protected calcTextWidth(str) {
    if (this.$el && this.$el.querySelector('.calc-text-width')) {
      this.$el.querySelector('.calc-text-width').innerHTML = str;
      const width = this.$el.querySelector('.calc-text-width').clientWidth;
      return width;
    }
    return 0;
  }

  // 展示数据的配置 ==1 时，显示的值的格式化
  protected getExtraText(val) {
    if (val === '-') {
      return val;
    }
    let value = '';
    switch (this.selectedRanking.id) {
      case 1:
        value = '￥' + numToTHS(val, 1);
        break;
      case 5:
        value = '￥' + numToTHS(val, 2);
        break;
      case 2:
        value = numToTHS(val, 0) + '人';
        break;
      case 3:
      case 4:
        value = Math.round(+val * 10000) / 100 + '%';
        break;
      case 6:
        value = Math.round(+val * 100) / 100 + '件';
    }
    return value;
  }

  // 排行榜列表中加载图片失败后，设置默认图片
  protected onImgError(event, imgSrc) {
    const img = event.srcElement as HTMLImageElement;
    img.src = imgSrc;
    img.onerror = null; // 控制不要一直跳动;
  }

  // 渲染我的位置，如果【（当前总榜单店数/人数）*X%（取整】）或【（当前总榜单店数/人数）*（1-Y%）（取整）】任意一值小于1，则不再显示【我的位置】模块
  protected renderRankingInfo(h) {
    const a = this.siteInfo.totalNum * (this.config.top_value / 100);
    const b = this.siteInfo.totalNum * (1 - this.config.bottom_value / 100);
    if (a < 1 || b < 1 || this.isCallAPINoData) {
      return '';
    }

    // 等待页面生成后，计算字符串实际宽度（px），然后计算X,Y对应位置【因为div padding 0.26rem，也要加上】
    setTimeout(() => {
      if (
        !this.siteInfo.topResultWidth ||
        !this.siteInfo.bottomResultWidth ||
        !this.siteInfo.ownerValueWidth
      ) {
        this.siteInfo.topResultWidth = this.calcTextWidth(
          this.siteInfo.topResult
        );
        this.siteInfo.bottomResultWidth = this.calcTextWidth(
          this.siteInfo.bottomResult
        );
        this.siteInfo.ownerValueWidth = this.calcTextWidth(
          (this.isSiteInfoShow ? '本店' : this.selectedSiteGuider.empName) +
            this.siteInfo.ownerValue
        );
      }
    }, 100);
    // const maxWidth = this.$el ? this.$el.clientWidth : null;
    let oneREMToPX = null; // 1rem = ？px；根据ranking-title 高度是1rem，现在是多少px就是单位
    if (this.$el && this.$el.querySelector('.ranking-title')) {
      oneREMToPX = this.$el.querySelector('.ranking-title').clientHeight;
    }
    let lineWidth = null; // 色柱长度 用于计算三角形位置
    if (this.$el && this.$el.querySelector('.line-chart')) {
      lineWidth = this.$el.querySelector('.line-chart').clientWidth;
    }
    // 本店或者选择的导购当前排名百分比, 第一名要显示在最前面，其他都是对应排名/总数
    const curPct =
      this.siteInfo.ownerRank === 1
        ? 0
        : this.siteInfo.ownerRank / this.siteInfo.totalNum;
    const xPct = this.config.top_value + '%';
    const yPct = this.config.bottom_value + '%';
    const unit = this.isSiteInfoShow ? '店铺' : '导购'; // 根据选择的店铺或者导购在注释【我的位置】里显示不同文本
    return (
      <div class='ranking-info'>
        <div class='line-text'>
          <span
            class='number-val'
            style={
              this.siteInfo.topResultWidth
                ? {
                    left:
                      // (maxWidth * this.config.top_value) / 100 -
                      //   this.siteInfo.topResultWidth / 2 >
                      // 0
                      //   ? 'calc(' +
                      //     xPct +
                      //     ' - ' +
                      //     this.siteInfo.topResultWidth / 2 +
                      //     'px + 0.26rem)'
                      //   : '0.13rem'

                      (lineWidth * this.config.top_value) / 100 +
                        oneREMToPX * 0.13 -
                        this.siteInfo.topResultWidth / 2 >
                      0
                        ? lineWidth * (1 - this.config.top_value / 100) +
                            oneREMToPX * 0.13 <
                          this.siteInfo.topResultWidth
                          ? 'calc(100% - ' +
                            this.siteInfo.topResultWidth +
                            'px - 0.13rem)'
                          : 'calc(' +
                            (lineWidth * this.config.top_value) / 100 +
                            'px - ' +
                            this.siteInfo.topResultWidth / 2 +
                            'px + 0.26rem)'
                        : '0.13rem'
                  }
                : {
                    zIndex: -1,
                    left: xPct
                  }
            }
          >
            {this.siteInfo.topResult}
          </span>
          <span
            class='number-val'
            style={
              this.siteInfo.bottomResultWidth
                ? {
                    right:
                      // (maxWidth * this.config.bottom_value) / 100 -
                      //   this.siteInfo.bottomResultWidth / 2 >
                      // 0
                      //   ? 'calc(' +
                      //     yPct +
                      //     ' - ' +
                      //     this.siteInfo.bottomResultWidth / 2 +
                      //     'px + 0.26rem)'
                      //   : '0.13rem'
                      (lineWidth * this.config.bottom_value) / 100 +
                        oneREMToPX * 0.13 -
                        this.siteInfo.bottomResultWidth / 2 >
                      0
                        ? lineWidth * (1 - this.config.bottom_value / 100) +
                            oneREMToPX * 0.13 <
                          this.siteInfo.bottomResultWidth
                          ? 'calc(100% - ' +
                            this.siteInfo.bottomResultWidth +
                            'px - 0.13rem)'
                          : 'calc(' +
                            (lineWidth * this.config.bottom_value) / 100 +
                            'px - ' +
                            this.siteInfo.bottomResultWidth / 2 +
                            'px + 0.26rem)'
                        : '0.13rem'
                  }
                : { zIndex: -1, right: yPct }
            }
          >
            {this.siteInfo.bottomResult}
          </span>
        </div>
        <div class='line-chart'>
          <div class='start-line' style={{ minWidth: xPct }}></div>
          <div class='middle-line'></div>
          <div class='end-line' style={{ minWidth: yPct }}></div>
        </div>
        <div class='line-arrow'>
          <span
            class='up-arrow'
            style={
              this.siteInfo.ownerValueWidth
                ? {
                    left:
                      // lineWidth * curPct = 所占色柱px值，lineWidth * (1 - curPct) = 色柱剩余长度
                      // 三角形的宽度（0.24rem OR oneREMToPX * 0.24 px）,三角形要居中的向左偏移值（0.24/2 = 0.12rem），0.26rem为两边padding值
                      // 如果所占长度大于三角形的宽度（0.24rem）,且色柱剩余长度大于三角形宽度【即三角形不超过色柱边线】，
                      // 三角形位置=0.26rem【左侧padding】- 0.12rem【三角形偏移值】+ lineWidth * curPct【所占色柱px值】
                      // 如果三角形超出色柱，100% - 0.26rem - 0.12rem = 色柱最右测加上色柱剩余长度来标记三角形
                      lineWidth * curPct - oneREMToPX * 0.24 > 0 &&
                      lineWidth * (1 - curPct) < oneREMToPX * 0.24
                        ? 'calc(100% - 0.26rem - 0.12rem - ' +
                          lineWidth * (1 - curPct) +
                          'px)'
                        : 'calc(' + lineWidth * curPct + 'px + 0.14rem)'
                  }
                : {
                    zIndex: -1,
                    left: curPct * 100 + '%'
                  }
            }
          ></span>
        </div>
        <div class='line-text'>
          <span
            class='number-val'
            style={
              this.siteInfo.ownerValueWidth
                ? {
                    left:
                      lineWidth * curPct +
                        oneREMToPX * 0.13 -
                        this.siteInfo.ownerValueWidth / 2 >
                      0
                        ? lineWidth * (1 - curPct) + oneREMToPX * 0.13 <
                          this.siteInfo.ownerValueWidth
                          ? 'calc(100% - ' +
                            this.siteInfo.ownerValueWidth +
                            'px - 0.13rem)'
                          : 'calc(' +
                            curPct * lineWidth +
                            'px - ' +
                            this.siteInfo.ownerValueWidth / 2 +
                            'px + 0.26rem)'
                        : '0.13rem'
                  }
                : {
                    zIndex: -1,
                    left: curPct * 100 + '%'
                  }
            }
          >
            {(this.isSiteInfoShow ? '本店' : this.selectedSiteGuider.empName) +
              this.siteInfo.ownerValue}
          </span>
        </div>
        {this.isDetailTipShow && (
          <div class='detail-info'>
            <div class='info-item'>
              <span class='square-icon start-line' />
              <span>
                <span class='desc-text'>
                  是在本榜单中排名在前{xPct}的{unit}，
                </span>
                【{this.siteInfo.topResult}】是前{xPct}
                {unit}
                {this.selectedRanking.name}
                的分界值
              </span>
            </div>
            <div class='info-item'>
              <span class='square-icon end-line' />
              <span>
                <span class='desc-text'>
                  是在本榜单中排名在后{yPct}的{unit}，
                </span>
                【{this.siteInfo.bottomResult}】是后{yPct}
                {unit}
                {this.selectedRanking.name}
                的分界值
              </span>
            </div>
            <div class='info-item'>
              <span class='red-tip'>*</span>
              <span>数据统计截止到昨天</span>
            </div>
          </div>
        )}
        <div
          class='arrow-btn'
          onClick={() => (this.isDetailTipShow = !this.isDetailTipShow)}
        >
          <SvgIcon
            class='ranking-tip'
            iconClass={this.isDetailTipShow ? 'icon-less' : 'icon-moreunfold'}
          ></SvgIcon>
        </div>
      </div>
    );
  }

  // 渲染头部，（固定未知，不可滚动）
  protected renderHeader(h) {
    return (
      <div class='header-wrap'>
        <div class='selectbox-row'>
          <DzSelectBox
            v-show={this.isStoreManager}
            class='guider-select'
            idKey='empCode'
            nameKey='empName'
            list={this.siteGuiderList}
            v-model={this.selectedSiteGuider}
            onChange={(newVal, oldVal) => this.onSiteGuiderChange(oldVal)}
          />
          <DzSelectBox
            list={this.monthList}
            v-model={this.selectedMonth}
            onChange={() => this.loadDate()}
          />
          <div class='org-goods-wrap'>
            <span class="org-brand-content" onClick={() => this.openSettingWrap()}>
              <span class="org-name-text">{this.selectedOrg ? this.selectedOrg.name : ''}</span>-
              <span class="brand-name-text">{this.selectedGoods.brandName}</span>
              <span>
                <i class='menu-icon iconfont icon-gengduo11' />
              </span>
            </span>
          </div>
        </div>
        <div class='ranking-title'>
          <DzSelectBox
            class='ranking-select'
            nameKey='title'
            list={this.rankingTitleList}
            v-model={this.selectedRanking}
            onChange={() => this.loadDate()}
          />
        </div>
        <div class='site-info' v-show={!this.isCallAPINoData}>
          排行榜共&nbsp;<span class='red-text'>{this.siteInfo.totalNum}</span>
          &nbsp;
          {this.isSiteInfoShow
            ? '家店，本店'
            : '人，' + this.selectedSiteGuider.empName}
          排名&nbsp;
          <span class='red-text'>{this.siteInfo.ownerRank}</span>&nbsp;，
          {this.selectedRanking.name}
          均值&nbsp;
          <span class='red-text'>{this.siteInfo.averageValue}</span>
        </div>
        {this.renderRankingInfo(h)}
      </div>
    );
  }

  protected renderExtraText(h, val) {
    if (val === '-') {
      return <span class='number-text'>{val}</span>;
    }
    let value = <span></span>;
    switch (this.selectedRanking.id) {
      case 1:
        value = (
          <span>
            ￥<span class='number-text'>{numToTHS(val, 1)}</span>
          </span>
        );
        break;
      case 5:
        value = (
          <span>
            ￥<span class='number-text'>{numToTHS(val, 2)}</span>
          </span>
        );
        break;
      case 2:
        value = (
          <span>
            <span class='number-text'>{numToTHS(val, 0)}</span>人
          </span>
        );
        break;
      case 3:
      case 4:
        value = (
          <span>
            <span class='number-text'>{Math.round(+val * 10000) / 100}</span>%
          </span>
        );
        break;
      case 6:
        value = (
          <span>
            <span class='number-text'>{Math.round(+val * 100) / 100}</span>件
          </span>
        );
    }
    return value;
  }

  protected renderContent(h) {
    const top1 = this.rankingList[0];
    const top2 = this.rankingList[1];
    const top3 = this.rankingList[2];
    const list =
      this.rankingList.length > 3
        ? JSON.parse(JSON.stringify(this.rankingList)).splice(
            3,
            this.rankingList.length - 3
          )
        : [];
    // 是否显示额外信息
    const isShowExtraInfo = this.config[this.selectedRanking.configKey] === 1;
    // 设置默认店铺或者导购头像图片地址
    const defaultImg = this.isSiteInfoShow
      ? 'https://cdn-img-service.qingger.cn/default_site_logo2.png'
      : 'https://cdn-img-service.qingger.cn/20190117111612.png';
    setTimeout(() => {
      const headerHeight =
        this.$el && this.$el.querySelector('.header-wrap')
          ? this.$el.querySelector('.header-wrap').clientHeight + 'px'
          : '2.8rem';
      if (this.$el && this.$el.querySelector('.ranking-content')) {
        (this.$el.querySelector('.ranking-content') as any).style.height =
          'calc(100vh - ' + headerHeight + ')';
      }
    }, 100);
    return (
      <div
        class={
          this.isCallAPINoData
            ? 'ranking-content ranking-noData-bg'
            : 'ranking-content ranking-bg'
        }
      >
        {!this.isCallAPINoData ? (
          [
            <div class='ranking-list'>
              <div class='list-header' v-show={!!top1 || !!top2 || !!top3}>
                <div class='ranking-top2'>
                  {top2 && [
                    <div class='top-img-size top2-img'></div>,
                    <div class='header-img'>
                      <img
                        src={top2.imgUrl || defaultImg}
                        on-error={$event => this.onImgError($event, defaultImg)}
                      />
                    </div>,
                    <div class='item-text'>
                      {this.isSiteInfoShow ? (
                        <div class='first-text text-limit'>{top2.siteName}</div>
                      ) : (
                        [
                          <div class='first-text name-limit'>
                            {top2.empName}
                          </div>,
                          <div class='sec-text text-limit'>{top2.siteName}</div>
                        ]
                      )}
                    </div>,
                    <div class='item-value'>
                      {isShowExtraInfo
                        ? this.renderExtraText(h, top2.value)
                        : ''}
                    </div>
                  ]}
                </div>
                <div class='ranking-top1-wrap'>
                  {top1 && [
                    <div class='ranking-top1-bg'></div>,
                    <div class='ranking-top1'>
                      <div class='top-img-size top1-img'></div>
                      <div class='header-img'>
                        <img
                          src={top1.imgUrl || defaultImg}
                          on-error={$event =>
                            this.onImgError($event, defaultImg)
                          }
                        />
                      </div>
                      <div class='item-text'>
                        {this.isSiteInfoShow ? (
                          <div class='first-text text-limit'>
                            {top1.siteName}
                          </div>
                        ) : (
                          [
                            <div class='first-text name-limit'>
                              {top1.empName}
                            </div>,
                            <div class='sec-text text-limit'>
                              {top1.siteName}
                            </div>
                          ]
                        )}
                      </div>
                      <div class='item-value'>
                        {isShowExtraInfo
                          ? this.renderExtraText(h, top1.value)
                          : ''}
                      </div>
                    </div>
                  ]}
                </div>
                <div class='ranking-top3'>
                  {top3 && [
                    <div class='top-img-size top3-img'></div>,
                    <div class='header-img'>
                      <img
                        src={top3.imgUrl || defaultImg}
                        on-error={$event => this.onImgError($event, defaultImg)}
                      />
                    </div>,
                    <div class='item-text'>
                      {this.isSiteInfoShow ? (
                        <div class='first-text text-limit'>{top3.siteName}</div>
                      ) : (
                        [
                          <div class='first-text name-limit'>
                            {top3.empName}
                          </div>,
                          <div class='sec-text text-limit'>{top3.siteName}</div>
                        ]
                      )}
                    </div>,
                    <div class='item-value'>
                      {isShowExtraInfo
                        ? this.renderExtraText(h, top3.value)
                        : ''}
                    </div>
                  ]}
                </div>
              </div>
              <div class='other-items'>
                {list.map((d, index) => {
                  return (
                    <div class='item-wrap' key={index}>
                      <div
                        class={
                          'index-number number-size' + (index + 4 + '').length
                        }
                      >
                        {index + 4}
                      </div>
                      <div class='item-img'>
                        <img
                          src={d.imgUrl || defaultImg}
                          on-error={$event =>
                            this.onImgError($event, defaultImg)
                          }
                        />
                      </div>
                      <div class='item-text'>
                        {this.isSiteInfoShow ? (
                          <div class='first-text text-limit'>{d.siteName}</div>
                        ) : (
                          [
                            <div class='first-text name-limit'>
                              {d.empName}
                            </div>,
                            <div class='sec-text text-limit'>{d.siteName}</div>
                          ]
                        )}
                      </div>
                      <div class='item-value'>
                        {isShowExtraInfo
                          ? this.renderExtraText(h, d.value)
                          : ''}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>,
            <div class='loading-wrap' v-show={this.listLoading}>
              - 加载中 -
            </div>,
            <div class='noListItem' v-show={this.isSearchedListEnd}>
              ~ 这是我的底线 ~
            </div>
          ]
        ) : (
          <div class='ranking-list noData-list'>
            <div class='list-header noData-header'>
              <div class='ranking-top2'></div>
              <div class='ranking-top1-wrap'>
                <div class='ranking-top1-bg'></div>
                <div class='ranking-top1'></div>
              </div>
              <div class='ranking-top3'></div>
            </div>
            <div class='noData-content'>
              <i class='iconfont icon-kongshuju1'></i>
              <div>暂无排行</div>
            </div>
          </div>
        )}
      </div>
    );
  }

  // 渲染地区和品牌设置页面
  protected renderSettingPage(h) {
    return (
      <div v-show={this.isSettingPageShow} class='setting-page'>
        <div class='setting-content'>
          <div class='setting-title'>区域范围配置</div>
          {this.areaList.map((d, index) => {
            return (
              <div
                key={'orgItem' + index}
                class='list-item'
                onClick={() => (this.selectedSetting.org = d)}
              >
                {Array.from({ length: index }).map(k => {
                  return <span>&#12288;</span>;
                })}
                <span>{d.name}</span>
                {this.selectedSetting &&
                this.selectedSetting.org &&
                this.selectedSetting.org.code === d.code ? (
                  <SvgIcon
                    class='selected-icon'
                    iconClass='icon-xuanzhong'
                  ></SvgIcon>
                ) : (
                  ''
                )}
              </div>
            );
          })}
          <div class='setting-title'>品牌范围配置</div>
          {this.goodsList.map((d, index) => {
            return (
              <div
                key={'goodsItem' + index}
                class='list-item'
                onClick={() => (this.selectedSetting.goods = d)}
              >
                <span>
                  {d.brandCode !== null
                    ? '本店主打品牌（' + d.brandName + '）'
                    : d.brandName}
                </span>
                {this.selectedSetting &&
                  this.selectedSetting.goods &&
                  this.selectedSetting.goods.brandCode === d.brandCode && (
                    <SvgIcon
                      class='selected-icon'
                      iconClass='icon-xuanzhong'
                    ></SvgIcon>
                  )}
              </div>
            );
          })}
        </div>
        <div class='setting-footer'>
          <div
            class='setting-cancel setting-btn'
            onClick={() => (this.isSettingPageShow = false)}
          >
            取消
          </div>
          <div
            class='setting-ok setting-btn'
            onClick={() => this.onSettingChange()}
          >
            确定
          </div>
        </div>
      </div>
    );
  }
  protected render(h) {
    return (
      <div class='ranking-page-wrap'>
        <div class='ranking-page'>
          {this.renderHeader(h)}
          {this.renderContent(h)}
          {this.renderSettingPage(h)}
          <DzInfoDialog
            v-model={this.isInfoDialogShow}
            innerHTML={this.helpInfoHtml}
          />
          <span
            style='position: absolute;　z-index: -100; top: 0;'
            class='calc-text-width'
          ></span>
        </div>
        <HelpTips
            onShow={() => {
              this.isInfoDialogShow = true
            }}
        />
      </div>
    );
  }
}
