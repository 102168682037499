var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"report2"},[_vm._m(0),_c('div',{staticClass:"popupMask",staticStyle:{"display":"none"}}),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"data-select-wrap",staticStyle:{"text-align":"center","background":"#fff","display":"none"}},[_c('GuiderList',{attrs:{"guiderListGetData":this.getData,"guiderList":_vm.guiderList}})],1),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"home-nav",staticStyle:{"display":"none"},attrs:{"href":"home","id":"homeTab"}},[_c('i',{staticClass:"iconfont icon-shouye"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dialog-cover",attrs:{"id":"dialogCover"}},[_c('div',{staticClass:"notice-dialog"},[_c('div',{staticClass:"title"},[_vm._v("帮助 "),_c('span',{attrs:{"id":"closeDialog"}},[_c('i',{staticClass:"close-icon"})])]),_c('div',{staticClass:"content"},[_vm._v(" 说明文的语言的要求是科学性、严谨性、准确性，真实性，周密性，简练明确;文学性说明文还要求生动性、形象性。恰当地运用说明方法，能提高说明语言的科学性和准确性，使说明对象更具体、更生动，让读者更明白，更清楚作者的意思，更能增强说服力，有时也能增强读者的阅读兴趣，更突出主题。说明文的语言的要求是科学性、严谨性、准确性，真实性，周密性，简练明确;文学性说明文还要求生动性、形象性。恰当地运用说明方法，能提高说明语言的科学性和准确性，使说明对象更具体、更生动，让读者更明白，更清楚作者的意思，更能增强说服力，有时也能增强读者的阅读兴趣，更突出主题。 ")]),_c('div',{staticClass:"img"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"top-nav-wrap"},[_c('li',{staticClass:"nav-on-selected",attrs:{"data-day":"1"}},[_vm._v("昨日")]),_c('li',{attrs:{"data-day":"2"}},[_vm._v("上周")]),_c('li',{attrs:{"data-day":"3"}},[_vm._v("本周")]),_c('li',{attrs:{"data-day":"4"}},[_vm._v("上月")]),_c('li',{attrs:{"data-day":"5"}},[_vm._v("本月")]),_c('li',{attrs:{"data-day":"6"}},[_vm._v("本年至今")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-tap-wrap"},[_c('div',{staticClass:"top-tap"},[_c('span',{staticClass:"tap-on-selected",attrs:{"data-type":"1"}},[_vm._v("我的客流")]),_c('span',{attrs:{"data-type":"2"}},[_vm._v("店铺客流")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrap"},[_c('div',{staticClass:"title"},[_vm._v("客流数据")]),_c('div',{staticClass:"chart-body",attrs:{"id":"customerCountChartWrap"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrap"},[_c('div',{staticClass:"title"},[_vm._v("进店试穿率趋势及同期对比")]),_c('div',{staticClass:"chart-body",attrs:{"id":"onlyWearChartWrap"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrap"},[_c('div',{staticClass:"title"},[_vm._v("进店成交率趋势及同期对比")]),_c('div',{staticClass:"chart-body",attrs:{"id":"buyChartWrap"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrap"},[_c('div',{staticClass:"title"},[_vm._v("试穿成交率趋势及同期对比")]),_c('div',{staticClass:"chart-body",attrs:{"id":"wearAndBuyChartWrap"}})])
}]

export { render, staticRenderFns }