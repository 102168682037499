import numeral from 'numeral';
import F2 from '@antv/f2';
import PieLabel from '@antv/f2/lib/plugin/pie-label';
import jquery from 'jquery';
/**
 * 格式化货币数据
 * @param num 原数据
 * @param len 小数位数，不填自动判断，但是只能判断0,1,2
 * @returns {*} 格式化后的数据
 */
/* tslint:disable */
export function formatNum(num: any, len?: any): string {
  if (num === 0) {
    return num;
  }
  let result;
  let flg = false;
  num = num * 1; // 去除无效的0
  if (num < 0) {
    flg = true;
  }
  num = Math.abs(num); // 小于0的变为0
  if (num % 1 === 0 && len === 1) {
    if (flg) {
      return '-' + numeral(num).format('0,0');
    }
    return numeral(num).format('0,0');
  }

  if (len === undefined) {
    num = '' + num;
    if (num.indexOf('.') > -1) {
      // 判断小数位数
      len = num.split('.')[1].length;
    } else {
      len = 0;
    }
  }
  if (len === 1) {
    result = numeral(num).format('0,0.0');
  } else if (len === 2) {
    result = numeral(num).format('0,0.00');
  } else {
    result = numeral(num).format('0,0');
  }
  console.log(result)

  if (flg) {
    return '-' + result;
  }
  return result;
}

export function numToTHS(num: number | string, toFixedCount: number = 2) {
  let numStr = num + '';

  const regex: any[] =
    numStr.indexOf('.') === -1
      ? [/(?=(?!(\b))(\d{3})+$)/g, ',']
      : [/(\d)(?=(\d{3})+\.)/g, '$1,'];

  const fixedCount = Math.pow(10, toFixedCount);
  const val = Math.round(+numStr * fixedCount) / fixedCount;
  numStr = (val + '').replace(regex[0], regex[1]);
  return numStr;
}

/**
 * 不做 负数处理
 * @param num
 * @param len
 * @return {string}
 */
export function formatData(num: any, len?: any): string {
  let result;
  num = num * 1; // 去除无效的0
  if (len === undefined) {
    num = '' + num;
    if (num.indexOf('.') > -1) {
      // 判断小数位数
      len = num.split('.')[1].length;
    } else {
      len = 0;
    }
  }
  if (len === 1) {
    result = numeral(num).format('0,0.0');
  } else if (len === 2) {
    result = numeral(num).format('0,0.00');
  } else {
    result = numeral(num).format('0,0');
  }
  return result;
}

/**
 * 求百分比
 * @param {number} target
 * @param {number} complete
 * @return {number}
 */
export function getPercent(target: number, complete: number): number {
  let percent: number;
  if (target === null) {
    target = -1;
  }
  percent = target <= 0 ? -1 : +((complete / target) * 100).toFixed(1) * 1;
  // percent = target === 0 && complete !== 0 ? 100 : percent;//如果目标为0，但是完成不为0，则计完成100%
  return percent;
}

/**
 * 生成环形图表
 * @param {number} percent
 * @param {string} id
 * @param {string} chartText 圆环内文字
 */
export function createCircleChart(
  percent: number,
  id: string,
  chartText: string
): void {
  const fontSize = window.innerWidth / 7.5; // 字体适配不同屏幕
  // percent = +(percent*100).toFixed(2);
  percent = percent * 1;
  const realData = percent;
  let per = 0;
  if (percent > 100) {
    percent = 100;
  } else if (percent < 0) {
    percent = 0;
    per = -1; // 证明分母是负数
  }
  const data = [
    {
      x: '1',
      y: percent
    }
  ];
  const chart = new F2.Chart({
    id,
    pixelRatio: window.devicePixelRatio,
    padding: 0,
  //@ts-ignore
    margin: 0,
    appendPadding: 0
  });
  chart.source(data, {
    y: {
      max: 100,
      min: 0
    }
  });
  chart.axis(false);
  chart.tooltip(false);
  chart.coord('polar', {
    transposed: true,
    innerRadius: 0.8,
    radius: 0.85
  });
  chart.guide().arc({
    start: [0, 0],
    end: [1, 99.98],
    top: false,
    style: {
      lineWidth: fontSize * 0.22,
      stroke: '#F5F7FA'
    }
  });

  chart.guide().html({
    position: ['50%', '50%'],
    html: `<div class="chart-inner" style="width: 250px;height: 40px;text-align: center;"><div style="font-size: 0.36rem;color: #2B61FE;">${
      per < 0 ? '-' : realData
    }<span style="font-size: 0.2rem">%</span></div><div style="font-size: 0.2rem;color: #999999;">${chartText}</div><div id="chart-diff" style="display: none"></div</div>`
  });
  chart
    .interval()
    .color('#1890FF')
    .position('x*y')
    .size(fontSize * 0.22)
    .animate({
      appear: {
        duration: 1200,
        easing: 'cubicIn'
      }
    });
  chart.render();
}

/**
 * 创建饼图
 * @param {Array<recruitChannelItem>} data
 * @param {string} id
 * @param config
 */
export function createPieChart(data: any[], id: string, config: any): void {
  if (!data) {
    jquery(`#${id}`)
      .parent()
      .html(
        `<div class="no-data"><i class="icon-kongshuju iconfont"></i><div>无数据</div></div>`
      );
  } else {
    const fontSize = window.innerWidth / 7.5; // 字体适配不同屏幕
    const newData: any = [];
    let total = 0;
    data.forEach(item => {
      total += item.val;
    });
    data.forEach(item => {
      newData.push({
        ...item,
        // ratio: index % 2 === 0 ? 0.1 : 0.05,
        ratio: total > 0 ? +formatNum((item.val / total).toFixed(2)) : 0,
        percent:
            total > 0 ? formatNum(((item.val / total) * 100).toFixed(2)) : '0',
        const: 'const'
      });
    });
    const chart = new F2.Chart({
      id,
      pixelRatio: window.devicePixelRatio,
      plugins: PieLabel,
      padding: [15, 0, 100, 0]
    });

    chart.source(newData);
    chart.coord('polar', {
      transposed: true,
      innerRadius: 0.4,
      radius: 0.75
    });
    chart.axis(false);
    chart.legend({
      position: 'bottom',
      align: 'center'
    });
    chart.tooltip(false);
    if (config.guide) {
      chart.guide().html({
        position: ['50%', '50%'],
        html: `<div style="width: 100px;height: 20px;text-align: center;position: relative;" id="textContent"><div style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">${config.guide(
          total,
          fontSize
        )}</div></div>`
      });
    }

    // 配置文本饼图
    chart.pieLabel(config.pieLabel);
    if (config.legend === false) {
      chart.legend(false);
    }
    chart
      .interval()
      .position('const*ratio')
      .color('name', [
        '#1890FF',
        '#13C2C2',
        '#2FC25B',
        '#FACC14',
        '#F04864',
        '#8543E0',
        '#3436C7',
        '#223273'
      ])
      .adjust('stack');
    chart.render();

    // 绘制内阴影
    const frontPlot = chart.get('frontPlot');
    const coord:any = chart.get('coord'); // 获取坐标系对象
    frontPlot.addShape('sector', {
      attrs: {
        x: coord.center.x,
        y: coord.center.y,
        r: coord.circleRadius * coord.innerRadius * 1.2, // 全半径
        r0: coord.circleRadius * coord.innerRadius,
        fill: '#000',
        opacity: 0.15
      }
    });
    chart.get('canvas').draw();
  }
}
/**
 * 1秒内点击一次
 * @type {boolean}
 */
let isClicked = true;
let time;

export function limitClick(timeout=500) {

	return new Promise(function (resolve, reject) {
		if (isClicked) {
			isClicked = false;
			time = setTimeout(function () {
				isClicked = true;
			}, timeout);
			resolve(true);
		} else {
			reject(false);
		}
	});
}
export default {
  formatNum,
  getPercent,
  createCircleChart,
  createPieChart,
  formatData
};
