import baseService from "./baseService";
import config, {PGConfig, crmConfig} from "./config";
import {resolve} from "path";
import baseCrmService, {getParams, newService} from "./baseCrmService";

export default {
    // R1-1. 导购及网点的业绩和招募数据(天) https://wiki.qingger.cn/pages/viewpage.action?pageId=5309776
    dayArchive: (params: dayArchiveRequest) => {
        return baseService.service(config.dayArchive.url, config.dayArchive.type, params);
    },
    // R1-2. 导购及网点的业绩和招募数据(月) https://wiki.qingger.cn/pages/viewpage.action?pageId=5310165
    monthArchive: (params: monthArchiveRequest) => {
        return baseService.service(config.monthArchive.url, config.monthArchive.type, params);
    },
    // R5. 报表 - 会员招募(DZCR0001) https://wiki.qingger.cn/pages/viewpage.action?pageId=5309813
    historyRecruit: (params: historyRecruitRequest) => {
        return baseService.service(config.historyRecruit.url, config.historyRecruit.type, params, true);
    },
    // R6-1. 报表 -  会员群体分布维度列表 (会员近一年消费) https://wiki.qingger.cn/pages/viewpage.action?pageId=5311526
    customerTags: (param) => {
        return baseService.service(config.customerTags.url, config.historyCustomer.type, param, true);
    },
    // R6-2. 报表 - 会员画像(群众) https://wiki.qingger.cn/pages/viewpage.action?pageId=5309826
    historyCustomer: (params: historyCustomerRequest) => {
        return baseService.service(config.historyCustomer.url, config.historyCustomer.type, params, true);
    },
    // R7. 报表 - 会员消费(DZCR0003) https://wiki.qingger.cn/pages/viewpage.action?pageId=5309819
    historySale: (params: historyCustomerRequest) => {
        return baseService.service(config.historySale.url, config.historySale.type, params, true);
    },
    // R8-1. 报表 - 客流数据 https://wiki.qingger.cn/pages/viewpage.action?pageId=5315991
    customerCount: (params: historyCustomerRequest) => {
        return baseService.service(config.customerCount.url, config.customerCount.type, params);
    },
    // R8-2. 报表 - 客流区间数据  https://wiki.qingger.cn/pages/viewpage.action?pageId=5315997
    customerCountCompare: (params: historyCustomerRequest) => {
        return baseService.service(config.customerCountCompare.url, config.customerCountCompare.type, params);
    },
    // R8-3. 报表 - 是否有客流统计任务  https://wiki.qingger.cn/pages/viewpage.action?pageId=5315999
    customerCountGet: (param) => {
        return baseService.service(config.customerCountGet.url, config.customerCountGet.type, param);
    },
    // L7. 用户登录-雇员的网点导购列表信息获取 https://wiki.qingger.cn/pages/viewpage.action?pageId=5310758
    guiderInfo: () => {
        return baseService.service(config.guiderInfo.url, config.guiderInfo.type);
    },
    // L8. 用户-切换导购网点
    siteChange: (params: siteChangeRequest) => {
        return baseService.service(config.siteChange.url, config.siteChange.type, params);
    },
    // L9. 获取当前导购登录的企业雇员及导购信息 https://wiki.qingger.cn/pages/viewpage.action?pageId=5310767
    loginInfo: () => {
        return baseService.service(config.loginInfo.url, config.loginInfo.type);
    },
    // L19. 小程序(额外)配置信息 - 目前仅针对报表提供
    extendInfo: () => {
        return baseService.service(config.extendInfo.url, config.extendInfo.type);
    },
    nearlyDaysCommunicate: (params) => {
        return baseService.service(
            config.nearlyDaysCommunicate.url,

            config.nearlyDaysCommunicate.type,
            params,
        );
    },
    // T308. 店铺/导购 任务沟通结果展示
    reportCommunicateResult: (params) => {
        return baseService.service(config.reportCommunicateResult.url, config.reportCommunicateResult.type, params);
    },
    // T306. 店铺/导购 任务执行情况概览
    taskReportimplementation: (params) => {
        return baseService.service(config.taskReportimplementation.url, config.taskReportimplementation.type, params);
    },
    // T309. 任务参与导购执行转化结果
    reportCommunicateConversionList: (params) => {
        return baseService.service(
            config.reportCommunicateConversionList.url,
            config.reportCommunicateConversionList.type,
            params,
        );
    },
    // L14. 小程序对于企业的配置信息
    enterpriseConfig: () => {
        return baseService.service(config.enterpriseConfig.url, config.enterpriseConfig.type);
    },
    // S2. 网点集团数据-网点下雇员导购列表
    siteGuiderList: (param?) => {
        return baseService.service(config.siteGuiderList.url, config.siteGuiderList.type, param);
    },
    // S2. 网点集团数据-网点下雇员导购列表
    siteReportGuiderList: (param?) => {
        return baseService.service(config.reportExechGuiderList.url, config.reportExechGuiderList.type, param);
    },
    // R9 - 渲染店铺导购下拉列表页面(返回HTML片断)
    siteProductSale: (params) => {
        return baseService.service(config.siteProductSale.url, config.siteProductSale.type, params, true);
    },
    // L16. 小程序(全局)配置信息 - 与集团无关 (ISVMPConfig)
    isvMPConfig: () => {
        return baseService.service(config.isvMPConfig.url, config.isvMPConfig.type);
    },
    // L22. 获取集团针对目标设置的配置
    targetConfigGet: () => {
        return baseService.service(config.targetConfigGet.url, config.targetConfigGet.type);
    },
    // SMG12. 店铺某月主推商品列表
    goodsListGet: () => {
        return baseService.service(config.goodsListGet.url, config.goodsListGet.type);
    },
    // R11 - 查看指定导购的月目标设置
    guiderTargetInfo: () => {
        return baseService.service(config.guiderTargetInfo.url, config.guiderTargetInfo.type);
    },
    // R12 - 主推商品销售数据
    productSalePerformance: (param) => {
        return baseService.service(config.productSalePerformance.url, config.productSalePerformance.type, param, true);
    },
    // T239.获取任务详情V2
    DZV2TaskInfo: (param) => {
        return baseService.service(config.DZV2TaskInfo.url, config.DZV2TaskInfo.type, param);
    },

    //  SMG8. 店铺管理--获取店铺接触顾客查看权限和会员顾客数
    contactMemberGet: (param) => {
        return baseService.service(config.contactMemberGet.url, config.contactMemberGet.type, param, true);
    },
    //  T305. 任务跟踪列表
    taskTrackList: (param: taskTrackListRequest): Promise<taskTrackListResponse> => {
        return baseService.service(config.taskTrackList.url, config.taskTrackList.type, param, true);
    },
    //  T305. 任务跟踪列表
    taskTrackListGroup: (param: taskTrackListRequest): Promise<ITaskListExtends<ITaskListGroup>> => {
        return baseService.service(config.taskTrackListGroup.url, config.taskTrackListGroup.type, param, true);
    },
    /**
     * T60.数据上报任务保存
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=5315782
     */
    taskStatusUpdate: (param) => {
        return baseService.service(config.T3111_TASK_STATUS_UPDATE.url, config.T3111_TASK_STATUS_UPDATE.type, param, true);
    },

    /**
     * FT305.2. 任务跟踪列表--客流统计任务
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=16024748
     */
    taskReportTrackList: (param) => {
        return baseService.service(config.FT305_TASK_TRACK_LIST.url, config.FT305_TASK_TRACK_LIST.type, param, true);
    },
    /**
     * FT306.2. 店铺/导购 任务跟踪-执行情况概览（售后任务）
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=16024956
     */
    taskReportAfterSaleImplementation: (param) => {
        return baseService.service(
            config.TASK_REPORT_AFTER_SALE_IMP.url,
            config.TASK_REPORT_AFTER_SALE_IMP.type,
            param,
            true,
        );
    },

    /**
     * R18. 批量查询导购消费/招募数据
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=17334298
     */
    reportGuiderRecruitList: (param) => {
        return baseService.service(
            config.REPORT_GUIDER_RECRUIT_LIST.url,
            config.REPORT_GUIDER_RECRUIT_LIST.type,
            param,
            true,
        );
    },

    /**
     * R10.2 主推商品- 获取指定主推商品的导购销售数据
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=16025465
     */
    reportSiteProductGuiderSale: (param) => {
        return baseService.service(
            config.REPORT_SITE_PRODUCT_GUIDER_SALE.url,
            config.REPORT_SITE_PRODUCT_GUIDER_SALE.type,
            param,
            true,
        );
    },
    /**
     * R10.3 主推商品- 获取店铺商品销售总值
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=17334388
     */
    reportSiteProductTotalSale: (param) => {
        return baseService.service(
            config.REPORT_SITE_PRODUCT_TOTAL_SALE.url,
            config.REPORT_SITE_PRODUCT_TOTAL_SALE.type,
            param,
            true,
        );
    },
    /**
     * R13. 会员消费-会员交易贡献/会员消费数据/会员消费结构对比
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=16025371
     */
    reportConsumptionBusinessData: (param) => {
        return baseService.service(
            config.REPORT_CONSUMPTION_BUSINESS_DATA.url,
            config.REPORT_CONSUMPTION_BUSINESS_DATA.type,
            param,
            true,
        );
    },

    /**
     * T50.获取沟通配置
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=5311746
     */
    getCommunicateConfig: (param) => {
        return baseService.service(config.GET_COMMUNICATE_CONFIG.url, config.GET_COMMUNICATE_CONFIG.type, param, true);
    },

    /**
     * T310. 任务参与导购列表
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=14779052
     */
    taskReportExecGuiderList: (param) => {
        return baseService.service(
            config.T310_TASK_REPORT_EXEC_GUIDER_LIST.url,
            config.T310_TASK_REPORT_EXEC_GUIDER_LIST.type,
            param,
            true,
        );
    },
    /**
     * T39.获取指定任务详情
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=5311064
     */
    getTaskInfo: (param) => {
        return baseService.service(config.DZ_T39_TASK_INFO.url, config.DZ_T39_TASK_INFO.type, param);
    },
    /**
     * T48.获取某天指定任务完成情况
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=5312517
     */
    taskDayInfo: (param) => {
        return baseService.service(config.POST_TASK_DAY_INFO.url, config.POST_TASK_DAY_INFO.type, param, true);
    },
    /**
     * T249. 任务执行页沟通顾客列表V2
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=12747291
     */
    taskCustomerListV2: (param) => {
        return baseService.service(
            config.TASK_DAY_CUSTOMER_LIST_V2.url,
            config.TASK_DAY_CUSTOMER_LIST_V2.type,
            param,
            true,
        );
    },
    /**
     * FT252.获取沟通详情V2
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=12747317
     */
    taskExecInfoV2: (param) => {
        return baseService.service(config.DZ_V2_TASK_EXEC_INFO.url, config.DZ_V2_TASK_EXEC_INFO.type, param, true);
    },
    /**
     * T314. 获取顾客任务期间店铺买单情况
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=16023556
     */
    taskCustomerPurchaseDataList: (param) => {
        return baseService.service(
            config.T314_TASK_CUSTOMER_PURCHASE_DATA.url,
            config.T314_TASK_CUSTOMER_PURCHASE_DATA.type,
            param,
            true,
        );
    },
    /**
     * C24. 顾客-订单-顾客订单列表
     *
     */
    customerOrderList: (param) => {
        return baseService.service(
            config.DZ_C24_CUSTOMER_ORDER_LIST.url,
            config.DZ_C24_CUSTOMER_ORDER_LIST.type,
            param,
            true,
        );
    },
    /**
     * FT249.2. 任务执行页沟通顾客列表（售后任务）
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=16024919
     */
    afterSaleCustomerList: (param) => {
        return baseService.service(config.AFTER_SALE_CUSTOMER_LIST.url, config.AFTER_SALE_CUSTOMER_LIST.type, param, true);
    },

    /**
     * S4. 获得具体场景的标签组集合
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=22249473
     */
    getCorpTagsAct: (param) => {
        return baseService.service(config.GET_CORP_TAGS_ACT.url, config.GET_CORP_TAGS_ACT.type, param, true);
    },
    /**
     * FT249.4 查询任务执行客资数量
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=55935037
     */
    taskCustomerCount: (param) => {
        return baseService.service(
            config.DZ_V2_TASK_CUSTOMER_COUNT.url,
            config.DZ_V2_TASK_CUSTOMER_COUNT.type,
            param,
            true,
        );
    },
    /**
     * C24. 顾客-订单-顾客订单列表
     *
     */
    taskAfterSaleList: (param) => {
        return baseService.service(config.AFTER_SALE_REGION_LIST.url, config.AFTER_SALE_REGION_LIST.type, param, true);
    },
    /**
     * FT252.获取沟通详情V2
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=12747317
     */
    dzTaskCustomerInfo: (param) => {
        return baseService.service(config.DZ_TASK_CUSTOMER_INFO.url, config.DZ_TASK_CUSTOMER_INFO.type, param, true);
    },

    /**
     * FT252.获取沟通详情V2
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=12747317
     */
    dzV2TaskCustomerExecList: (param) => {
        return baseService.service(
            config.DZ_V2_TASK_CUSTOMER_EXEC_LIST.url,
            config.DZ_V2_TASK_CUSTOMER_EXEC_LIST.type,
            param,
            true,
        );
    },
    /**
     * C40. 根据UserId获取顾客CustomerId
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=5310568
     */
    getCustomerIdByExternalUserId: (param) => {
        return baseService.service(
            config.DZ_C40_GET_EXTERNAL_CSTID.url,
            config.DZ_C40_GET_EXTERNAL_CSTID.type,
            param,
            true,
        );
    },

    /**
     * FT249.3 生日类型任务未沟通-客资分配到日-左侧日期列表
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=41746476
     */
    taskBirthdayDateList: (param) => {
        return baseService.service(config.TASK_BIRTHDAY_DATE_LIST.url, config.TASK_BIRTHDAY_DATE_LIST.type, param, true);
    },

    /**
     * L23. 获取集团针对看板配置的配置(排行榜)
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=17334340
     */
    mpGroupAchievementConfigGet: (param: any): Promise<APIReponse<{ ranking: IMpGroupAchievementConfig }>> => {
        return baseService.service(
            config.L23_MP_GROUP_ACHIEVEMENT_CONFIG_GET.url,
            config.L23_MP_GROUP_ACHIEVEMENT_CONFIG_GET.type,
            param,
            true,
        );
    },

    /**
     * R14.排行榜-店铺主打品牌
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=16025392
     */
    reportRankingSiteMainBrand: (param: any): Promise<APIReponse<IReportRankingSiteMainBrand>> => {
        return baseService.service(
            config.R14_REPORT_RANKING_SITE_MAIN_BRAND.url,
            config.R14_REPORT_RANKING_SITE_MAIN_BRAND.type,
            param,
            true,
        );
    },
    /**
     * R15.排行榜-网点所在区域树
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=16025400
     */
    reportRankingSiteRegionList: (param: any): Promise<APIReponse<IReportRankingSiteRegionList[]>> => {
        return baseService.service(
            config.R15_REPORT_RANKING_SITE_REGION_LIST.url,
            config.R15_REPORT_RANKING_SITE_REGION_LIST.type,
            param,
            true,
        );
    },
    /**
     * R16. 排行榜-店铺/导购 排行情况
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=16025406
     */
    reportRankingSiteGuiderInfo: (
        param: IReportRankingSiteGuiderInfoParam,
    ): Promise<APIReponse<IReportRankingSiteGuiderInfo>> => {
        return baseService.service(
            config.R16_REPORT_RANKING_SITE_GUIDER_INFO.url,
            config.R16_REPORT_RANKING_SITE_GUIDER_INFO.type,
            param,
            true,
        );
    },
    /**
     * R17. 排行榜-店铺/导购 排行榜列表
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=16025425
     */
    reportRankingSiteGuiderList: (
        param: IReportRankingSiteGuiderListParam,
    ): Promise<APIListResponse<IReportRankingSiteGuiderListItem>> => {
        return baseService.service(
            config.R17_REPORT_RANKING_SITE_GUIDER_LIST.url,
            config.R17_REPORT_RANKING_SITE_GUIDER_LIST.type,
            param,
            true,
        );
    },
    /**
     * PGR.1 导购/店铺-当月/当日的招募/销售数据以及达成率
     * @see https://runsa.yuque.com/runsadev/ir5wcy/yn6yn5
     */
    pgReportPerformance: (param: IReportRankingSiteGuiderListParam): Promise<IResponse<IReportPerformance>> => {
        return baseService.service(PGConfig.PG_REPORT_PERFORMANCE.url, PGConfig.PG_REPORT_PERFORMANCE.type, param, true);
    },
    /**
     * PGR.2 招募明细
     * @see https://runsa.yuque.com/runsadev/ir5wcy/pvnwdf
     */
    pgReportRecruitDetail: (param: IReportRankingSiteGuiderListParam): Promise<historyRecruitResponsePG> => {
        return baseService.service(
            PGConfig.PG_REPORT_RECRUIT_DETAIL.url,
            PGConfig.PG_REPORT_RECRUIT_DETAIL.type,
            param,
            true,
        );
    },
    /**
     * PGR.1-2 导购/店铺-主推商品销售概览
     * @see https://runsa.yuque.com/runsadev/ir5wcy/ldhpzl
     */
    pgReportProductPerformance: (param: IReportRankingSiteGuiderListParam): Promise<historyRecruitResponsePG> => {
        return baseService.service(
            PGConfig.PG_REPORT_PRODUCT_PERFORMANCE.url,
            PGConfig.PG_REPORT_PRODUCT_PERFORMANCE.type,
            param,
            true,
        );
    },
    /**
     * PGR.3 会员画像明细
     * @see https://runsa.yuque.com/runsadev/ir5wcy/ym8vcl
     */
    pgReportMemberStructure: (
        param: IReportRankingSiteGuiderListParam,
    ): Promise<historyCustomerResponse<historyCustomerListItem>> => {
        return baseService.service(
            PGConfig.PG_REPORT_MEMBER_STRUCTURE.url,
            PGConfig.PG_REPORT_MEMBER_STRUCTURE.type,
            param,
            true,
        );
    },
    /**
     * PGR.4 主推商品-店铺业绩明细
     * @see https://runsa.yuque.com/runsadev/ir5wcy/uqmyam
     */
    pgReportProductSiteDetail: (param) => {
        return baseService.service(
            PGConfig.PG_REPORT_PRODUCT_SITE_DETAIL.url,
            PGConfig.PG_REPORT_PRODUCT_SITE_DETAIL.type,
            param,
            true,
        );
    },
    /**
     * PGR.4-2 主推商品-我的业绩明细
     * @see https://runsa.yuque.com/runsadev/ir5wcy/uid249
     */
    pgReportproductGuiderDetail: (param) => {
        return baseService.service(
            PGConfig.PG_REPORT_PRODUCT_GUIDER_DETAIL.url,
            PGConfig.PG_REPORT_PRODUCT_GUIDER_DETAIL.type,
            param,
            true,
        );
    },
    /**
     * PGR.5 销售明细
     * @see https://runsa.yuque.com/runsadev/ir5wcy/vwmak2
     */
    pgReportConsumptionsDetail: (param) => {
        return baseService.service(
            PGConfig.PG_REPORT_CONSUMPTIONS_DETAIL.url,
            PGConfig.PG_REPORT_CONSUMPTIONS_DETAIL.type,
            param,
            true,
        );
    },
    pgReportConsumeOrderList: (param) => {
        return baseService.service(
            PGConfig.PG_REPORT_CONSUME_ORDER_LIST.url,
            PGConfig.PG_REPORT_CONSUME_ORDER_LIST.type,
            param,
            true,
        );
    },
    /**
     * PGR.6 会员消费结构
     * @see https://runsa.yuque.com/runsadev/ir5wcy/ast2va
     */
    pgReportConsumptionsStructure: (param) => {
        return baseService.service(
            PGConfig.PG_REPORT_CONSUMPTION_STRUCTURE.url,
            PGConfig.PG_REPORT_CONSUMPTION_STRUCTURE.type,
            param,
            true,
        );
    },
    /**
     * PGR.6-2 会员消费标签
     * @see https://runsa.yuque.com/runsadev/ir5wcy/tdp65w
     */
    pgReportConsumptionsTag: (param) => {
        return baseService.service(
            PGConfig.PG_REPORT_CONSUMPTION_TAG.url,
            PGConfig.PG_REPORT_CONSUMPTION_TAG.type,
            param,
            true,
        );
    },
    /**
     * PGR.6-3 会员消费KPI对比
     * @see https://runsa.yuque.com/runsadev/ir5wcy/rqfwcy
     */
    pgReportConsumptionsKpi: (param) => {
        return baseService.service(
            PGConfig.PG_REPORT_CONSUMPTION_KPI.url,
            PGConfig.PG_REPORT_CONSUMPTION_KPI.type,
            param,
            true,
        );
    },
    /**
     * PGR.7 导购的销售以及招募数据
     * @see https://runsa.yuque.com/runsadev/ir5wcy/fgos7y
     */
    pgReportGuiderSaleList: (param) => {
        return baseService.service(
            PGConfig.PG_REPORT_GUIDER_SALE_LIST.url,
            PGConfig.PG_REPORT_GUIDER_SALE_LIST.type,
            param,
            true,
        );
    },
    /**
     * PGR.2-2 招募明细 - 招募趋势对比
     * @see https://runsa.yuque.com/runsadev/ir5wcy/ii3mnz
     */
    pgReportRecruitTrend: (param) => {
        return baseService.service(
            PGConfig.PG_REPORT_RECRUIT_TREND.url,
            PGConfig.PG_REPORT_RECRUIT_TREND.type,
            param,
            true,
        );
    },
    /**
     * PGR.2-3 招募明细 - 渠道
     * @see https://runsa.yuque.com/runsadev/ir5wcy/hb9ifg
     */
    pgReportRecruitChannel: (param) => {
        return baseService.service(
            PGConfig.PG_REPORT_RECRUIT_CHANNEL.url,
            PGConfig.PG_REPORT_RECRUIT_CHANNEL.type,
            param,
            true,
        );
    },
    /**
     * PGR.8 排行榜-网点所在区域树
     * @see https://runsa.yuque.com/runsadev/ir5wcy/xgyhkg
     */
    pgReportRankRegionList: (param) => {
        return baseService.service(
            PGConfig.PG_REPORT_RANKING_REGION_LIST.url,
            PGConfig.PG_REPORT_RANKING_REGION_LIST.type,
            param,
            true,
        );
    },
    /**
     * PGR.9 获取店铺主打品牌
     * @see https://runsa.yuque.com/runsadev/ir5wcy/fxngs1
     */
    pgReportRankMainBrand: (param) => {
        return baseService.service(
            PGConfig.PG_REPORT_RANKING_MAIN_BRAND.url,
            PGConfig.PG_REPORT_RANKING_MAIN_BRAND.type,
            param,
            true,
        );
    },
    /**
     * PGR.10 排行榜-排行指标
     * @see https://runsa.yuque.com/runsadev/ir5wcy/mn48cq
     */
    pgReportRankPki: (param) => {
        return baseService.service(PGConfig.PG_REPORT_RANKING_KPI.url, PGConfig.PG_REPORT_RANKING_KPI.type, param, true);
    },
    /**
     * PGR.11 排行榜-店铺/导购 排行榜详情
     * @see https://runsa.yuque.com/runsadev/ir5wcy/btlxu8
     */
    pgReportRankDetail: (param) => {
        return baseService.service(
            PGConfig.PG_REPORT_RANKING_DETAIL.url,
            PGConfig.PG_REPORT_RANKING_DETAIL.type,
            param,
            true,
        );
    },
    /**
     * PGR.12 排行榜-店铺/导购 排行榜列表
     * @see https://runsa.yuque.com/runsadev/ir5wcy/qindvs
     */
    pgReportRankList: (param) => {
        return baseService.service(PGConfig.PG_REPORT_RANKING_LIST.url, PGConfig.PG_REPORT_RANKING_LIST.type, param, true);
    },
    /**
     * L23.2 获取集团通用配置
     * @see http://yapi.tenyun.runsasoft.com/project/38/interface/api/2022
     */
    dzGroupCommonConfig: (param) => {
        return baseService.service(PGConfig.DZ_GROUP_COMMON_CONFIG.url, PGConfig.DZ_GROUP_COMMON_CONFIG.type, param, true);
    },
    /**
     * PGR.2-4 招募明细 - 期间会员入会率
     * @see https://yapi.tenyun.runsasoft.com/project/38/interface/api/3926
     */
    dzReportRecruitViprate: (param) => {
        return baseService.service(
            PGConfig.DZ_REPORT_RECRUIT_VIPRATE.url,
            PGConfig.DZ_REPORT_RECRUIT_VIPRATE.type,
            param,
            true,
        );
    },

    dzNoAuthJSSDKSignPackage: (param) => {
        return baseService.service(
            PGConfig.DZ_NO_AUTH_JSSDK_SIGN_PACKAGE.url,
            PGConfig.DZ_NO_AUTH_JSSDK_SIGN_PACKAGE.type,
            param,
            true,
        );
    },
    /*
     企微拉新排行榜(C端)
     https://yapi.tenyun.runsasoft.com/project/97/interface/api/7520
    */
    crmLxsmToplist: (param={}) => {
        return baseCrmService.service(crmConfig.CRM_LXSM_TOPLIST.url, crmConfig.CRM_LXSM_TOPLIST.type, param, true);
    },
    crmLxsmMemberInfo: (param = {}) => {
        return baseCrmService.service(crmConfig.CRM_LXSM_MEMBER_INFO.url, crmConfig.CRM_LXSM_MEMBER_INFO.type, param, true);
    },
    crmLxsmFindChat: (param={}) => {
        return baseCrmService.service(crmConfig.CRM_LXSM_FIND_CHAT.url, crmConfig.CRM_LXSM_FIND_CHAT.type, param, true);
    },
    crmLxsmFindActivity: (param={}) => {
        return baseCrmService.service(crmConfig.CRM_LXSM_FIND_ACTIVITY.url, crmConfig.CRM_LXSM_FIND_ACTIVITY.type, param, true);
    },
    crmLxsmGetChatUrl: (param) => {
        return baseCrmService.service(crmConfig.CRM_LXSM_GET_CHAT_URL.url, crmConfig.CRM_LXSM_GET_CHAT_URL.type, param, true);
    },
    crmLxsmChatMemberInfo: (param={}) => {
        return baseCrmService.service(crmConfig.CRM_LXSM_CHAT_MEMBER_INFO.url, crmConfig.CRM_LXSM_CHAT_MEMBER_INFO.type, param, true);
    },

    rstoreQywxQSopTask: (param={}) => {
        return baseCrmService.service(crmConfig.RSTORE_QYWX_QSOP_TASK.url, crmConfig.RSTORE_QYWX_QSOP_TASK.type, param, true);
    },

    rstoreQywxQSopTaskExecute: (param = {}) => {
        return baseCrmService.service(crmConfig.RSTORE_QYWX_QSOP_EXECUTE.url, crmConfig.RSTORE_QYWX_QSOP_EXECUTE.type, param, true);
    },
    rstoreTaskSopItemFind: (param = {}) => {
        return newService(crmConfig.RSTORE_TASK_SOP_ITEM.url, crmConfig.RSTORE_TASK_SOP_ITEM.type, param, true);
    },
    rstoreGetQrcode: (param = {}) => {
        return baseCrmService.service(crmConfig.RSTORE_GET_QRCODE.url, crmConfig.RSTORE_GET_QRCODE.type, param, true);
    }
};
