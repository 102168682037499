// 会员画像
import * as F2 from "@antv/f2";
import * as $ from "jquery";
import GuiderList from "@/components/guiderList";
import eventBus from "@/util/eventBus";
import service from "../../service/index";
import { formatNum, createCircleChart, createPieChart } from "@/util/utils";
import PieLabel from "@antv/f2/lib/plugin/pie-label";

let selectedItem: string; // 下拉选中项

/**
 * 性别报表
 */
function createSexDisChart(list: any[]): void {
  if (list.length === 0) {
    $("#sexDisChartWrap").html(
      `<div class="no-data"><i class="icon-kongshuju iconfont"></i><div>无数据</div></div>`
    );
  } else {
    const data: any = [];
    list.forEach(item => {
      data.push({
        name: item.name,
        y: item.val,
        const: "const",
      });
    });

    const chart = new F2.Chart({
      id: "sexDisChart",
      pixelRatio: window.devicePixelRatio,
      plugins: PieLabel,
    });

    chart.source(data);
    chart.coord("polar", {
      transposed: true,
      radius: 0.75,
    });
    chart.legend({
      position: "bottom",
      align: "center",
    });
    chart.axis(false);
    chart.tooltip(false);

    // 添加饼图文本
    chart.pieLabel({
      sidePadding: 30,
      label1: function label1(datas: any, color: any) {
        return {
          text: datas.name,
          fill: color,
        };
      },
      label2: function label2(datas: any) {
        return {
          text: String(Math.floor(datas.y * 100) / 100).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          ),
          fill: "#808080",
          fontWeight: "bold",
        };
      },
    });

    chart
      .interval()
      .position("const*y")
      .color("name", ["#1890FF", "#13C2C2", "#2FC25B", "#FACC14", "#F04864"])
      .adjust("stack");
    chart.render();
  }
}

/**
 * 清除图表缓存
 * @param {boolean} isOnly 是否只更新customerDistribution
 */
function initCanvas(isOnly: boolean) {
  $("#customerDistributionChartWrap").html(
    '<canvas style="width: 100%; height: 100%;" id="customerDistributionChart"></canvas>'
  );
    $("#sexDisChartWrap").html(
      '<canvas style="width: 100%; height: 100%;" id="sexDisChart"></canvas>'
    );
    $("#customerLevelDisWrap").html(
      '<canvas style="width: 100%; height: 100%;" id="customerLevelDis"></canvas>'
    );
    $("#customerAgeDisWrap").html(
      '<canvas style="width: 100%; height: 100%;" id="customerAgeDis"></canvas>'
    );
}

/**
 * 数据请求
 */
function getData(isOnly: boolean = false, param?) {
    getChartData(selectedItem, isOnly, param);
  // if (!selectedItem) {
  //   service
  //     .customerTags(param)
  //     .then((result: customerTags) => {
  //       const content = result.content;
  //       let html = "";
  //       if (content.list.length > 0) {
  //         $("#customerConsume").show();
  //       } else {
  //         $("#customerConsume").hide();
  //       }
  //       content.list.forEach((item, index) => {
  //         if (index === 0) {
  //           $("#selectContent").text(item.tagName);
  //         }
  //         html += `<li class="${index === 0 ? "selected" : ""}" fieldId="${
  //           item.fieldId
  //         }" tagId="${item.tagId}">${item.tagName}</li>`;
  //       });
  //       $(".portrait-select ul").html(html);
  //       const tagId =
  //         (content.list && content.list.length && content.list[0].tagId) || "";
  //       getChartData(tagId,false,param);
  //       // getChartData('1235678');
  //       selectedItem = tagId;
  //     })
  //     .catch((error: any) => {
  //       getChartData("",false,param);
  //       console.log(error);
  //     });
  // } else {
  //   getChartData(selectedItem, isOnly, param);
  //   // getChartData('1235678', isOnly);
  // }
}

/**
 * 获取表格数据
 * @param tagId
 * @param isOnly 是否只更新customerDistribution
 */
function getChartData(tagId: string, isOnly: boolean = false, param?) {
  const chooseItems = ["customerLevelDis", "customerAgeDis", "customerSexDis"];
  if (tagId) {
    chooseItems.push("customerDistribution");
  }
  const setParam = {...{guiderId:localStorage.getItem("guiderId")},...param};
  service
    .historyCustomer({
      ...{
        items: isOnly ? ["customerDistribution"] : chooseItems,
        conditions: {
          customerDistribution: { chooseItem: tagId },
        },
      },
      ...setParam,
    })
    .then((result: historyCustomerResponse<historyCustomerListItem>) => {
      const content = result.content;
      initCanvas(isOnly);

      setTimeout(()=>{
          if (content.customerDistribution && content.customerDistribution.list) {
              $("#customerDistributionChartWrap").show();
              createPieChart(
                  content.customerDistribution.list,
                  "customerDistributionChart",
                  {
                      pieLabel: {
                          sidePadding: 35,
                          label1: function label1(data: any) {
                              return {
                                  text: `${data.name}  ${data.percent}%`,
                                  fill: "#000",
                              };
                          },
                          label2: function label2(data: any) {
                              return {
                                  fill: "#999999",
                                  text: data.val ? formatNum(data.val.toFixed(2)):'',
                                  fontWeight: 500,
                                  fontSize: 10,
                              };
                          },
                      },
                      guide: (total: number, fontSize: number) => {
                          return `<span style="color: #999;white-space:nowrap;font-size: ${fontSize *
                          0.2}px">会员总数</span><br><span style="font-size: ${fontSize *
                          0.24}px">${formatNum(total)}</span>`;
                      },
                  }
              );
          } else {
              $("#customerDistributionChartWrap").hide();
          }

          if (content.customerLevelDis && content.customerLevelDis.list) {
              createPieChart(content.customerLevelDis.list, "customerLevelDis", {
                  pieLabel: {
                      sidePadding: 35,
                      label1: function label1(data: any) {
                          return {
                              text: data.name,
                              fill: "#000",
                          };
                      },
                      label2: function label2(data: any) {
                          return {
                              fill: "#999999",
                              text: data.val ? formatNum(data.val.toFixed(2)):'',
                              fontWeight: 500,
                              fontSize: 10,
                          };
                      },
                  },
                  guide: (total: number, fontSize: number) => {
                      return `<span style="color: #999;white-space:nowrap;font-size: ${fontSize *
                      0.2}px">总计</span><br><span style="font-size: ${fontSize *
                      0.24}px">${formatNum(total)}</span>`;
                  },
              });
          }

          if (content.customerAgeDis && content.customerAgeDis.list) {
              createPieChart(content.customerAgeDis.list, "customerAgeDis", {
                  pieLabel: {
                      sidePadding: 35,
                      label1: function label1(data: any) {
                          return {
                              text: `${data.name}  ${data.percent}%`,
                              fill: "#000",
                          };
                      },
                      label2: function label2(data: any) {
                          return {
                              fill: "#999999",
                              text: data.val ? formatNum(data.val.toFixed(2)):'',
                              fontWeight: 500,
                              fontSize: 10,
                          };
                      },
                  },
                  guide: (total: number, fontSize: number) => {
                      return `<span style="color: #999;white-space:nowrap;font-size: ${fontSize *
                      0.2}px">总计</span><br><span style="font-size: ${fontSize *
                      0.24}px">${formatNum(total)}</span>`;
                  },
              });
          }

          if (content.customerSexDis && content.customerSexDis.list) {
              createSexDisChart(content.customerSexDis.list);
          }
      })


    })
    .catch((error: any) => {
      console.log(error);
    });
}

/**
 * 获取会员画像说明
 */
function getExtendInfo() {
  service.extendInfo().then((result: extendInfoResponse) => {
    if (
      result.content.functional_module_desc.Customer &&
      result.content.functional_module_desc.Customer !== ""
    ) {
      $(".warning").show();
      $("#dialogCover .content").html(
        result.content.functional_module_desc.Customer
      );
    } else {
      $(".warning").hide();
    }
  });
}

/**
 * 页面事件
 */
function pageEvents() {

  $("#showDialog").click(() => {
    $("#dialogCover").show();
    $("body").addClass("lock-scroll");
    $("html").addClass("lock-scroll");
  });

  $("#closeDialog").click(() => {
    $("#dialogCover").hide();
    $("body").removeClass("lock-scroll");
    $("html").removeClass("lock-scroll");
  });

  $(".top-tap-wrap span").click(function() {
    const type = $(this).attr("data-type");
    $(this)
      .addClass("tap-on-selected")
      .siblings()
      .removeClass("tap-on-selected");
    if (type === "1") {
      window.localStorage.setItem("isGuider", "1");
      $(".data-select-wrap").hide();
      $("#selectContent").text("活跃状态");
      selectedItem = "";
      getData();
    } else {
      window.localStorage.setItem("isGuider", "0");
      $(".data-select-wrap").show();
      (window as any).guiderId = $("#guiderSelect").attr("data-id");
      (window as any).empCode = $("#guiderSelect").attr("data-code");
      $("#selectContent").text("活跃状态");
      selectedItem = "";
      eventBus.emit("taskCenterOnSelectGuider", {
        empCode: "",
        empName: "全店",
        guiderId: ""
      });
    }

  });

  $(".portrait-select ul").on("click", "li", function(event: any) {
    $("#selectContent").text($(this).text());
    selectedItem = $(this).attr("tagid");
    getData(true);
    $(".portrait-select ul").hide();
    $(".popupMask").hide();
    $(this)
      .addClass("selected")
      .siblings()
      .removeClass("selected");
  });

  $("#selectTop").click(() => {
    if ($(".portrait-select ul").is(":hidden")) {
      $(".portrait-select ul").show();
      $(".popupMask").show();
    } else {
      $(".portrait-select ul").hide();
      $(".popupMask").hide();
    }
  });

  $(".popupMask").click(() => {
    $(".guider-list").hide();
    $(".popupMask").hide();
    $(".portrait-select ul").hide();
  });
}

function getGuiderList() {
  service
    .siteGuiderList()
    .then((data: any) => {
      $("#siteGuider").empty();
      $("#siteGuider").html(data && data.content);
    })
    .catch(error => {
      console.log(error);
    });
}

const getPageData = (guiderId, empCode) => {
  const param = { guiderId, empCode };
  console.log(param);

  getData(false, param);
};

function getIsvMPConfig() {
  service
    .isvMPConfig()
    .then(result => {
      if (result.content.mpCode !== "MPRStore") {
        $("#homeTab").show();
      } else {
        $("#homeTab").hide();
      }
    })
    .catch(error => {
      console.log(error);
    });
}

function sale_divide() {
  service
    .contactMemberGet({ appointedGuiderId: localStorage.getItem("guiderId") })
    .then((resp: any) => {
      const siteRole = window.localStorage.getItem("siteRole");

      if (siteRole !== "101" && +resp.content.performanceType !== 1) {
        $(".top-tap-wrap").hide();
        $(".guider-tip-wrap").show();
      }
    });
}

import { Component, Vue } from "vue-property-decorator";
import _Template from "./portrait-temp.vue";
import HelpTips from "@/components/helpTips";

@Component({
  mixins: [_Template],
  components: { GuiderList,HelpTips },
})
export default class Portrait extends Vue {
  protected guiderList: any[] = [];

  mounted() {
    document.title = "会员画像";
    window.localStorage.setItem("isGuider", "1");
    (window as any).empCode = "";
    $("body").show();
    pageEvents();
    getIsvMPConfig();
    getData();
    getExtendInfo();
    getGuiderList();
    sale_divide();
    this.getGuiderList();
  }
  getData(guiderId, empCode) {
    getPageData(guiderId, empCode);
  }
  showTipDialog(){
    $("#dialogCover").show();
  }
  getGuiderList() {
    service
      .siteGuiderList()
      .then((data: any) => {
        this.guiderList = data.content.list;
      })
      .catch(error => {
        console.log(error);
      });
  }
}
