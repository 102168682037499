import { render, staticRenderFns } from "./portrait-temp.vue?vue&type=template&id=502dbce0&scoped=true&"
var script = {}
import style0 from "./portrait-temp.vue?vue&type=style&index=0&id=502dbce0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "502dbce0",
  null
  
)

export default component.exports