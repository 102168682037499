import { Component, Prop, Vue } from "vue-property-decorator";
import _Template from "./style.vue";
import * as $ from "jquery";
import service from "@/service/index";
import DzMenu from "../dzMenu";
import eventBus from "@/util/eventBus";

interface ISelectItem {
  frame?: string;
  explain?: string;
}

@Component({
  mixins: [_Template]
})
export default class StractList extends Vue {
  @Prop({ default: {} })
  selectPowerData;

  public isMainMenuWrapperShow: boolean = false;

  public selectItem: ISelectItem = {};

  protected defPowerList: any[] = [
    {
      empName: "首单/回购",
      empCode: "3"
    },
    {
      empName: "单次客/多次客",
      empCode: "4"
    },
    {
      empName: "生日客/非生日客",
      empCode: "1"
    },
    {
      empName: "用券客/非用券客",
      empCode: "2"
    }
    // {
    //   empName: "沉默客/非沉默客",
    //   empCode: "5"
    // }
  ];
  // 菜单 点击事件
  public onMenuItemClick(e, item) {
    this.isMainMenuWrapperShow = false;
    this.selectPower(item);
  }
  public showMenu() {
    this.isMainMenuWrapperShow = true;
  }

  public selectPower(item) {
    if (!item) { return; }
    const { empName, empCode } = item;
    this.selectItem = item;
    $("#powerSelect").text(empName);
    this.selectPowerData(item);
    $(".guider-list").hide();
    $(".popupMask").hide();
  }
  mounted() {
    eventBus.on("taskCenterOnSelectStract", item => {
      this.selectPower(item);
    });
  }
  render(h) {
    return (
      <div class="data-select-wrap-stract">
        <div class="data-select">
          <span
            onClick={() => {
              this.showMenu();
            }}
            class="date-text"
            id="powerSelect"
          >
            {this.defPowerList[0].empName}
          </span>

          {this.isMainMenuWrapperShow ? (
            <i class="iconfont icon-moreunfold" style="position: static;" />
          ) : (
            <i
              class="iconfont icon-moreunfold"
              style="position: static;color:#2c3e50;"
            />
          )}

          <DzMenu
            label="empName"
            v-model={this.isMainMenuWrapperShow}
            menuItems={this.defPowerList}
            onMenuItemClick={(e, item) => this.onMenuItemClick(e, item)}
          />
        </div>
      </div>
    );
  }
}
