import Vue from "vue";
import globalDialog from "./global-dialog";
import defaultDialog from "./dialog";
export type GlobalDialogTypes = ReturnType<typeof globalDialog>;
export default (options?) => {
  /**
   * options 预留配置
   *
   * 可定制默认弹窗组件。
   *
   * 自定义组件需要实现
   * function show():void {}
   * function hide():void {}
   */
  Vue.prototype.$dz_dlg = function(arg) {
    return globalDialog(defaultDialog)({ parent: this, ...arg });
  };
};
