import { Component, Vue, Prop } from "vue-property-decorator";
import _Template from "./style.vue";

@Component({
  mixins: [_Template],
})
export default class DzTabs extends Vue {
  @Prop({ default: () => [] })
  public tabs: { label: string; value: number; visitNum?: number }[];
  @Prop()
  public curTabIndex: number;

  @Prop()
  value: any;

  @Prop({ default: false })
  useValue: boolean;

  @Prop({ default: false })
  adaptive: boolean; // 是否自适应

  @Prop({ default: "" })
  addLabel: string; //

  protected mounted() {
    if(!this.useValue){
      if (this.tabs.length && !this.curTabIndex) {
        this.curTabIndex = this.tabs[0].value;
      }
    }
  }
  protected onTabItemClick(tabVal) {
    this.$emit("tabClick", tabVal);
    this.$emit("input", tabVal);
  }

  protected render(h) {
    return (
      <div class={{ "dz-tabs": true, adaptive: this.adaptive }} v-show={this.tabs.length > 0}>
        {(this.tabs || []).map((d) => {
          let isActive = this.curTabIndex === d.value;
          if (this.useValue) {
            isActive = this.value === d.value;
          }
          return (
            <div class={isActive ? "tabItem selectedTab" : "tabItem"} onClick={() => this.onTabItemClick(d.value)}>
              {this.addLabel ? [<span>{d.label}</span>, d.visitNum > 0 && <i class="red-dot" />] : d.label}
            </div>
          );
        })}
      </div>
    );
  }
}
