import * as $ from "jquery";
import Service from "../service/index";
// 支持和写了函数的小程序功能。
export type JSSDKOption = "scanQRCode" | "navigateTo" | "redirectTo";

class InitJSSDK {
    // 单例
    protected singleton: boolean = false;
    // location href url
    protected url: string = decodeURI(window.location.href.split("#")[0]);
    // 请求的接口
    protected requestUrl: string = "/dzapi/mp/wx/jssdk/sign/package";
    // TOKEN
    protected token: string = window.localStorage.getItem("token");

    // 请求的参数信息
    protected requestParam: {
        groupId?: string;
        wxAppid?: string;
        url: string;
        isWxWork?: number;
    } = {
        // wxAppid: "wxcc8de8e3697ecfc9",
        // groupId: "luxbatest",
        url: this.url,
    };

    // 是否是企业微信
    protected isWxWork: 1 | undefined = null;

    public Init(option: JSSDKOption, callBack: (argument) => void) {
        // 获取是不是企业微信
        this.isWxWork = this.getCtx();

        return this.getConfig().then(() => {
            this[option](callBack);
        });
    }

    protected getCtx(): 1 | undefined {
        const ua = window.navigator.userAgent.toLowerCase();
        // 是不是微信的环境 && 企业微信
        return ua.indexOf("micromessenger") !== -1 && ua.indexOf("wxwork") !== -1 ? 1 : undefined;
    }

    protected navigateTo(callback): void {
        setTimeout(() => {
            callback();
        }, 500);
    }

    protected getCurrentUser(callback) {
        setTimeout(() => {
            callback();
        }, 500);
    }

    protected redirectTo(callback): void {
        setTimeout(() => {
            callback();
        }, 500);
    }

    protected scanQRCode(callBack): void {
        setTimeout(() => {
            (window as any).wx.scanQRCode({
                desc: "scanQRCode desc",
                needResult: 0, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
                scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success(res) {
                    // 回调
                },
                error(res) {
                    //
                },
            });
        }, 500);
    }

    protected getConfig(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (this.singleton) {
                resolve(null);
                return;
            }
            const wxWork = this.isWxWork;

            // if (wxWork) {
            //   this.requestParam.isWxWork = wxWork;
            //   this.requestParam.wxAppid = "ww53d5f7312ece9f69";
            // }


            this.fetch().then((response) => {
                console.log("config==>" + JSON.stringify(response));
                console.log('window,wx',window,(window as any).wx)
                const content = response.content;
                (window as any).wx.config({
                    beta: true,
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: wxWork ? content.appId : content.wxAppId, // 必填，企业号的唯一标识，此处填写企业号corpid
                    timestamp: content.timestamp, // 必填，生成签名的时间戳（10位）
                    nonceStr: content.nonceStr, // 必填，生成签名的随机串,注意大小写
                    signature: content.signature, // 必填，签名，
                    jsApiList: [
                        "invokeMiniProgramAPI",
                        "translateVoice",
                        "checkJsApi",
                        "onMenuShareAppMessage",
                        "onMenuShareWechat",
                        "onMenuShareTimeline",
                        "shareAppMessage",
                        "shareWechatMessage",
                        "startRecord",
                        "stopRecord",
                        "onVoiceRecordEnd",
                        "playVoice",
                        "pauseVoice",
                        "stopVoice",
                        "uploadVoice",
                        "downloadVoice",
                        "chooseImage",
                        "previewImage",
                        "uploadImage",
                        "downloadImage",
                        "getNetworkType",
                        "openLocation",
                        "getLocation",
                        "hideOptionMenu",
                        "showOptionMenu",
                        "hideMenuItems",
                        "showMenuItems",
                        "hideAllNonBaseMenuItem",
                        "showAllNonBaseMenuItem",
                        "closeWindow",
                        "scanQRCode",
                        "previewFile",
                        "openEnterpriseChat",
                        "selectEnterpriseContact",
                        "onHistoryBack",
                        "openDefaultBrowser",
                    ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                });

                (window as any).wx.ready(() => {
                    resolve(null);
                });
                (window as any).wx.error((res) => {
                    console.log("wx.error===>" + JSON.stringify(res));
                });
                this.singleton = true;

            });
        });
    }

    fetch() {
        return new Promise<any>((resolve, reject) => {
            if (!this.token) {
                this.requestUrl = "/dzapi/noauth/mp/wx/jssdk/sign/package"
            }
            $.ajax({
                type: "POST",
                url: this.requestUrl,
                data: JSON.stringify(this.requestParam),
                headers: this.token ? {
                    Authorization: `Bearer ${this.token}`,
                } : undefined,
                contentType: "application/json; charset=utf-8",
                success: (response: any) => {
                    resolve(response);
                },
                error: (apiError: any) => {
                    console.log("error==>" + JSON.stringify(apiError));
                    setTimeout(() => {
                        reject(apiError);
                    }, 1000);
                },
            });

        });
    }
}

export default new InitJSSDK();
