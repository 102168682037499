import { Component, Prop, Vue, Watch, Model } from 'vue-property-decorator';
import _Template from './style.vue';
import * as $ from 'jquery';
import service from '@/service/index';
import DzMenu from '../dzMenu';
import eventBus from '@/util/eventBus';

@Component({
  mixins: [_Template]
})
export default class DzSelectBox extends Vue {
  @Model('input')
  value;

  @Prop()
  list: any[];
  @Prop({ default: 'id' })
  idKey: string;
  @Prop({ default: 'name' })
  nameKey: string;
  // // 选择ID
  // @Prop()
  // selectedId: string;

  // // 是否默认选择第一个
  // @Prop({ default: true })
  // selectedFirst: boolean;

  public isMainMenuWrapperShow: boolean = false;
  public selectItem: any = null;

  @Watch('value', { deep: true, immediate: true })
  watchValue(newVal, oldVal) {
    this.selectItem = newVal
      ? this.list.find(d => d[this.idKey] === newVal[this.idKey])
      : null;
  }
  // 菜单 点击事件
  public onMenuItemClick(e, item) {
    this.isMainMenuWrapperShow = false;
    this.selectItemEvent(item);
  }
  public showMenu() {
    this.isMainMenuWrapperShow = true;
  }

  public selectItemEvent(item) {
    if (this.selectItem[this.idKey] !== item[this.idKey]) {
      const oldSelectItem = this.selectItem
        ? JSON.parse(JSON.stringify(this.selectItem))
        : null;
      this.selectItem = item;
      this.$emit('input', JSON.parse(JSON.stringify(item)));
      this.$emit('change', this.selectItem, oldSelectItem);
    }
  }

  mounted() {
    this.selectItem =this.value
    // if (!this.selectedId && this.selectedFirst && this.list && this.list[0]) {
    //   this.selectItem = this.list[0];
    // }
    // eventBus.on('taskCenterOnSelectGuider', item => {
    //   this.selectItemEvent(item);
    // });
  }

  render(h) {
    return (
      <div class='data-select-wrap'>
        <div class='data-select'>
          <span
            onClick={() => {
              this.showMenu();
            }}
            class='date-text'
          >
            {this.selectItem ? this.selectItem[this.nameKey] : '暂无'}
          </span>

          <i class='iconfont icon-moreunfold' style='position: static;color:#2c3e50;' />

          <DzMenu
            label={this.nameKey}
            v-model={this.isMainMenuWrapperShow}
            menuItems={this.list}
            onMenuItemClick={(e, item) => this.onMenuItemClick(e, item)}
          />
        </div>
      </div>
    );
  }
}
