import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Dialog from "@/components/dialog/install";
// 或者使用配置参数来初始化，详情见文档
// tslint:disable-next-line: no-var-requires
const pack = require("../package.json");
import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)
Vue.config.productionTip = false;

// import VConsole from 'vconsole';
// const vConsole = new VConsole();


Dialog();

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
