import * as $ from "jquery";
import config from "./config";
import {BaseHttpRequestOption, HttpMethod} from "qingger-lib";
// import { ErrCode } from "@/exception/ExceptionCode";
import merge from "lodash/merge";
import {BaseHttpRequestParams} from "qingger-lib/library/httpClient/baseHttpClientType";

const env = process.env.NODE_ENV;

export function JsonToUrlParam(json: {} = {}): string {
    return Object.keys(json)
        .map((key) => {
            return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
        })
        .join("&");
}

const caches: any = {};
export default {
    service: (url: string, type: string, params = {}, cancel: boolean = false): Promise<any> => {
        const urls = url;

        if (urls in caches && cancel) {
            caches[urls].abort();
        }
        return new Promise<any>((resolve, reject) => {
            let data: any = null;

            const id = window.localStorage.getItem("id") || getParams('id');
            const corpId = window.localStorage.getItem("corpId") || getParams("corpId")
            const uId = +window.localStorage.getItem("uId") || getParams('uId');
            const batchId = +window.localStorage.getItem("batchId") || getParams('batchId');
            const token = window.localStorage.getItem("token") || getParams('token');
            const tenCode = window.localStorage.getItem("tenCode") || getParams("tenCode")

            const urlParams = JsonToUrlParam(params);
            const reg = /\{(.+?)\}/

            const replaceStr = Object.keys(params).length ? Object.values(params) + "" : id
            const path = url.match(reg) ? urls.replace(reg, replaceStr) : urls
            data = {
                path: path + `?id=${id}&uId=${uId}&batchId=${batchId}&corpId=${corpId}&${urlParams}`,
                method: type,
                body: {...params,},
                corpId: tenCode || corpId
            };
            caches[urls] = $.ajax({
                type: "POST",
                url: (env !== "development" ? "dzapi" : "") + '/platform/common/call/v2',

                data: JSON.stringify(data),
                contentType: "application/json; charset=utf-8",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                success: (resp: any) => {
                    resolve(resp);
                },
                error: (apiError: any) => {
                    if (apiError.statusText === "abort") {
                        reject("abort");
                    } else if (apiError.responseJSON.code === 12002) {

                    } else {
                        alert(apiError.responseText);
                        reject(apiError);
                    }
                },
            });
        });
    },
};
//isH5是否是H5页面登录
export function newService(url: string, type: string, params = {}, cancel: boolean = false, isH5 = false) {
  const urls = url;

  if (urls in caches && cancel) {
    caches[urls].abort();
  }
  return new Promise<any>((resolve, reject) => {
    let data: any = null;

    const id = window.localStorage.getItem("id") || getParams('id');
    const corpId = window.localStorage.getItem("corpId") || getParams("corpId")

    const urlParams = JsonToUrlParam(params);
    const reg = /\{(.+?)\}/

    const replaceStr = Object.keys(params).length ? Object.values(params) + "" : id
    const path = url.match(reg) ? urls.replace(reg, replaceStr) : urls
    data = {
      path: path + `?id=${id}&corpId=${corpId}`,
      method: type,
      body: {...params,},
    };
    if (isH5) {
      data["qwCorpId"] = corpId
    } else {
      data["corpId"] = corpId
    }
    caches[urls] = $.ajax({
      type: "POST",
      url: (env !== "development" ? "dzapi" : "") + '/platform/common/call/sign',

      data: JSON.stringify(data),
      contentType: "application/json; charset=utf-8",
      success: (resp: any) => {
        resolve(resp);
      },
      error: (apiError: any) => {
        if (apiError.statusText === "abort") {
          reject("abort");
        } else if (apiError.responseJSON.code === 12002) {

        } else {
          alert(apiError.responseText);
          reject(apiError);
        }
      },
    });
  });
}
export function getParams(name) {
  const queryString = window.location.search.substring(1)
  if (!queryString) {
    return ""
  }
  const strArr = queryString.split("&")
  let keyValue = ''
  strArr.map(i => {
    const keyList = i.split("=")
    if (keyList[0] === name) {
      keyValue = keyList[1]
    }
  })
  return keyValue
}
