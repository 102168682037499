export default {
    tooken: {
        url: "/mp/auth/refresh/token",
        type: "POST",
    },
    dayArchive: {
        url: "/report/real/day/archive",
        type: "POST",
    },
    monthArchive: {
        url: "/report/real/month/archive",
        type: "POST",
    },
    historyRecruit: {
        url: "/report/history/recruit",
        type: "POST",
    },
    historyCustomer: {
        url: "/report/history/customer",
        type: "POST",
    },
    customerTags: {
        url: "/report/customer/tags",
        type: "POST",
    },
    historySale: {
        url: "/report/history/sale",
        type: "POST",
    },
    customerCount: {
        url: "/report/passenger/data",
        type: "POST",
    },
    customerCountCompare: {
        url: "/report/passenger/interval/data",
        type: "POST",
    },
    customerCountGet: {
        url: "/report/passenger/get",
        type: "POST",
    },
    guiderInfo: {
        url: "/mp/user/guider/info",
        type: "POST",
    },
    siteChange: {
        url: "/mp/user/site/change",
        type: "POST",
    },
    loginInfo: {
        url: "/mp/user/login/info",
        type: "POST",
    },
    extendInfo: {
        url: "/mp/isv/config/extend/info",
        type: "POST",
    },
    enterpriseConfig: {
        // 小程序对于企业的配置信息
        url: "/mp/config/info",
        type: "POST",
    },
    siteGuiderList: {
        url: "/site/guider/list",
        type: "POST",
    },
    siteProductSale: {
        url: "/report/site/product/sale",
        type: "POST",
    },
    isvMPConfig: {
        url: "/mp/isv/config/info",
        type: "POST",
    },
    targetConfigGet: {
        url: "/mp/group/target/config/get",
        type: "POST",
    },
    goodsListGet: {
        url: "/site/main/goods/list/get",
        type: "POST",
    },
    guiderTargetInfo: {
        url: "/report/site/guider/target/info",
        type: "POST",
    },
    productSalePerformance: {
        url: "/report/site/product/sale/performance",
        type: "POST",
    },
    contactMemberGet: {
        url: "/site/guider/contact/member/get",
        type: "POST",
    },
    taskTrackList: {
        url: "/dz/v2/task/track/list",
        type: "POST",
    },
    taskTrackListGroup: {
        url: "/dz/v2/task/track/list/group",
        type: "POST",
    },
    nearlyDaysCommunicate: {
        url: "/dz/v2/task/report/nearly/days/communicate",
        type: "POST",
    },
    reportCommunicateResult: {
        url: "/dz/v2/task/report/communicate/result",
        type: "POST",
    },
    taskReportimplementation: {
        url: "/dz/v2/task/report/implementation",
        type: "POST",
    },
    reportCommunicateConversionList: {
        url: "/dz/v2/task/report/communicate/conversion/list",
        type: "POST",
    },
    DZV2TaskInfo: {
        url: "/dz/v2/task/info",
        type: "POST",
    },
    reportExechGuiderList: {
        url: "/dz/v2/task/report/exec/guider/list",
        type: "POST",
    },

    T3111_TASK_STATUS_UPDATE: {
        url: "/dz/v2/task/status/update",
        type: "POST",
    },
    FT305_TASK_TRACK_LIST: {
        url: "/dz/v2/task/report/track/list",
        type: "POST",
    },
    TASK_REPORT_AFTER_SALE_IMP: {
        url: "/dz/v2/task/report/after/sale/implementation",
        type: "POST",
    },
    REPORT_GUIDER_RECRUIT_LIST: {
        url: "/report/guider/consume/recruit/list",
        type: "POST",
    },
    REPORT_SITE_PRODUCT_GUIDER_SALE: {
        url: "/report/site/product/guider/sale",
        type: "POST",
    },
    REPORT_SITE_PRODUCT_TOTAL_SALE: {
        url: "/report/site/product/total/sale",
        type: "POST",
    },
    REPORT_CONSUMPTION_BUSINESS_DATA: {
        url: "/report/consumption/business/data",
        type: "POST",
    },
    L23_MP_GROUP_ACHIEVEMENT_CONFIG_GET: {
        url: "/mp/group/achievement/config/get",
        type: "POST",
    },
    R14_REPORT_RANKING_SITE_MAIN_BRAND: {
        url: "/report/ranking/site/main/brand",
        type: "POST",
    },
    R15_REPORT_RANKING_SITE_REGION_LIST: {
        url: "/report/ranking/site/region/list",
        type: "POST",
    },
    R16_REPORT_RANKING_SITE_GUIDER_INFO: {
        url: "/report/ranking/site/guider/info",
        type: "POST",
    },
    R17_REPORT_RANKING_SITE_GUIDER_LIST: {
        url: "/report/ranking/site/guider/list",
        type: "POST",
    },
    GET_COMMUNICATE_CONFIG: {
        url: "/dz/v2/task/communicate/config",
        type: "POST",
    },
    T310_TASK_REPORT_EXEC_GUIDER_LIST: {
        url: "/dz/v2/task/report/exec/guider/list",
        type: "POST",
    },
    DZ_T39_TASK_INFO: {
        url: "/dz/v2/task/info",
        type: "POST",
    },
    POST_TASK_DAY_INFO: {
        url: "/dz/task/day/info",
        type: "POST",
    },
    TASK_DAY_CUSTOMER_LIST_V2: {
        url: "/dz/v2/task/day/customer/list",
        type: "POST",
    },
    DZ_V2_TASK_EXEC_INFO: {
        url: "/dz/v2/task/exec/info",
        type: "POST",
    },
    DZ_TASK_CUSTOMER_INFO: {
        url: "/dz/task/customer/info",
        type: "POST",
    },
    DZ_V2_TASK_CUSTOMER_EXEC_LIST: {
        url: "/dz/v2/task/customer/exec/list",
        type: "POST",
    },
    T314_TASK_CUSTOMER_PURCHASE_DATA: {
        url: "/dz/v2/task/customer/purchase/data",
        type: "POST",
    },
    DZ_C24_CUSTOMER_ORDER_LIST: {
        url: "/dz/v2/task/after/sale/customer/order/list",
        type: "POST",
    },
    AFTER_SALE_REGION_LIST: {
        url: "/dz/v2/task/after/sale/region/list",
        type: "POST",
    },
    AFTER_SALE_CUSTOMER_LIST: {
        url: "/dz/v2/task/after/sale/customer/list",
        type: "POST",
    },
    TASK_BIRTHDAY_DATE_LIST: {
        url: "/dz/v2/task/birthday/date/list",
        type: "POST",
    },
    GET_CORP_TAGS_ACT: {
        url: "/get/corp/tags/act",
        type: "POST",
    },
    DZ_V2_TASK_CUSTOMER_COUNT: {
        url: "/dz/v2/task/customer/count",
        type: "POST",
    },
    /**
     * C40. 根据UserId获取顾客CustomerId
     * @see https://wiki.qingger.cn/pages/viewpage.action?pageId=5310568
     */
    DZ_C40_GET_EXTERNAL_CSTID: {
        url: "/customer/id/by/userId",
        type: "POST",
    },
};
/***************对接PG的**************/
export const PGConfig = {
    PG_REPORT_CONSUME_ORDER_LIST: {
        url: "/pg/report/consumption/order/list",
        type: "POST",
    },
    PG_REPORT_PERFORMANCE: {
        url: "/pg/report/performance",
        type: "POST",
    },
    PG_REPORT_RECRUIT_DETAIL: {
        url: "/pg/report/recruit/detail",
        type: "POST",
    },
    PG_REPORT_PRODUCT_PERFORMANCE: {
        url: "/pg/report/product/performance",
        type: "POST",
    },
    PG_REPORT_PRODUCT_GUIDER_DETAIL: {
        url: "/pg/report/product/guider/detail",
        type: "POST",
    },
    PG_REPORT_MEMBER_STRUCTURE: {
        url: "/pg/report/member/structure",
        type: "POST",
    },
    PG_REPORT_PRODUCT_SITE_DETAIL: {
        url: "/pg/report/product/site/detail",
        type: "POST",
    },
    PG_REPORT_CONSUMPTIONS_DETAIL: {
        url: "/pg/report/consumption/detail",
        type: "POST",
    },
    PG_REPORT_CONSUMPTION_STRUCTURE: {
        url: "/pg/report/consumption/structure",
        type: "POST",
    },
    PG_REPORT_CONSUMPTION_TAG: {
        url: "/pg/report/consumption/tag",
        type: "POST",
    },
    PG_REPORT_CONSUMPTION_KPI: {
        url: "/pg/report/consumption/kpi",
        type: "POST",
    },
    PG_REPORT_GUIDER_SALE_LIST: {
        url: "/pg/report/guider/sale/list",
        type: "POST",
    },
    PG_REPORT_RECRUIT_TREND: {
        url: "/pg/report/recruit/trend",
        type: "POST",
    },
    PG_REPORT_RECRUIT_CHANNEL: {
        url: "/pg/report/recruit/channel",
        type: "POST",
    },
    PG_REPORT_RANKING_MAIN_BRAND: {
        url: "/pg/report/ranking/main/brand",
        type: "POST",
    },
    PG_REPORT_RANKING_KPI: {
        url: "/pg/report/ranking/kpi",
        type: "POST",
    },
    PG_REPORT_RANKING_REGION_LIST: {
        url: "/pg/report/ranking/region/list",
        type: "POST",
    },
    PG_REPORT_RANKING_DETAIL: {
        url: "/pg/report/ranking/detail",
        type: "POST",
    },
    PG_REPORT_RANKING_LIST: {
        url: "/pg/report/ranking/list",
        type: "POST",
    },

    DZ_GROUP_COMMON_CONFIG: {
        url: "/mp/group/common/config",
        type: "POST",
    },
    DZ_REPORT_RECRUIT_VIPRATE: {
        url: "/pg/report/recruit/viprate",
        type: "POST",
    },

    DZ_NO_AUTH_JSSDK_SIGN_PACKAGE: {
        url: "noauth/mp/wx/jssdk/sign/package",
        type: "POST",
    },

};

/***************对接crm的**************/
export const crmConfig = {
    CRM_LXSM_TOPLIST: {
        url: '/rstore/api/customer/lxsm/toplist',
        type: "GET"
    },
    CRM_LXSM_MEMBER_INFO: {
        url: '/rstore/api/customer/lxsm/memberInfo',
        type: "GET"
    },
    CRM_LXSM_FIND_CHAT: {
        url: '/rstore/api/customer/lxsm/findChat',
        type: "GET"
    },
    CRM_LXSM_CHAT_MEMBER_INFO: {
        url: '/rstore/api/customer/lxsm/chatMemberInfo',
        type: "GET"
    },
    CRM_LXSM_FIND_ACTIVITY: {
        url: '/rstore/api/customer/lxsm/findActivity/{id}',
        type: "POST"
    },
    CRM_LXSM_GET_CHAT_URL: {
        url: '/rstore/api/customer/lxsm/getChatUrl',
        type: "GET"
    },

    RSTORE_QYWX_QSOP_EXECUTE: {
        url: '/rstore/api/crm/rest/rstore/qywx/qsop/execute',
        type: "GET"
    },
    RSTORE_QYWX_QSOP_TASK: {
        url: '/rstore/api/crm/rest/rstore/qywx/qsop/task',
        type: "GET"
    },
    RSTORE_TASK_PREVIEW: {
        url: '/rest/crm-customer/rstore/sop/find/preview/{id}',
        type: "GET"
    },
    RSTORE_TASK_SOP_ITEM: {
        url: '/rest/crm-customer/crm-rstore-sop-task-item/find',
        type: "POST"
    },
    RSTORE_GET_QRCODE: {
        url: '/api/crm/iactivity/common/rstore/getQRCode',
        type: "POST"
    },
    RSTORE_SOP_TASK_LIST: {
        url: '/rstore/sop/taskList/customer',
        type: "POST"
    },
}
