// 店铺销售
import * as F2 from "@antv/f2";
import * as $ from "jquery";
import { formatNum, createCircleChart, createPieChart } from "@/util/utils";
import GuiderList from "@/components/guiderList";
import eventBus from "@/util/eventBus";

import service from "../../service/index";
const yesterday = 1;
let type: string = "";
let topVa: any = yesterday; // 头部时间,默认昨天
let seleectParams: any = {};
let consumeType: number = 3; // 1:生日客/非生日客，2:用券客/非用券客，3:首单客/回购客，4:单次客/多次客

let vueExa: any = null;

const structureList: any[] = [
  {
    empName: "首单/回购",
    empCode: "3"
  },
  {
    empName: "单次客/多次客",
    empCode: "4"
  },
  {
    empName: "生日客/非生日客",
    empCode: "1"
  },
  {
    empName: "用券客/非用券客",
    empCode: "2"
  }
  // {
  //   empName: "沉默客/非沉默客",
  //   empCode: "5"
  // }
];

function createPopulationSaleChart(
  data: populationSaleListItem[],
  id: string
): void {
  if (data && data.length > 0) {
    const Global = F2.Global;
    const chart = new F2.Chart({
      id,
      pixelRatio: window.devicePixelRatio
    });
    chart.source(data, {
      val: {
        tickCount: 5
      }
    });
    chart.coord({
      transposed: true
    });
    chart.axis("name", {
      line: Global._defaultAxis.line,
      grid: null
    });
    chart.axis("val", {
      line: null,
      grid: Global._defaultAxis.grid,
      label: function label(text: number, index: number, total: number) {
        const textCfg: any = {};
        if (index === 0) {
          textCfg.textAlign = "left";
        } else if (index === total - 1) {
          textCfg.textAlign = "right";
        }
        return textCfg;
      }
    });
    chart.tooltip({
      showItemMarker: false,
      background: {
        radius: 2,
        fill: "#1890FF",
        padding: [3, 5]
      },
      tooltipMarkerStyle: {
        fill: "#1890FF",
        fillOpacity: 0.1
      },
      onShow: function onShow(ev: any) {
        const items = ev.items;
        items[0].name = items[0].title;
        items[0].value = "￥" + formatNum(items[0].value);
      }
    });
    chart.interval().position("name*val");

    chart.render();
  } else {
    $(`#${id}`)
      .parent()
      .html(
        `<div class="no-data"><i class="icon-kongshuju iconfont"></i><div>无数据</div></div>`
      );
  }
}

/**
 * 更新页面数据
 * @param content
 */
function updatePage(content: any): void {
  const mySaleAmt: string = formatNum(content.memberBusiness.totalSaleAmt);
  const memberSaleAmt: string = formatNum(content.memberBusiness.memberSaleAmt);
  $("#mySaleAmt").html(`<span>￥</span>${mySaleAmt}`);
  $("#memberSaleAmt").html(
    `<span style="font-size:0.22rem">￥</span>${memberSaleAmt}`
  );

  let kpiLeftHtml = "";
  let kpiCenterHtml = "";
  let kpiRightHtml = "";
  content.memberConsumeKPI.forEach((item: any) => {
    const leftNum = item.value1;
    const rightNum = item.value2;
    let leftWidth = "";
    let rightWidth = "";
    // tslint:disable-next-line: restrict-plus-operands
    const total = leftNum + rightNum;
    if (total === 0) {
      leftWidth = "0";
      rightWidth = "0";
    } else {
      leftWidth = ((leftNum / total) * 100).toFixed(2) + "%";
      rightWidth = ((rightNum / total) * 100).toFixed(2) + "%";
    }
    let leftData = `${leftNum.toFixed(2) * 1}`;
    let rightData = `${rightNum.toFixed(2) * 1}`;
    if (item.code === "comsumeNum") {
      // tslint:disable-next-line: restrict-plus-operands
      leftData = +leftNum.toFixed(2) + "人";
      // tslint:disable-next-line: restrict-plus-operands
      rightData = +rightNum.toFixed(2) + "人";
    }
    if (item.code === "unitPrice") {
      // tslint:disable-next-line: restrict-plus-operands
      leftData = "￥" + leftNum.toFixed(2);
      // tslint:disable-next-line: restrict-plus-operands
      rightData = "￥" + rightNum.toFixed(2);
    }
    if (item.code === "unitQuantity") {
      // tslint:disable-next-line: restrict-plus-operands
      leftData = +leftNum.toFixed(2) + "件";
      // tslint:disable-next-line: restrict-plus-operands
      rightData = +rightNum.toFixed(2) + "件";
    }
    if (item.code === "perCapitaAmt") {
      // tslint:disable-next-line: restrict-plus-operands
      leftData = "￥" + leftNum.toFixed(2);
      // tslint:disable-next-line: restrict-plus-operands
      rightData = "￥" + rightNum.toFixed(2);
    }
    if (item.code === "perCapitaNum") {
      // tslint:disable-next-line: restrict-plus-operands
      leftData = +leftNum.toFixed(2) + "件";
      // tslint:disable-next-line: restrict-plus-operands
      rightData = +rightNum.toFixed(2) + "件";
    }
    kpiLeftHtml += `<li style="width: ${leftWidth}" class="${
      leftWidth >= rightWidth ? "bigger" : ""
    }"><span>${leftData}</span></li>`;
    kpiCenterHtml += `<li><span>${item.name}</span></li>`;
    kpiRightHtml += `<li style="width: ${rightWidth}" class="${
      leftWidth <= rightWidth ? "bigger" : ""
    }"><span>${rightData}</span></li>`;
  });
  $(".kpi-left ul").html(kpiLeftHtml);
  $(".kpi-center ul").html(kpiCenterHtml);
  $(".kpi-right ul").html(kpiRightHtml);
}

/**
 * 清除图表缓存
 */
function initCanvas() {
  $("#mySaleAmt").html(`<span>￥</span>0`);
  $("#memberSaleAmt").html(`<span style="font-size:0.22rem">￥</span>0`);
  $("#memberSaleChartWrap").html(
    '<canvas style="width: 100%; height: 100%;" id="memberSaleChart"></canvas>'
  );
}

/**
 * 数据请求
 */
function getData(typeNumber: string = "") {
  service
    .reportConsumptionBusinessData({
      ...{
        va: topVa,
        consumeType,
        items: ["memberBusiness", "memberConsume", "memberConsumeKPI"]
      },
      ...seleectParams
    })
    .then((result: any) => {
      if (+type !== +typeNumber) {
        return;
      }
      initCanvas();
      updatePage(result.content);
      createCircleChart(
        result.content.memberBusiness.memberSaleRate,
        "memberSaleChart",
        "会员消费占比"
      );

      eventBus.emit("shopSalesDataChange", {
        memberConsume: result.content.memberConsume,
        topVa,
        memberSaleChainRate: result.content.memberBusiness.memberSaleChainRate,
        memberSaleSameTermRate:
          result.content.memberBusiness.memberSaleSameTermRate
      });
    })
    .catch(error => {
      console.log(error);
    });

  if (vueExa) {
    vueExa.getRuictData();
  }
}

/**
 * 获取会员消费说明
 */
function getExtendInfo() {
  service.extendInfo().then((result: extendInfoResponse) => {
    if (
      result.content.functional_module_desc.Consume &&
      result.content.functional_module_desc.Consume !== ""
    ) {
      $("#showDialog").show();
      $("#dialogCover .content").html(
        result.content.functional_module_desc.Consume
      );
    } else {
      $("#showDialog").hide();
    }
  });
}

/**
 * 页面事件
 */
function pageEvents() {
  $("#help").click(() => {
    $("#dialogCover").show();
    $("body").addClass("lock-scroll");
    $("html").addClass("lock-scroll");
  });

  $(".top-nav-wrap li").click(function() {
    if (![3, 5, 6].find(i => i === +$(this).attr("data-day"))) {
      $(".lastDayTip").hide();
    } else {
      $(".lastDayTip").show();
    }

    $(this)
      .addClass("nav-on-selected")
      .siblings()
      .removeClass("nav-on-selected");
    topVa = +$(this).attr("data-day");
    type = "";
    getData();
    eventBus.emit("taskCenterOnSelectStract", structureList[0]);
  });
  $("#showDialog").click(() => {
    $("#dialogCover").show();
    $("body").addClass("lock-scroll");
    $("html").addClass("lock-scroll");
  });

  $("#closeDialog").click(() => {
    $("#dialogCover").hide();
    $("body").removeClass("lock-scroll");
    $("html").removeClass("lock-scroll");
  });

  $(".top-tap-wrap span").click(function() {
    type = $(this).attr("data-type");
    $(this)
      .addClass("tap-on-selected")
      .siblings()
      .removeClass("tap-on-selected");
    if (type === "1") {
      seleectParams = { empCode: localStorage.getItem("empCode") };
      seleectParams.guiderId = localStorage.getItem("guiderId");
      window.localStorage.setItem("isGuider", "1");
      $(".data-select-wrap").hide();
      vueExa.isShop = false;
    } else {
      vueExa.isShop = true;
      seleectParams = { empCode: "" };
      seleectParams.guiderId = localStorage.getItem("guiderId");
      window.localStorage.setItem("isGuider", "0");
      $(".data-select-wrap").show();
      (window as any).guiderId = $("#guiderSelect").attr("data-id");
      (window as any).empCode = $("#guiderSelect").attr("data-code");
      eventBus.emit("taskCenterOnSelectGuider", {
        empCode: "",
        empName: "全店",
        guiderId: ""
      });
    }

    eventBus.emit("taskCenterOnSelectStract", structureList[0]);
    getData(type);
  });

  $(".popupMask").click(() => {
    $(".guider-list").hide();
    $(".popupMask").hide();
  });
}

function getGuiderList() {
  service
    .siteGuiderList()
    .then((data: any) => {
      $("#siteGuider").empty();
      $("#siteGuider").html(data && data.content);
    })
    .catch(error => {
      console.log(error);
    });
}

function getIsvMPConfig() {
  service
    .isvMPConfig()
    .then(result => {
      if (result.content.mpCode !== "MPRStore") {
        $("#homeTab").show();
      } else {
        $("#homeTab").hide();
      }
    })
    .catch(error => {
      console.log(error);
    });
}

const getPageData = () => {
  topVa = 1;
  $(".top-nav-wrap")
    .children("li")
    .each((index: any, el: any) => {
      if ($(el).hasClass("nav-on-selected")) {
        topVa = $(el).attr("data-day");
      }
    });
  type = "2";
  getData("2");
};

function sale_divide() {
  service
    .contactMemberGet({ appointedGuiderId: localStorage.getItem("guiderId") })
    .then((resp: any) => {
      const siteRole = window.localStorage.getItem("siteRole");

      if (siteRole !== "101" && +resp.content.performanceType !== 1) {
        $(".top-tap-wrap").hide();
        $(".guider-tip-wrap").show();
      }
    });
}

const onload = () => {
  document.title = "会员消费";
  window.localStorage.setItem("isGuider", "1");
  $("body").show();
  getIsvMPConfig();
  pageEvents();
  getData();
  getExtendInfo();
  getGuiderList();
  sale_divide();
};

import { Component, Vue } from "vue-property-decorator";
import _Template from "./shopSales-temp.vue";
import StructList from "@/components/stractList";
import HelpTips from "@/components/helpTips";

@Component({
  mixins: [_Template],
  components: { GuiderList,  StructList, HelpTips }
})
export default class ShopSales extends Vue {
  protected guiderList: any[] = [];
  protected chainRatio: boolean = true;
  protected structureList: any[] = structureList;
  protected guiderMock: any[] = [];
  protected guiderListMore: boolean = false;
  protected memberConsume: any = {};
  protected showDiff: boolean = false;
  protected memberSaleChainRate: string = "";
  protected memberSaleSameTermRate: string = "";

  protected isShop: boolean = false;
  protected isShopSelectGuider: boolean = true;

  protected KPITitle: string[] = ["首单", "回购"];

  get showRuict() {
    return this.isShopSelectGuider && this.isShop;
  }
  getClass(val) {
    let value = val;
    if (+value === 0) {
      value = "-";
    }
    return value !== "-"
      ? (value + "").indexOf("-") !== -1
        ? "xia"
        : "red"
      : "";
  }
  getString(val) {
    let value = val;
    if (+value === 0) {
      value = "-";
    }
    return value !== "-" ? ((value + "").indexOf("-") !== -1 ? "↓" : "↑") : "";
  }
  closeDialog(event, val) {
    event.stopPropagation();
    if (val === 1) {
      $("#dialogCover").hide();
    }
  }
  protected mounted() {
    vueExa = this;
    setTimeout(() => {
      onload();
      eventBus.emit("taskCenterOnSelectStract", this.structureList[0]);
      this.getGuiderList();
      this.getRuictData();
      eventBus.on("shopSalesDataChange", arg => {
        this.memberConsume = arg.memberConsume;
        this.showDiff = +arg.topVa === 5 || +arg.topVa === 4;
        this.memberSaleChainRate = arg.memberSaleChainRate;
        this.memberSaleSameTermRate = arg.memberSaleSameTermRate;
        this.changeShowDiff();
      });
    });
  }
  protected showTipDialog() {
    $("#dialogCover").show();
  }

  protected selectGuider(item) {
    eventBus.emit("taskCenterOnSelectGuider", {
      empName: item.empName,
      empCode: item.empCode
    });

    this.getData(item.guiderId, item.empCode);
  }

  protected changeShowDiff() {
    if (this.showDiff) {
      $("#memberSaleChartWrap")
        .find(".chart-inner")
        .css("top", "35px")
        .find("#chart-diff")
        .removeClass("red")
        .removeClass("xia")
        .addClass(
          this.chainRatio
            ? this.getClass(this.memberSaleSameTermRate)
            : this.getClass(this.memberSaleChainRate)
        )
        .html(
          this.chainRatio
            ? this.memberSaleSameTermRate +
                this.getString(this.memberSaleSameTermRate)
            : this.memberSaleChainRate +
                this.getString(this.memberSaleChainRate)
        )
        .css("font-size", "0.24rem")
        .show();
    } else {
      $("#memberSaleChartWrap")
        .find(".chart-inner")
        .find("#chart-diff")
        .hide();
    }
  }

  protected getRuictData() {
    service
      .reportGuiderRecruitList({
        ...{
          va: topVa
        },
        ...seleectParams
      })
      .then(res => {
        this.guiderMock = (res.content.list || []).sort((a, b) => {
          return b.memberSaleRate - a.memberSaleRate;
        });
      });
  }
  protected getData(guiderId, empCode) {
    seleectParams = { guiderId, empCode };
    if (!guiderId) {
      this.isShopSelectGuider = true;
      seleectParams.guiderId = localStorage.getItem("guiderId");
    } else {
      this.isShopSelectGuider = false;
    }
    getPageData();
  }
  protected getGuiderList() {
    service
      .siteGuiderList()
      .then((data: any) => {
        this.guiderList = data.content.list;
      })
      .catch(error => {
        console.log(error);
      });
  }
  protected changeChainRatio() {
    this.chainRatio = !this.chainRatio;
    this.changeShowDiff();
  }

  protected getStructure(item) {
    consumeType = item.empCode;
    this.$set(this, "KPITitle", item.empName.split("/"));
    getData(type);
  }
}
